var Vr = Object.defineProperty;
var jr = (t, e, n) => e in t ? Vr(t, e, { enumerable: !0, configurable: !0, writable: !0, value: n }) : t[e] = n;
var Ae = (t, e, n) => (jr(t, typeof e != "symbol" ? e + "" : e, n), n);
var Cn = /* @__PURE__ */ ((t) => (t.Full_1M = "tn-brand-full-plan-1m", t.Full_3M = "tn-brand-full-plan-3m", t.Basic_1M = "tn-brand-basic-plan-1m", t))(Cn || {});
const Tc = [
  "tn-brand-full-plan-1m",
  "tn-brand-full-plan-3m"
  /* Full_3M */
], vc = [
  "tn-brand-basic-plan-1m"
  /* Basic_1M */
];
var qr = /* @__PURE__ */ ((t) => (t.Archived = "archived", t.Pending = "pending", t.Draft = "draft", t.Live = "live", t.Approved = "approved", t))(qr || {}), Gr = /* @__PURE__ */ ((t) => (t.Scheduled = "scheduled", t.Draft = "draft", t.Live = "live", t.Expired = "expired", t))(Gr || {}), gt = /* @__PURE__ */ ((t) => (t.Unfulfilled = "unfulfilled", t.Fulfilled = "fulfilled", t))(gt || {});
const Rc = ["password", "magic-link", "cm-sso"], Oc = (t) => new Promise((e) => setTimeout(e, t)), Z = (t) => {
  const e = t.data();
  return Object.defineProperty(e, "id", { value: t.id }), e;
}, Nc = 2, Cc = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" }
];
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Dn = function(t) {
  const e = [];
  let n = 0;
  for (let r = 0; r < t.length; r++) {
    let s = t.charCodeAt(r);
    s < 128 ? e[n++] = s : s < 2048 ? (e[n++] = s >> 6 | 192, e[n++] = s & 63 | 128) : (s & 64512) === 55296 && r + 1 < t.length && (t.charCodeAt(r + 1) & 64512) === 56320 ? (s = 65536 + ((s & 1023) << 10) + (t.charCodeAt(++r) & 1023), e[n++] = s >> 18 | 240, e[n++] = s >> 12 & 63 | 128, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128) : (e[n++] = s >> 12 | 224, e[n++] = s >> 6 & 63 | 128, e[n++] = s & 63 | 128);
  }
  return e;
}, Wr = function(t) {
  const e = [];
  let n = 0, r = 0;
  for (; n < t.length; ) {
    const s = t[n++];
    if (s < 128)
      e[r++] = String.fromCharCode(s);
    else if (s > 191 && s < 224) {
      const i = t[n++];
      e[r++] = String.fromCharCode((s & 31) << 6 | i & 63);
    } else if (s > 239 && s < 365) {
      const i = t[n++], o = t[n++], a = t[n++], c = ((s & 7) << 18 | (i & 63) << 12 | (o & 63) << 6 | a & 63) - 65536;
      e[r++] = String.fromCharCode(55296 + (c >> 10)), e[r++] = String.fromCharCode(56320 + (c & 1023));
    } else {
      const i = t[n++], o = t[n++];
      e[r++] = String.fromCharCode((s & 15) << 12 | (i & 63) << 6 | o & 63);
    }
  }
  return e.join("");
}, Pn = {
  /**
   * Maps bytes to characters.
   */
  byteToCharMap_: null,
  /**
   * Maps characters to bytes.
   */
  charToByteMap_: null,
  /**
   * Maps bytes to websafe characters.
   * @private
   */
  byteToCharMapWebSafe_: null,
  /**
   * Maps websafe characters to bytes.
   * @private
   */
  charToByteMapWebSafe_: null,
  /**
   * Our default alphabet, shared between
   * ENCODED_VALS and ENCODED_VALS_WEBSAFE
   */
  ENCODED_VALS_BASE: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  /**
   * Our default alphabet. Value 64 (=) is special; it means "nothing."
   */
  get ENCODED_VALS() {
    return this.ENCODED_VALS_BASE + "+/=";
  },
  /**
   * Our websafe alphabet.
   */
  get ENCODED_VALS_WEBSAFE() {
    return this.ENCODED_VALS_BASE + "-_.";
  },
  /**
   * Whether this browser supports the atob and btoa functions. This extension
   * started at Mozilla but is now implemented by many browsers. We use the
   * ASSUME_* variables to avoid pulling in the full useragent detection library
   * but still allowing the standard per-browser compilations.
   *
   */
  HAS_NATIVE_SUPPORT: typeof atob == "function",
  /**
   * Base64-encode an array of bytes.
   *
   * @param input An array of bytes (numbers with
   *     value in [0, 255]) to encode.
   * @param webSafe Boolean indicating we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeByteArray(t, e) {
    if (!Array.isArray(t))
      throw Error("encodeByteArray takes an array as a parameter");
    this.init_();
    const n = e ? this.byteToCharMapWebSafe_ : this.byteToCharMap_, r = [];
    for (let s = 0; s < t.length; s += 3) {
      const i = t[s], o = s + 1 < t.length, a = o ? t[s + 1] : 0, c = s + 2 < t.length, u = c ? t[s + 2] : 0, d = i >> 2, h = (i & 3) << 4 | a >> 4;
      let T = (a & 15) << 2 | u >> 6, S = u & 63;
      c || (S = 64, o || (T = 64)), r.push(n[d], n[h], n[T], n[S]);
    }
    return r.join("");
  },
  /**
   * Base64-encode a string.
   *
   * @param input A string to encode.
   * @param webSafe If true, we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? btoa(t) : this.encodeByteArray(Dn(t), e);
  },
  /**
   * Base64-decode a string.
   *
   * @param input to decode.
   * @param webSafe True if we should use the
   *     alternative alphabet.
   * @return string representing the decoded value.
   */
  decodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? atob(t) : Wr(this.decodeStringToByteArray(t, e));
  },
  /**
   * Base64-decode a string.
   *
   * In base-64 decoding, groups of four characters are converted into three
   * bytes.  If the encoder did not apply padding, the input length may not
   * be a multiple of 4.
   *
   * In this case, the last group will have fewer than 4 characters, and
   * padding will be inferred.  If the group has one or two characters, it decodes
   * to one byte.  If the group has three characters, it decodes to two bytes.
   *
   * @param input Input to decode.
   * @param webSafe True if we should use the web-safe alphabet.
   * @return bytes representing the decoded value.
   */
  decodeStringToByteArray(t, e) {
    this.init_();
    const n = e ? this.charToByteMapWebSafe_ : this.charToByteMap_, r = [];
    for (let s = 0; s < t.length; ) {
      const i = n[t.charAt(s++)], a = s < t.length ? n[t.charAt(s)] : 0;
      ++s;
      const u = s < t.length ? n[t.charAt(s)] : 64;
      ++s;
      const h = s < t.length ? n[t.charAt(s)] : 64;
      if (++s, i == null || a == null || u == null || h == null)
        throw new Kr();
      const T = i << 2 | a >> 4;
      if (r.push(T), u !== 64) {
        const S = a << 4 & 240 | u >> 2;
        if (r.push(S), h !== 64) {
          const f = u << 6 & 192 | h;
          r.push(f);
        }
      }
    }
    return r;
  },
  /**
   * Lazy static initialization function. Called before
   * accessing any of the static map variables.
   * @private
   */
  init_() {
    if (!this.byteToCharMap_) {
      this.byteToCharMap_ = {}, this.charToByteMap_ = {}, this.byteToCharMapWebSafe_ = {}, this.charToByteMapWebSafe_ = {};
      for (let t = 0; t < this.ENCODED_VALS.length; t++)
        this.byteToCharMap_[t] = this.ENCODED_VALS.charAt(t), this.charToByteMap_[this.byteToCharMap_[t]] = t, this.byteToCharMapWebSafe_[t] = this.ENCODED_VALS_WEBSAFE.charAt(t), this.charToByteMapWebSafe_[this.byteToCharMapWebSafe_[t]] = t, t >= this.ENCODED_VALS_BASE.length && (this.charToByteMap_[this.ENCODED_VALS_WEBSAFE.charAt(t)] = t, this.charToByteMapWebSafe_[this.ENCODED_VALS.charAt(t)] = t);
    }
  }
};
class Kr extends Error {
  constructor() {
    super(...arguments), this.name = "DecodeBase64StringError";
  }
}
const zr = function(t) {
  const e = Dn(t);
  return Pn.encodeByteArray(e, !0);
}, Ln = function(t) {
  return zr(t).replace(/\./g, "");
}, Mn = function(t) {
  try {
    return Pn.decodeString(t, !0);
  } catch (e) {
    console.error("base64Decode failed: ", e);
  }
  return null;
};
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Jr() {
  if (typeof self < "u")
    return self;
  if (typeof window < "u")
    return window;
  if (typeof global < "u")
    return global;
  throw new Error("Unable to locate global object.");
}
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Yr = () => Jr().__FIREBASE_DEFAULTS__, Xr = () => {
  if (typeof process > "u" || typeof process.env > "u")
    return;
  const t = process.env.__FIREBASE_DEFAULTS__;
  if (t)
    return JSON.parse(t);
}, Qr = () => {
  if (typeof document > "u")
    return;
  let t;
  try {
    t = document.cookie.match(/__FIREBASE_DEFAULTS__=([^;]+)/);
  } catch {
    return;
  }
  const e = t && Mn(t[1]);
  return e && JSON.parse(e);
}, Zr = () => {
  try {
    return Yr() || Xr() || Qr();
  } catch (t) {
    console.info(`Unable to get __FIREBASE_DEFAULTS__ due to: ${t}`);
    return;
  }
}, es = (t) => {
  var e;
  return (e = Zr()) === null || e === void 0 ? void 0 : e[`_${t}`];
};
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Q() {
  return typeof navigator < "u" && typeof navigator.userAgent == "string" ? navigator.userAgent : "";
}
function ts() {
  return typeof window < "u" && // @ts-ignore Setting up an broadly applicable index signature for Window
  // just to deal with this case would probably be a bad idea.
  !!(window.cordova || window.phonegap || window.PhoneGap) && /ios|iphone|ipod|ipad|android|blackberry|iemobile/i.test(Q());
}
function ns() {
  const t = typeof chrome == "object" ? chrome.runtime : typeof browser == "object" ? browser.runtime : void 0;
  return typeof t == "object" && t.id !== void 0;
}
function rs() {
  return typeof navigator == "object" && navigator.product === "ReactNative";
}
function ss() {
  try {
    return typeof indexedDB == "object";
  } catch {
    return !1;
  }
}
function is() {
  return new Promise((t, e) => {
    try {
      let n = !0;
      const r = "validate-browser-context-for-indexeddb-analytics-module", s = self.indexedDB.open(r);
      s.onsuccess = () => {
        s.result.close(), n || self.indexedDB.deleteDatabase(r), t(!0);
      }, s.onupgradeneeded = () => {
        n = !1;
      }, s.onerror = () => {
        var i;
        e(((i = s.error) === null || i === void 0 ? void 0 : i.message) || "");
      };
    } catch (n) {
      e(n);
    }
  });
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const os = "FirebaseError";
class fe extends Error {
  constructor(e, n, r) {
    super(n), this.code = e, this.customData = r, this.name = os, Object.setPrototypeOf(this, fe.prototype), Error.captureStackTrace && Error.captureStackTrace(this, Le.prototype.create);
  }
}
class Le {
  constructor(e, n, r) {
    this.service = e, this.serviceName = n, this.errors = r;
  }
  create(e, ...n) {
    const r = n[0] || {}, s = `${this.service}/${e}`, i = this.errors[e], o = i ? as(i, r) : "Error", a = `${this.serviceName}: ${o} (${s}).`;
    return new fe(s, a, r);
  }
}
function as(t, e) {
  return t.replace(cs, (n, r) => {
    const s = e[r];
    return s != null ? String(s) : `<${r}?>`;
  });
}
const cs = /\{\$([^}]+)}/g;
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function kn(t) {
  const e = [];
  for (const [n, r] of Object.entries(t))
    Array.isArray(r) ? r.forEach((s) => {
      e.push(encodeURIComponent(n) + "=" + encodeURIComponent(s));
    }) : e.push(encodeURIComponent(n) + "=" + encodeURIComponent(r));
  return e.length ? "&" + e.join("&") : "";
}
function us(t, e) {
  const n = new ls(t, e);
  return n.subscribe.bind(n);
}
class ls {
  /**
   * @param executor Function which can make calls to a single Observer
   *     as a proxy.
   * @param onNoObservers Callback when count of Observers goes to zero.
   */
  constructor(e, n) {
    this.observers = [], this.unsubscribes = [], this.observerCount = 0, this.task = Promise.resolve(), this.finalized = !1, this.onNoObservers = n, this.task.then(() => {
      e(this);
    }).catch((r) => {
      this.error(r);
    });
  }
  next(e) {
    this.forEachObserver((n) => {
      n.next(e);
    });
  }
  error(e) {
    this.forEachObserver((n) => {
      n.error(e);
    }), this.close(e);
  }
  complete() {
    this.forEachObserver((e) => {
      e.complete();
    }), this.close();
  }
  /**
   * Subscribe function that can be used to add an Observer to the fan-out list.
   *
   * - We require that no event is sent to a subscriber sychronously to their
   *   call to subscribe().
   */
  subscribe(e, n, r) {
    let s;
    if (e === void 0 && n === void 0 && r === void 0)
      throw new Error("Missing Observer.");
    ds(e, [
      "next",
      "error",
      "complete"
    ]) ? s = e : s = {
      next: e,
      error: n,
      complete: r
    }, s.next === void 0 && (s.next = it), s.error === void 0 && (s.error = it), s.complete === void 0 && (s.complete = it);
    const i = this.unsubscribeOne.bind(this, this.observers.length);
    return this.finalized && this.task.then(() => {
      try {
        this.finalError ? s.error(this.finalError) : s.complete();
      } catch {
      }
    }), this.observers.push(s), i;
  }
  // Unsubscribe is synchronous - we guarantee that no events are sent to
  // any unsubscribed Observer.
  unsubscribeOne(e) {
    this.observers === void 0 || this.observers[e] === void 0 || (delete this.observers[e], this.observerCount -= 1, this.observerCount === 0 && this.onNoObservers !== void 0 && this.onNoObservers(this));
  }
  forEachObserver(e) {
    if (!this.finalized)
      for (let n = 0; n < this.observers.length; n++)
        this.sendOne(n, e);
  }
  // Call the Observer via one of it's callback function. We are careful to
  // confirm that the observe has not been unsubscribed since this asynchronous
  // function had been queued.
  sendOne(e, n) {
    this.task.then(() => {
      if (this.observers !== void 0 && this.observers[e] !== void 0)
        try {
          n(this.observers[e]);
        } catch (r) {
          typeof console < "u" && console.error && console.error(r);
        }
    });
  }
  close(e) {
    this.finalized || (this.finalized = !0, e !== void 0 && (this.finalError = e), this.task.then(() => {
      this.observers = void 0, this.onNoObservers = void 0;
    }));
  }
}
function ds(t, e) {
  if (typeof t != "object" || t === null)
    return !1;
  for (const n of e)
    if (n in t && typeof t[n] == "function")
      return !0;
  return !1;
}
function it() {
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Ye(t) {
  return t && t._delegate ? t._delegate : t;
}
class Ne {
  /**
   *
   * @param name The public service name, e.g. app, auth, firestore, database
   * @param instanceFactory Service factory responsible for creating the public interface
   * @param type whether the service provided by the component is public or private
   */
  constructor(e, n, r) {
    this.name = e, this.instanceFactory = n, this.type = r, this.multipleInstances = !1, this.serviceProps = {}, this.instantiationMode = "LAZY", this.onInstanceCreated = null;
  }
  setInstantiationMode(e) {
    return this.instantiationMode = e, this;
  }
  setMultipleInstances(e) {
    return this.multipleInstances = e, this;
  }
  setServiceProps(e) {
    return this.serviceProps = e, this;
  }
  setInstanceCreatedCallback(e) {
    return this.onInstanceCreated = e, this;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var U;
(function(t) {
  t[t.DEBUG = 0] = "DEBUG", t[t.VERBOSE = 1] = "VERBOSE", t[t.INFO = 2] = "INFO", t[t.WARN = 3] = "WARN", t[t.ERROR = 4] = "ERROR", t[t.SILENT = 5] = "SILENT";
})(U || (U = {}));
const hs = {
  debug: U.DEBUG,
  verbose: U.VERBOSE,
  info: U.INFO,
  warn: U.WARN,
  error: U.ERROR,
  silent: U.SILENT
}, fs = U.INFO, ps = {
  [U.DEBUG]: "log",
  [U.VERBOSE]: "log",
  [U.INFO]: "info",
  [U.WARN]: "warn",
  [U.ERROR]: "error"
}, ms = (t, e, ...n) => {
  if (e < t.logLevel)
    return;
  const r = (/* @__PURE__ */ new Date()).toISOString(), s = ps[e];
  if (s)
    console[s](`[${r}]  ${t.name}:`, ...n);
  else
    throw new Error(`Attempted to log a message with an invalid logType (value: ${e})`);
};
class Un {
  /**
   * Gives you an instance of a Logger to capture messages according to
   * Firebase's logging scheme.
   *
   * @param name The name that the logs will be associated with
   */
  constructor(e) {
    this.name = e, this._logLevel = fs, this._logHandler = ms, this._userLogHandler = null;
  }
  get logLevel() {
    return this._logLevel;
  }
  set logLevel(e) {
    if (!(e in U))
      throw new TypeError(`Invalid value "${e}" assigned to \`logLevel\``);
    this._logLevel = e;
  }
  // Workaround for setter/getter having to be the same type.
  setLogLevel(e) {
    this._logLevel = typeof e == "string" ? hs[e] : e;
  }
  get logHandler() {
    return this._logHandler;
  }
  set logHandler(e) {
    if (typeof e != "function")
      throw new TypeError("Value assigned to `logHandler` must be a function");
    this._logHandler = e;
  }
  get userLogHandler() {
    return this._userLogHandler;
  }
  set userLogHandler(e) {
    this._userLogHandler = e;
  }
  /**
   * The functions below are all based on the `console` interface
   */
  debug(...e) {
    this._userLogHandler && this._userLogHandler(this, U.DEBUG, ...e), this._logHandler(this, U.DEBUG, ...e);
  }
  log(...e) {
    this._userLogHandler && this._userLogHandler(this, U.VERBOSE, ...e), this._logHandler(this, U.VERBOSE, ...e);
  }
  info(...e) {
    this._userLogHandler && this._userLogHandler(this, U.INFO, ...e), this._logHandler(this, U.INFO, ...e);
  }
  warn(...e) {
    this._userLogHandler && this._userLogHandler(this, U.WARN, ...e), this._logHandler(this, U.WARN, ...e);
  }
  error(...e) {
    this._userLogHandler && this._userLogHandler(this, U.ERROR, ...e), this._logHandler(this, U.ERROR, ...e);
  }
}
const _s = (t, e) => e.some((n) => t instanceof n);
let Ht, Vt;
function gs() {
  return Ht || (Ht = [
    IDBDatabase,
    IDBObjectStore,
    IDBIndex,
    IDBCursor,
    IDBTransaction
  ]);
}
function ys() {
  return Vt || (Vt = [
    IDBCursor.prototype.advance,
    IDBCursor.prototype.continue,
    IDBCursor.prototype.continuePrimaryKey
  ]);
}
const xn = /* @__PURE__ */ new WeakMap(), yt = /* @__PURE__ */ new WeakMap(), Fn = /* @__PURE__ */ new WeakMap(), ot = /* @__PURE__ */ new WeakMap(), Dt = /* @__PURE__ */ new WeakMap();
function Es(t) {
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("success", i), t.removeEventListener("error", o);
    }, i = () => {
      n(se(t.result)), s();
    }, o = () => {
      r(t.error), s();
    };
    t.addEventListener("success", i), t.addEventListener("error", o);
  });
  return e.then((n) => {
    n instanceof IDBCursor && xn.set(n, t);
  }).catch(() => {
  }), Dt.set(e, t), e;
}
function bs(t) {
  if (yt.has(t))
    return;
  const e = new Promise((n, r) => {
    const s = () => {
      t.removeEventListener("complete", i), t.removeEventListener("error", o), t.removeEventListener("abort", o);
    }, i = () => {
      n(), s();
    }, o = () => {
      r(t.error || new DOMException("AbortError", "AbortError")), s();
    };
    t.addEventListener("complete", i), t.addEventListener("error", o), t.addEventListener("abort", o);
  });
  yt.set(t, e);
}
let Et = {
  get(t, e, n) {
    if (t instanceof IDBTransaction) {
      if (e === "done")
        return yt.get(t);
      if (e === "objectStoreNames")
        return t.objectStoreNames || Fn.get(t);
      if (e === "store")
        return n.objectStoreNames[1] ? void 0 : n.objectStore(n.objectStoreNames[0]);
    }
    return se(t[e]);
  },
  set(t, e, n) {
    return t[e] = n, !0;
  },
  has(t, e) {
    return t instanceof IDBTransaction && (e === "done" || e === "store") ? !0 : e in t;
  }
};
function ws(t) {
  Et = t(Et);
}
function Ss(t) {
  return t === IDBDatabase.prototype.transaction && !("objectStoreNames" in IDBTransaction.prototype) ? function(e, ...n) {
    const r = t.call(at(this), e, ...n);
    return Fn.set(r, e.sort ? e.sort() : [e]), se(r);
  } : ys().includes(t) ? function(...e) {
    return t.apply(at(this), e), se(xn.get(this));
  } : function(...e) {
    return se(t.apply(at(this), e));
  };
}
function As(t) {
  return typeof t == "function" ? Ss(t) : (t instanceof IDBTransaction && bs(t), _s(t, gs()) ? new Proxy(t, Et) : t);
}
function se(t) {
  if (t instanceof IDBRequest)
    return Es(t);
  if (ot.has(t))
    return ot.get(t);
  const e = As(t);
  return e !== t && (ot.set(t, e), Dt.set(e, t)), e;
}
const at = (t) => Dt.get(t);
function Is(t, e, { blocked: n, upgrade: r, blocking: s, terminated: i } = {}) {
  const o = indexedDB.open(t, e), a = se(o);
  return r && o.addEventListener("upgradeneeded", (c) => {
    r(se(o.result), c.oldVersion, c.newVersion, se(o.transaction), c);
  }), n && o.addEventListener("blocked", (c) => n(
    // Casting due to https://github.com/microsoft/TypeScript-DOM-lib-generator/pull/1405
    c.oldVersion,
    c.newVersion,
    c
  )), a.then((c) => {
    i && c.addEventListener("close", () => i()), s && c.addEventListener("versionchange", (u) => s(u.oldVersion, u.newVersion, u));
  }).catch(() => {
  }), a;
}
const Ts = ["get", "getKey", "getAll", "getAllKeys", "count"], vs = ["put", "add", "delete", "clear"], ct = /* @__PURE__ */ new Map();
function jt(t, e) {
  if (!(t instanceof IDBDatabase && !(e in t) && typeof e == "string"))
    return;
  if (ct.get(e))
    return ct.get(e);
  const n = e.replace(/FromIndex$/, ""), r = e !== n, s = vs.includes(n);
  if (
    // Bail if the target doesn't exist on the target. Eg, getAll isn't in Edge.
    !(n in (r ? IDBIndex : IDBObjectStore).prototype) || !(s || Ts.includes(n))
  )
    return;
  const i = async function(o, ...a) {
    const c = this.transaction(o, s ? "readwrite" : "readonly");
    let u = c.store;
    return r && (u = u.index(a.shift())), (await Promise.all([
      u[n](...a),
      s && c.done
    ]))[0];
  };
  return ct.set(e, i), i;
}
ws((t) => ({
  ...t,
  get: (e, n, r) => jt(e, n) || t.get(e, n, r),
  has: (e, n) => !!jt(e, n) || t.has(e, n)
}));
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Rs {
  constructor(e) {
    this.container = e;
  }
  // In initial implementation, this will be called by installations on
  // auth token refresh, and installations will send this string.
  getPlatformInfoString() {
    return this.container.getProviders().map((n) => {
      if (Os(n)) {
        const r = n.getImmediate();
        return `${r.library}/${r.version}`;
      } else
        return null;
    }).filter((n) => n).join(" ");
  }
}
function Os(t) {
  const e = t.getComponent();
  return (e == null ? void 0 : e.type) === "VERSION";
}
const bt = "@firebase/app", qt = "0.10.5";
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const de = new Un("@firebase/app"), Ns = "@firebase/app-compat", Cs = "@firebase/analytics-compat", Ds = "@firebase/analytics", Ps = "@firebase/app-check-compat", Ls = "@firebase/app-check", Ms = "@firebase/auth", ks = "@firebase/auth-compat", Us = "@firebase/database", xs = "@firebase/database-compat", Fs = "@firebase/functions", $s = "@firebase/functions-compat", Bs = "@firebase/installations", Hs = "@firebase/installations-compat", Vs = "@firebase/messaging", js = "@firebase/messaging-compat", qs = "@firebase/performance", Gs = "@firebase/performance-compat", Ws = "@firebase/remote-config", Ks = "@firebase/remote-config-compat", zs = "@firebase/storage", Js = "@firebase/storage-compat", Ys = "@firebase/firestore", Xs = "@firebase/vertexai-preview", Qs = "@firebase/firestore-compat", Zs = "firebase", ei = "10.12.2", ti = {
  [bt]: "fire-core",
  [Ns]: "fire-core-compat",
  [Ds]: "fire-analytics",
  [Cs]: "fire-analytics-compat",
  [Ls]: "fire-app-check",
  [Ps]: "fire-app-check-compat",
  [Ms]: "fire-auth",
  [ks]: "fire-auth-compat",
  [Us]: "fire-rtdb",
  [xs]: "fire-rtdb-compat",
  [Fs]: "fire-fn",
  [$s]: "fire-fn-compat",
  [Bs]: "fire-iid",
  [Hs]: "fire-iid-compat",
  [Vs]: "fire-fcm",
  [js]: "fire-fcm-compat",
  [qs]: "fire-perf",
  [Gs]: "fire-perf-compat",
  [Ws]: "fire-rc",
  [Ks]: "fire-rc-compat",
  [zs]: "fire-gcs",
  [Js]: "fire-gcs-compat",
  [Ys]: "fire-fst",
  [Qs]: "fire-fst-compat",
  [Xs]: "fire-vertex",
  "fire-js": "fire-js",
  [Zs]: "fire-js-all"
};
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const ni = /* @__PURE__ */ new Map(), ri = /* @__PURE__ */ new Map(), Gt = /* @__PURE__ */ new Map();
function Wt(t, e) {
  try {
    t.container.addComponent(e);
  } catch (n) {
    de.debug(`Component ${e.name} failed to register with FirebaseApp ${t.name}`, n);
  }
}
function Ce(t) {
  const e = t.name;
  if (Gt.has(e))
    return de.debug(`There were multiple attempts to register component ${e}.`), !1;
  Gt.set(e, t);
  for (const n of ni.values())
    Wt(n, t);
  for (const n of ri.values())
    Wt(n, t);
  return !0;
}
function Te(t) {
  return t.settings !== void 0;
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const si = {
  "no-app": "No Firebase App '{$appName}' has been created - call initializeApp() first",
  "bad-app-name": "Illegal App name: '{$appName}'",
  "duplicate-app": "Firebase App named '{$appName}' already exists with different options or config",
  "app-deleted": "Firebase App named '{$appName}' already deleted",
  "server-app-deleted": "Firebase Server App has been deleted",
  "no-options": "Need to provide options, when not being deployed to hosting via source.",
  "invalid-app-argument": "firebase.{$appName}() takes either no argument or a Firebase App instance.",
  "invalid-log-argument": "First argument to `onLog` must be null or a function.",
  "idb-open": "Error thrown when opening IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-get": "Error thrown when reading from IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-set": "Error thrown when writing to IndexedDB. Original error: {$originalErrorMessage}.",
  "idb-delete": "Error thrown when deleting from IndexedDB. Original error: {$originalErrorMessage}.",
  "finalization-registry-not-supported": "FirebaseServerApp deleteOnDeref field defined but the JS runtime does not support FinalizationRegistry.",
  "invalid-server-app-environment": "FirebaseServerApp is not for use in browser environments."
}, Pt = new Le("app", "Firebase", si);
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Lt = ei;
function ve(t, e, n) {
  var r;
  let s = (r = ti[t]) !== null && r !== void 0 ? r : t;
  n && (s += `-${n}`);
  const i = s.match(/\s|\//), o = e.match(/\s|\//);
  if (i || o) {
    const a = [
      `Unable to register library "${s}" with version "${e}":`
    ];
    i && a.push(`library name "${s}" contains illegal characters (whitespace or "/")`), i && o && a.push("and"), o && a.push(`version name "${e}" contains illegal characters (whitespace or "/")`), de.warn(a.join(" "));
    return;
  }
  Ce(new Ne(
    `${s}-version`,
    () => ({ library: s, version: e }),
    "VERSION"
    /* ComponentType.VERSION */
  ));
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const ii = "firebase-heartbeat-database", oi = 1, De = "firebase-heartbeat-store";
let ut = null;
function $n() {
  return ut || (ut = Is(ii, oi, {
    upgrade: (t, e) => {
      switch (e) {
        case 0:
          try {
            t.createObjectStore(De);
          } catch (n) {
            console.warn(n);
          }
      }
    }
  }).catch((t) => {
    throw Pt.create("idb-open", {
      originalErrorMessage: t.message
    });
  })), ut;
}
async function ai(t) {
  try {
    const n = (await $n()).transaction(De), r = await n.objectStore(De).get(Bn(t));
    return await n.done, r;
  } catch (e) {
    if (e instanceof fe)
      de.warn(e.message);
    else {
      const n = Pt.create("idb-get", {
        originalErrorMessage: e == null ? void 0 : e.message
      });
      de.warn(n.message);
    }
  }
}
async function Kt(t, e) {
  try {
    const r = (await $n()).transaction(De, "readwrite");
    await r.objectStore(De).put(e, Bn(t)), await r.done;
  } catch (n) {
    if (n instanceof fe)
      de.warn(n.message);
    else {
      const r = Pt.create("idb-set", {
        originalErrorMessage: n == null ? void 0 : n.message
      });
      de.warn(r.message);
    }
  }
}
function Bn(t) {
  return `${t.name}!${t.options.appId}`;
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const ci = 1024, ui = 30 * 24 * 60 * 60 * 1e3;
class li {
  constructor(e) {
    this.container = e, this._heartbeatsCache = null;
    const n = this.container.getProvider("app").getImmediate();
    this._storage = new hi(n), this._heartbeatsCachePromise = this._storage.read().then((r) => (this._heartbeatsCache = r, r));
  }
  /**
   * Called to report a heartbeat. The function will generate
   * a HeartbeatsByUserAgent object, update heartbeatsCache, and persist it
   * to IndexedDB.
   * Note that we only store one heartbeat per day. So if a heartbeat for today is
   * already logged, subsequent calls to this function in the same day will be ignored.
   */
  async triggerHeartbeat() {
    var e, n;
    const s = this.container.getProvider("platform-logger").getImmediate().getPlatformInfoString(), i = zt();
    if (!(((e = this._heartbeatsCache) === null || e === void 0 ? void 0 : e.heartbeats) == null && (this._heartbeatsCache = await this._heartbeatsCachePromise, ((n = this._heartbeatsCache) === null || n === void 0 ? void 0 : n.heartbeats) == null)) && !(this._heartbeatsCache.lastSentHeartbeatDate === i || this._heartbeatsCache.heartbeats.some((o) => o.date === i)))
      return this._heartbeatsCache.heartbeats.push({ date: i, agent: s }), this._heartbeatsCache.heartbeats = this._heartbeatsCache.heartbeats.filter((o) => {
        const a = new Date(o.date).valueOf();
        return Date.now() - a <= ui;
      }), this._storage.overwrite(this._heartbeatsCache);
  }
  /**
   * Returns a base64 encoded string which can be attached to the heartbeat-specific header directly.
   * It also clears all heartbeats from memory as well as in IndexedDB.
   *
   * NOTE: Consuming product SDKs should not send the header if this method
   * returns an empty string.
   */
  async getHeartbeatsHeader() {
    var e;
    if (this._heartbeatsCache === null && await this._heartbeatsCachePromise, ((e = this._heartbeatsCache) === null || e === void 0 ? void 0 : e.heartbeats) == null || this._heartbeatsCache.heartbeats.length === 0)
      return "";
    const n = zt(), { heartbeatsToSend: r, unsentEntries: s } = di(this._heartbeatsCache.heartbeats), i = Ln(JSON.stringify({ version: 2, heartbeats: r }));
    return this._heartbeatsCache.lastSentHeartbeatDate = n, s.length > 0 ? (this._heartbeatsCache.heartbeats = s, await this._storage.overwrite(this._heartbeatsCache)) : (this._heartbeatsCache.heartbeats = [], this._storage.overwrite(this._heartbeatsCache)), i;
  }
}
function zt() {
  return (/* @__PURE__ */ new Date()).toISOString().substring(0, 10);
}
function di(t, e = ci) {
  const n = [];
  let r = t.slice();
  for (const s of t) {
    const i = n.find((o) => o.agent === s.agent);
    if (i) {
      if (i.dates.push(s.date), Jt(n) > e) {
        i.dates.pop();
        break;
      }
    } else if (n.push({
      agent: s.agent,
      dates: [s.date]
    }), Jt(n) > e) {
      n.pop();
      break;
    }
    r = r.slice(1);
  }
  return {
    heartbeatsToSend: n,
    unsentEntries: r
  };
}
class hi {
  constructor(e) {
    this.app = e, this._canUseIndexedDBPromise = this.runIndexedDBEnvironmentCheck();
  }
  async runIndexedDBEnvironmentCheck() {
    return ss() ? is().then(() => !0).catch(() => !1) : !1;
  }
  /**
   * Read all heartbeats.
   */
  async read() {
    if (await this._canUseIndexedDBPromise) {
      const n = await ai(this.app);
      return n != null && n.heartbeats ? n : { heartbeats: [] };
    } else
      return { heartbeats: [] };
  }
  // overwrite the storage with the provided heartbeats
  async overwrite(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const s = await this.read();
      return Kt(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : s.lastSentHeartbeatDate,
        heartbeats: e.heartbeats
      });
    } else
      return;
  }
  // add heartbeats
  async add(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const s = await this.read();
      return Kt(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : s.lastSentHeartbeatDate,
        heartbeats: [
          ...s.heartbeats,
          ...e.heartbeats
        ]
      });
    } else
      return;
  }
}
function Jt(t) {
  return Ln(
    // heartbeatsCache wrapper properties
    JSON.stringify({ version: 2, heartbeats: t })
  ).length;
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function fi(t) {
  Ce(new Ne(
    "platform-logger",
    (e) => new Rs(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), Ce(new Ne(
    "heartbeat",
    (e) => new li(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), ve(bt, qt, t), ve(bt, qt, "esm2017"), ve("fire-js", "");
}
fi("");
function Hn(t, e) {
  var n = {};
  for (var r in t)
    Object.prototype.hasOwnProperty.call(t, r) && e.indexOf(r) < 0 && (n[r] = t[r]);
  if (t != null && typeof Object.getOwnPropertySymbols == "function")
    for (var s = 0, r = Object.getOwnPropertySymbols(t); s < r.length; s++)
      e.indexOf(r[s]) < 0 && Object.prototype.propertyIsEnumerable.call(t, r[s]) && (n[r[s]] = t[r[s]]);
  return n;
}
function Vn() {
  return {
    "dependent-sdk-initialized-before-auth": "Another Firebase SDK was initialized and is trying to use Auth before Auth is initialized. Please be sure to call `initializeAuth` or `getAuth` before starting any other Firebase SDK."
  };
}
const pi = Vn, jn = new Le("auth", "Firebase", Vn()), mi = {
  ADMIN_ONLY_OPERATION: "auth/admin-restricted-operation",
  ARGUMENT_ERROR: "auth/argument-error",
  APP_NOT_AUTHORIZED: "auth/app-not-authorized",
  APP_NOT_INSTALLED: "auth/app-not-installed",
  CAPTCHA_CHECK_FAILED: "auth/captcha-check-failed",
  CODE_EXPIRED: "auth/code-expired",
  CORDOVA_NOT_READY: "auth/cordova-not-ready",
  CORS_UNSUPPORTED: "auth/cors-unsupported",
  CREDENTIAL_ALREADY_IN_USE: "auth/credential-already-in-use",
  CREDENTIAL_MISMATCH: "auth/custom-token-mismatch",
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "auth/requires-recent-login",
  DEPENDENT_SDK_INIT_BEFORE_AUTH: "auth/dependent-sdk-initialized-before-auth",
  DYNAMIC_LINK_NOT_ACTIVATED: "auth/dynamic-link-not-activated",
  EMAIL_CHANGE_NEEDS_VERIFICATION: "auth/email-change-needs-verification",
  EMAIL_EXISTS: "auth/email-already-in-use",
  EMULATOR_CONFIG_FAILED: "auth/emulator-config-failed",
  EXPIRED_OOB_CODE: "auth/expired-action-code",
  EXPIRED_POPUP_REQUEST: "auth/cancelled-popup-request",
  INTERNAL_ERROR: "auth/internal-error",
  INVALID_API_KEY: "auth/invalid-api-key",
  INVALID_APP_CREDENTIAL: "auth/invalid-app-credential",
  INVALID_APP_ID: "auth/invalid-app-id",
  INVALID_AUTH: "auth/invalid-user-token",
  INVALID_AUTH_EVENT: "auth/invalid-auth-event",
  INVALID_CERT_HASH: "auth/invalid-cert-hash",
  INVALID_CODE: "auth/invalid-verification-code",
  INVALID_CONTINUE_URI: "auth/invalid-continue-uri",
  INVALID_CORDOVA_CONFIGURATION: "auth/invalid-cordova-configuration",
  INVALID_CUSTOM_TOKEN: "auth/invalid-custom-token",
  INVALID_DYNAMIC_LINK_DOMAIN: "auth/invalid-dynamic-link-domain",
  INVALID_EMAIL: "auth/invalid-email",
  INVALID_EMULATOR_SCHEME: "auth/invalid-emulator-scheme",
  INVALID_IDP_RESPONSE: "auth/invalid-credential",
  INVALID_LOGIN_CREDENTIALS: "auth/invalid-credential",
  INVALID_MESSAGE_PAYLOAD: "auth/invalid-message-payload",
  INVALID_MFA_SESSION: "auth/invalid-multi-factor-session",
  INVALID_OAUTH_CLIENT_ID: "auth/invalid-oauth-client-id",
  INVALID_OAUTH_PROVIDER: "auth/invalid-oauth-provider",
  INVALID_OOB_CODE: "auth/invalid-action-code",
  INVALID_ORIGIN: "auth/unauthorized-domain",
  INVALID_PASSWORD: "auth/wrong-password",
  INVALID_PERSISTENCE: "auth/invalid-persistence-type",
  INVALID_PHONE_NUMBER: "auth/invalid-phone-number",
  INVALID_PROVIDER_ID: "auth/invalid-provider-id",
  INVALID_RECIPIENT_EMAIL: "auth/invalid-recipient-email",
  INVALID_SENDER: "auth/invalid-sender",
  INVALID_SESSION_INFO: "auth/invalid-verification-id",
  INVALID_TENANT_ID: "auth/invalid-tenant-id",
  MFA_INFO_NOT_FOUND: "auth/multi-factor-info-not-found",
  MFA_REQUIRED: "auth/multi-factor-auth-required",
  MISSING_ANDROID_PACKAGE_NAME: "auth/missing-android-pkg-name",
  MISSING_APP_CREDENTIAL: "auth/missing-app-credential",
  MISSING_AUTH_DOMAIN: "auth/auth-domain-config-required",
  MISSING_CODE: "auth/missing-verification-code",
  MISSING_CONTINUE_URI: "auth/missing-continue-uri",
  MISSING_IFRAME_START: "auth/missing-iframe-start",
  MISSING_IOS_BUNDLE_ID: "auth/missing-ios-bundle-id",
  MISSING_OR_INVALID_NONCE: "auth/missing-or-invalid-nonce",
  MISSING_MFA_INFO: "auth/missing-multi-factor-info",
  MISSING_MFA_SESSION: "auth/missing-multi-factor-session",
  MISSING_PHONE_NUMBER: "auth/missing-phone-number",
  MISSING_SESSION_INFO: "auth/missing-verification-id",
  MODULE_DESTROYED: "auth/app-deleted",
  NEED_CONFIRMATION: "auth/account-exists-with-different-credential",
  NETWORK_REQUEST_FAILED: "auth/network-request-failed",
  NULL_USER: "auth/null-user",
  NO_AUTH_EVENT: "auth/no-auth-event",
  NO_SUCH_PROVIDER: "auth/no-such-provider",
  OPERATION_NOT_ALLOWED: "auth/operation-not-allowed",
  OPERATION_NOT_SUPPORTED: "auth/operation-not-supported-in-this-environment",
  POPUP_BLOCKED: "auth/popup-blocked",
  POPUP_CLOSED_BY_USER: "auth/popup-closed-by-user",
  PROVIDER_ALREADY_LINKED: "auth/provider-already-linked",
  QUOTA_EXCEEDED: "auth/quota-exceeded",
  REDIRECT_CANCELLED_BY_USER: "auth/redirect-cancelled-by-user",
  REDIRECT_OPERATION_PENDING: "auth/redirect-operation-pending",
  REJECTED_CREDENTIAL: "auth/rejected-credential",
  SECOND_FACTOR_ALREADY_ENROLLED: "auth/second-factor-already-in-use",
  SECOND_FACTOR_LIMIT_EXCEEDED: "auth/maximum-second-factor-count-exceeded",
  TENANT_ID_MISMATCH: "auth/tenant-id-mismatch",
  TIMEOUT: "auth/timeout",
  TOKEN_EXPIRED: "auth/user-token-expired",
  TOO_MANY_ATTEMPTS_TRY_LATER: "auth/too-many-requests",
  UNAUTHORIZED_DOMAIN: "auth/unauthorized-continue-uri",
  UNSUPPORTED_FIRST_FACTOR: "auth/unsupported-first-factor",
  UNSUPPORTED_PERSISTENCE: "auth/unsupported-persistence-type",
  UNSUPPORTED_TENANT_OPERATION: "auth/unsupported-tenant-operation",
  UNVERIFIED_EMAIL: "auth/unverified-email",
  USER_CANCELLED: "auth/user-cancelled",
  USER_DELETED: "auth/user-not-found",
  USER_DISABLED: "auth/user-disabled",
  USER_MISMATCH: "auth/user-mismatch",
  USER_SIGNED_OUT: "auth/user-signed-out",
  WEAK_PASSWORD: "auth/weak-password",
  WEB_STORAGE_UNSUPPORTED: "auth/web-storage-unsupported",
  ALREADY_INITIALIZED: "auth/already-initialized",
  RECAPTCHA_NOT_ENABLED: "auth/recaptcha-not-enabled",
  MISSING_RECAPTCHA_TOKEN: "auth/missing-recaptcha-token",
  INVALID_RECAPTCHA_TOKEN: "auth/invalid-recaptcha-token",
  INVALID_RECAPTCHA_ACTION: "auth/invalid-recaptcha-action",
  MISSING_CLIENT_TYPE: "auth/missing-client-type",
  MISSING_RECAPTCHA_VERSION: "auth/missing-recaptcha-version",
  INVALID_RECAPTCHA_VERSION: "auth/invalid-recaptcha-version",
  INVALID_REQ_TYPE: "auth/invalid-req-type"
};
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const qe = new Un("@firebase/auth");
function _i(t, ...e) {
  qe.logLevel <= U.WARN && qe.warn(`Auth (${Lt}): ${t}`, ...e);
}
function Be(t, ...e) {
  qe.logLevel <= U.ERROR && qe.error(`Auth (${Lt}): ${t}`, ...e);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Yt(t, ...e) {
  throw Mt(t, ...e);
}
function qn(t, ...e) {
  return Mt(t, ...e);
}
function Gn(t, e, n) {
  const r = Object.assign(Object.assign({}, pi()), { [e]: n });
  return new Le("auth", "Firebase", r).create(e, {
    appName: t.name
  });
}
function He(t) {
  return Gn(t, "operation-not-supported-in-this-environment", "Operations that alter the current user are not supported in conjunction with FirebaseServerApp");
}
function Mt(t, ...e) {
  if (typeof t != "string") {
    const n = e[0], r = [...e.slice(1)];
    return r[0] && (r[0].appName = t.name), t._errorFactory.create(n, ...r);
  }
  return jn.create(t, ...e);
}
function L(t, e, ...n) {
  if (!t)
    throw Mt(e, ...n);
}
function Re(t) {
  const e = "INTERNAL ASSERTION FAILED: " + t;
  throw Be(e), new Error(e);
}
function Ge(t, e) {
  t || Re(e);
}
function gi() {
  return Xt() === "http:" || Xt() === "https:";
}
function Xt() {
  var t;
  return typeof self < "u" && ((t = self.location) === null || t === void 0 ? void 0 : t.protocol) || null;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function yi() {
  return typeof navigator < "u" && navigator && "onLine" in navigator && typeof navigator.onLine == "boolean" && // Apply only for traditional web apps and Chrome extensions.
  // This is especially true for Cordova apps which have unreliable
  // navigator.onLine behavior unless cordova-plugin-network-information is
  // installed which overwrites the native navigator.onLine value and
  // defines navigator.connection.
  (gi() || ns() || "connection" in navigator) ? navigator.onLine : !0;
}
function Ei() {
  if (typeof navigator > "u")
    return null;
  const t = navigator;
  return (
    // Most reliable, but only supported in Chrome/Firefox.
    t.languages && t.languages[0] || // Supported in most browsers, but returns the language of the browser
    // UI, not the language set in browser settings.
    t.language || // Couldn't determine language.
    null
  );
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Me {
  constructor(e, n) {
    this.shortDelay = e, this.longDelay = n, Ge(n > e, "Short delay should be less than long delay!"), this.isMobile = ts() || rs();
  }
  get() {
    return yi() ? this.isMobile ? this.longDelay : this.shortDelay : Math.min(5e3, this.shortDelay);
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function bi(t, e) {
  Ge(t.emulator, "Emulator should always be set here");
  const { url: n } = t.emulator;
  return e ? `${n}${e.startsWith("/") ? e.slice(1) : e}` : n;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Wn {
  static initialize(e, n, r) {
    this.fetchImpl = e, n && (this.headersImpl = n), r && (this.responseImpl = r);
  }
  static fetch() {
    if (this.fetchImpl)
      return this.fetchImpl;
    if (typeof self < "u" && "fetch" in self)
      return self.fetch;
    if (typeof globalThis < "u" && globalThis.fetch)
      return globalThis.fetch;
    if (typeof fetch < "u")
      return fetch;
    Re("Could not find fetch implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static headers() {
    if (this.headersImpl)
      return this.headersImpl;
    if (typeof self < "u" && "Headers" in self)
      return self.Headers;
    if (typeof globalThis < "u" && globalThis.Headers)
      return globalThis.Headers;
    if (typeof Headers < "u")
      return Headers;
    Re("Could not find Headers implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static response() {
    if (this.responseImpl)
      return this.responseImpl;
    if (typeof self < "u" && "Response" in self)
      return self.Response;
    if (typeof globalThis < "u" && globalThis.Response)
      return globalThis.Response;
    if (typeof Response < "u")
      return Response;
    Re("Could not find Response implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const wi = {
  // Custom token errors.
  CREDENTIAL_MISMATCH: "custom-token-mismatch",
  // This can only happen if the SDK sends a bad request.
  MISSING_CUSTOM_TOKEN: "internal-error",
  // Create Auth URI errors.
  INVALID_IDENTIFIER: "invalid-email",
  // This can only happen if the SDK sends a bad request.
  MISSING_CONTINUE_URI: "internal-error",
  // Sign in with email and password errors (some apply to sign up too).
  INVALID_PASSWORD: "wrong-password",
  // This can only happen if the SDK sends a bad request.
  MISSING_PASSWORD: "missing-password",
  // Thrown if Email Enumeration Protection is enabled in the project and the email or password is
  // invalid.
  INVALID_LOGIN_CREDENTIALS: "invalid-credential",
  // Sign up with email and password errors.
  EMAIL_EXISTS: "email-already-in-use",
  PASSWORD_LOGIN_DISABLED: "operation-not-allowed",
  // Verify assertion for sign in with credential errors:
  INVALID_IDP_RESPONSE: "invalid-credential",
  INVALID_PENDING_TOKEN: "invalid-credential",
  FEDERATED_USER_ID_ALREADY_LINKED: "credential-already-in-use",
  // This can only happen if the SDK sends a bad request.
  MISSING_REQ_TYPE: "internal-error",
  // Send Password reset email errors:
  EMAIL_NOT_FOUND: "user-not-found",
  RESET_PASSWORD_EXCEED_LIMIT: "too-many-requests",
  EXPIRED_OOB_CODE: "expired-action-code",
  INVALID_OOB_CODE: "invalid-action-code",
  // This can only happen if the SDK sends a bad request.
  MISSING_OOB_CODE: "internal-error",
  // Operations that require ID token in request:
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "requires-recent-login",
  INVALID_ID_TOKEN: "invalid-user-token",
  TOKEN_EXPIRED: "user-token-expired",
  USER_NOT_FOUND: "user-token-expired",
  // Other errors.
  TOO_MANY_ATTEMPTS_TRY_LATER: "too-many-requests",
  PASSWORD_DOES_NOT_MEET_REQUIREMENTS: "password-does-not-meet-requirements",
  // Phone Auth related errors.
  INVALID_CODE: "invalid-verification-code",
  INVALID_SESSION_INFO: "invalid-verification-id",
  INVALID_TEMPORARY_PROOF: "invalid-credential",
  MISSING_SESSION_INFO: "missing-verification-id",
  SESSION_EXPIRED: "code-expired",
  // Other action code errors when additional settings passed.
  // MISSING_CONTINUE_URI is getting mapped to INTERNAL_ERROR above.
  // This is OK as this error will be caught by client side validation.
  MISSING_ANDROID_PACKAGE_NAME: "missing-android-pkg-name",
  UNAUTHORIZED_DOMAIN: "unauthorized-continue-uri",
  // getProjectConfig errors when clientId is passed.
  INVALID_OAUTH_CLIENT_ID: "invalid-oauth-client-id",
  // User actions (sign-up or deletion) disabled errors.
  ADMIN_ONLY_OPERATION: "admin-restricted-operation",
  // Multi factor related errors.
  INVALID_MFA_PENDING_CREDENTIAL: "invalid-multi-factor-session",
  MFA_ENROLLMENT_NOT_FOUND: "multi-factor-info-not-found",
  MISSING_MFA_ENROLLMENT_ID: "missing-multi-factor-info",
  MISSING_MFA_PENDING_CREDENTIAL: "missing-multi-factor-session",
  SECOND_FACTOR_EXISTS: "second-factor-already-in-use",
  SECOND_FACTOR_LIMIT_EXCEEDED: "maximum-second-factor-count-exceeded",
  // Blocking functions related errors.
  BLOCKING_FUNCTION_ERROR_RESPONSE: "internal-error",
  // Recaptcha related errors.
  RECAPTCHA_NOT_ENABLED: "recaptcha-not-enabled",
  MISSING_RECAPTCHA_TOKEN: "missing-recaptcha-token",
  INVALID_RECAPTCHA_TOKEN: "invalid-recaptcha-token",
  INVALID_RECAPTCHA_ACTION: "invalid-recaptcha-action",
  MISSING_CLIENT_TYPE: "missing-client-type",
  MISSING_RECAPTCHA_VERSION: "missing-recaptcha-version",
  INVALID_RECAPTCHA_VERSION: "invalid-recaptcha-version",
  INVALID_REQ_TYPE: "invalid-req-type"
  /* AuthErrorCode.INVALID_REQ_TYPE */
};
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Si = new Me(3e4, 6e4);
function Kn(t, e) {
  return t.tenantId && !e.tenantId ? Object.assign(Object.assign({}, e), { tenantId: t.tenantId }) : e;
}
async function Xe(t, e, n, r, s = {}) {
  return zn(t, s, async () => {
    let i = {}, o = {};
    r && (e === "GET" ? o = r : i = {
      body: JSON.stringify(r)
    });
    const a = kn(Object.assign({ key: t.config.apiKey }, o)).slice(1), c = await t._getAdditionalHeaders();
    return c[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/json", t.languageCode && (c[
      "X-Firebase-Locale"
      /* HttpHeader.X_FIREBASE_LOCALE */
    ] = t.languageCode), Wn.fetch()(Jn(t, t.config.apiHost, n, a), Object.assign({
      method: e,
      headers: c,
      referrerPolicy: "no-referrer"
    }, i));
  });
}
async function zn(t, e, n) {
  t._canInitEmulator = !1;
  const r = Object.assign(Object.assign({}, wi), e);
  try {
    const s = new Ai(t), i = await Promise.race([
      n(),
      s.promise
    ]);
    s.clearNetworkTimeout();
    const o = await i.json();
    if ("needConfirmation" in o)
      throw $e(t, "account-exists-with-different-credential", o);
    if (i.ok && !("errorMessage" in o))
      return o;
    {
      const a = i.ok ? o.errorMessage : o.error.message, [c, u] = a.split(" : ");
      if (c === "FEDERATED_USER_ID_ALREADY_LINKED")
        throw $e(t, "credential-already-in-use", o);
      if (c === "EMAIL_EXISTS")
        throw $e(t, "email-already-in-use", o);
      if (c === "USER_DISABLED")
        throw $e(t, "user-disabled", o);
      const d = r[c] || c.toLowerCase().replace(/[_\s]+/g, "-");
      if (u)
        throw Gn(t, d, u);
      Yt(t, d);
    }
  } catch (s) {
    if (s instanceof fe)
      throw s;
    Yt(t, "network-request-failed", { message: String(s) });
  }
}
function Jn(t, e, n, r) {
  const s = `${e}${n}?${r}`;
  return t.config.emulator ? bi(t.config, s) : `${t.config.apiScheme}://${s}`;
}
class Ai {
  constructor(e) {
    this.auth = e, this.timer = null, this.promise = new Promise((n, r) => {
      this.timer = setTimeout(() => r(qn(
        this.auth,
        "network-request-failed"
        /* AuthErrorCode.NETWORK_REQUEST_FAILED */
      )), Si.get());
    });
  }
  clearNetworkTimeout() {
    clearTimeout(this.timer);
  }
}
function $e(t, e, n) {
  const r = {
    appName: t.name
  };
  n.email && (r.email = n.email), n.phoneNumber && (r.phoneNumber = n.phoneNumber);
  const s = qn(t, e, r);
  return s.customData._tokenResponse = n, s;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function Ii(t, e) {
  return Xe(t, "POST", "/v1/accounts:delete", e);
}
async function Yn(t, e) {
  return Xe(t, "POST", "/v1/accounts:lookup", e);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Oe(t) {
  if (t)
    try {
      const e = new Date(Number(t));
      if (!isNaN(e.getTime()))
        return e.toUTCString();
    } catch {
    }
}
async function Ti(t, e = !1) {
  const n = Ye(t), r = await n.getIdToken(e), s = Xn(r);
  L(
    s && s.exp && s.auth_time && s.iat,
    n.auth,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = typeof s.firebase == "object" ? s.firebase : void 0, o = i == null ? void 0 : i.sign_in_provider;
  return {
    claims: s,
    token: r,
    authTime: Oe(lt(s.auth_time)),
    issuedAtTime: Oe(lt(s.iat)),
    expirationTime: Oe(lt(s.exp)),
    signInProvider: o || null,
    signInSecondFactor: (i == null ? void 0 : i.sign_in_second_factor) || null
  };
}
function lt(t) {
  return Number(t) * 1e3;
}
function Xn(t) {
  const [e, n, r] = t.split(".");
  if (e === void 0 || n === void 0 || r === void 0)
    return Be("JWT malformed, contained fewer than 3 sections"), null;
  try {
    const s = Mn(n);
    return s ? JSON.parse(s) : (Be("Failed to decode base64 JWT payload"), null);
  } catch (s) {
    return Be("Caught error parsing JWT payload as JSON", s == null ? void 0 : s.toString()), null;
  }
}
function Qt(t) {
  const e = Xn(t);
  return L(
    e,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), L(
    typeof e.exp < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), L(
    typeof e.iat < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), Number(e.exp) - Number(e.iat);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function wt(t, e, n = !1) {
  if (n)
    return e;
  try {
    return await e;
  } catch (r) {
    throw r instanceof fe && vi(r) && t.auth.currentUser === t && await t.auth.signOut(), r;
  }
}
function vi({ code: t }) {
  return t === "auth/user-disabled" || t === "auth/user-token-expired";
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Ri {
  constructor(e) {
    this.user = e, this.isRunning = !1, this.timerId = null, this.errorBackoff = 3e4;
  }
  _start() {
    this.isRunning || (this.isRunning = !0, this.schedule());
  }
  _stop() {
    this.isRunning && (this.isRunning = !1, this.timerId !== null && clearTimeout(this.timerId));
  }
  getInterval(e) {
    var n;
    if (e) {
      const r = this.errorBackoff;
      return this.errorBackoff = Math.min(
        this.errorBackoff * 2,
        96e4
        /* Duration.RETRY_BACKOFF_MAX */
      ), r;
    } else {
      this.errorBackoff = 3e4;
      const s = ((n = this.user.stsTokenManager.expirationTime) !== null && n !== void 0 ? n : 0) - Date.now() - 3e5;
      return Math.max(0, s);
    }
  }
  schedule(e = !1) {
    if (!this.isRunning)
      return;
    const n = this.getInterval(e);
    this.timerId = setTimeout(async () => {
      await this.iteration();
    }, n);
  }
  async iteration() {
    try {
      await this.user.getIdToken(!0);
    } catch (e) {
      (e == null ? void 0 : e.code) === "auth/network-request-failed" && this.schedule(
        /* wasError */
        !0
      );
      return;
    }
    this.schedule();
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class St {
  constructor(e, n) {
    this.createdAt = e, this.lastLoginAt = n, this._initializeTime();
  }
  _initializeTime() {
    this.lastSignInTime = Oe(this.lastLoginAt), this.creationTime = Oe(this.createdAt);
  }
  _copy(e) {
    this.createdAt = e.createdAt, this.lastLoginAt = e.lastLoginAt, this._initializeTime();
  }
  toJSON() {
    return {
      createdAt: this.createdAt,
      lastLoginAt: this.lastLoginAt
    };
  }
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function We(t) {
  var e;
  const n = t.auth, r = await t.getIdToken(), s = await wt(t, Yn(n, { idToken: r }));
  L(
    s == null ? void 0 : s.users.length,
    n,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = s.users[0];
  t._notifyReloadListener(i);
  const o = !((e = i.providerUserInfo) === null || e === void 0) && e.length ? Qn(i.providerUserInfo) : [], a = Ni(t.providerData, o), c = t.isAnonymous, u = !(t.email && i.passwordHash) && !(a != null && a.length), d = c ? u : !1, h = {
    uid: i.localId,
    displayName: i.displayName || null,
    photoURL: i.photoUrl || null,
    email: i.email || null,
    emailVerified: i.emailVerified || !1,
    phoneNumber: i.phoneNumber || null,
    tenantId: i.tenantId || null,
    providerData: a,
    metadata: new St(i.createdAt, i.lastLoginAt),
    isAnonymous: d
  };
  Object.assign(t, h);
}
async function Oi(t) {
  const e = Ye(t);
  await We(e), await e.auth._persistUserIfCurrent(e), e.auth._notifyListenersIfCurrent(e);
}
function Ni(t, e) {
  return [...t.filter((r) => !e.some((s) => s.providerId === r.providerId)), ...e];
}
function Qn(t) {
  return t.map((e) => {
    var { providerId: n } = e, r = Hn(e, ["providerId"]);
    return {
      providerId: n,
      uid: r.rawId || "",
      displayName: r.displayName || null,
      email: r.email || null,
      phoneNumber: r.phoneNumber || null,
      photoURL: r.photoUrl || null
    };
  });
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function Ci(t, e) {
  const n = await zn(t, {}, async () => {
    const r = kn({
      grant_type: "refresh_token",
      refresh_token: e
    }).slice(1), { tokenApiHost: s, apiKey: i } = t.config, o = Jn(t, s, "/v1/token", `key=${i}`), a = await t._getAdditionalHeaders();
    return a[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/x-www-form-urlencoded", Wn.fetch()(o, {
      method: "POST",
      headers: a,
      body: r
    });
  });
  return {
    accessToken: n.access_token,
    expiresIn: n.expires_in,
    refreshToken: n.refresh_token
  };
}
async function Di(t, e) {
  return Xe(t, "POST", "/v2/accounts:revokeToken", Kn(t, e));
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class _e {
  constructor() {
    this.refreshToken = null, this.accessToken = null, this.expirationTime = null;
  }
  get isExpired() {
    return !this.expirationTime || Date.now() > this.expirationTime - 3e4;
  }
  updateFromServerResponse(e) {
    L(
      e.idToken,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), L(
      typeof e.idToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), L(
      typeof e.refreshToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const n = "expiresIn" in e && typeof e.expiresIn < "u" ? Number(e.expiresIn) : Qt(e.idToken);
    this.updateTokensAndExpiration(e.idToken, e.refreshToken, n);
  }
  updateFromIdToken(e) {
    L(
      e.length !== 0,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const n = Qt(e);
    this.updateTokensAndExpiration(e, null, n);
  }
  async getToken(e, n = !1) {
    return !n && this.accessToken && !this.isExpired ? this.accessToken : (L(
      this.refreshToken,
      e,
      "user-token-expired"
      /* AuthErrorCode.TOKEN_EXPIRED */
    ), this.refreshToken ? (await this.refresh(e, this.refreshToken), this.accessToken) : null);
  }
  clearRefreshToken() {
    this.refreshToken = null;
  }
  async refresh(e, n) {
    const { accessToken: r, refreshToken: s, expiresIn: i } = await Ci(e, n);
    this.updateTokensAndExpiration(r, s, Number(i));
  }
  updateTokensAndExpiration(e, n, r) {
    this.refreshToken = n || null, this.accessToken = e || null, this.expirationTime = Date.now() + r * 1e3;
  }
  static fromJSON(e, n) {
    const { refreshToken: r, accessToken: s, expirationTime: i } = n, o = new _e();
    return r && (L(typeof r == "string", "internal-error", {
      appName: e
    }), o.refreshToken = r), s && (L(typeof s == "string", "internal-error", {
      appName: e
    }), o.accessToken = s), i && (L(typeof i == "number", "internal-error", {
      appName: e
    }), o.expirationTime = i), o;
  }
  toJSON() {
    return {
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      expirationTime: this.expirationTime
    };
  }
  _assign(e) {
    this.accessToken = e.accessToken, this.refreshToken = e.refreshToken, this.expirationTime = e.expirationTime;
  }
  _clone() {
    return Object.assign(new _e(), this.toJSON());
  }
  _performRefresh() {
    return Re("not implemented");
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function te(t, e) {
  L(typeof t == "string" || typeof t > "u", "internal-error", { appName: e });
}
class re {
  constructor(e) {
    var { uid: n, auth: r, stsTokenManager: s } = e, i = Hn(e, ["uid", "auth", "stsTokenManager"]);
    this.providerId = "firebase", this.proactiveRefresh = new Ri(this), this.reloadUserInfo = null, this.reloadListener = null, this.uid = n, this.auth = r, this.stsTokenManager = s, this.accessToken = s.accessToken, this.displayName = i.displayName || null, this.email = i.email || null, this.emailVerified = i.emailVerified || !1, this.phoneNumber = i.phoneNumber || null, this.photoURL = i.photoURL || null, this.isAnonymous = i.isAnonymous || !1, this.tenantId = i.tenantId || null, this.providerData = i.providerData ? [...i.providerData] : [], this.metadata = new St(i.createdAt || void 0, i.lastLoginAt || void 0);
  }
  async getIdToken(e) {
    const n = await wt(this, this.stsTokenManager.getToken(this.auth, e));
    return L(
      n,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.accessToken !== n && (this.accessToken = n, await this.auth._persistUserIfCurrent(this), this.auth._notifyListenersIfCurrent(this)), n;
  }
  getIdTokenResult(e) {
    return Ti(this, e);
  }
  reload() {
    return Oi(this);
  }
  _assign(e) {
    this !== e && (L(
      this.uid === e.uid,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.displayName = e.displayName, this.photoURL = e.photoURL, this.email = e.email, this.emailVerified = e.emailVerified, this.phoneNumber = e.phoneNumber, this.isAnonymous = e.isAnonymous, this.tenantId = e.tenantId, this.providerData = e.providerData.map((n) => Object.assign({}, n)), this.metadata._copy(e.metadata), this.stsTokenManager._assign(e.stsTokenManager));
  }
  _clone(e) {
    const n = new re(Object.assign(Object.assign({}, this), { auth: e, stsTokenManager: this.stsTokenManager._clone() }));
    return n.metadata._copy(this.metadata), n;
  }
  _onReload(e) {
    L(
      !this.reloadListener,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.reloadListener = e, this.reloadUserInfo && (this._notifyReloadListener(this.reloadUserInfo), this.reloadUserInfo = null);
  }
  _notifyReloadListener(e) {
    this.reloadListener ? this.reloadListener(e) : this.reloadUserInfo = e;
  }
  _startProactiveRefresh() {
    this.proactiveRefresh._start();
  }
  _stopProactiveRefresh() {
    this.proactiveRefresh._stop();
  }
  async _updateTokensIfNecessary(e, n = !1) {
    let r = !1;
    e.idToken && e.idToken !== this.stsTokenManager.accessToken && (this.stsTokenManager.updateFromServerResponse(e), r = !0), n && await We(this), await this.auth._persistUserIfCurrent(this), r && this.auth._notifyListenersIfCurrent(this);
  }
  async delete() {
    if (Te(this.auth.app))
      return Promise.reject(He(this.auth));
    const e = await this.getIdToken();
    return await wt(this, Ii(this.auth, { idToken: e })), this.stsTokenManager.clearRefreshToken(), this.auth.signOut();
  }
  toJSON() {
    return Object.assign(Object.assign({
      uid: this.uid,
      email: this.email || void 0,
      emailVerified: this.emailVerified,
      displayName: this.displayName || void 0,
      isAnonymous: this.isAnonymous,
      photoURL: this.photoURL || void 0,
      phoneNumber: this.phoneNumber || void 0,
      tenantId: this.tenantId || void 0,
      providerData: this.providerData.map((e) => Object.assign({}, e)),
      stsTokenManager: this.stsTokenManager.toJSON(),
      // Redirect event ID must be maintained in case there is a pending
      // redirect event.
      _redirectEventId: this._redirectEventId
    }, this.metadata.toJSON()), {
      // Required for compatibility with the legacy SDK (go/firebase-auth-sdk-persistence-parsing):
      apiKey: this.auth.config.apiKey,
      appName: this.auth.name
    });
  }
  get refreshToken() {
    return this.stsTokenManager.refreshToken || "";
  }
  static _fromJSON(e, n) {
    var r, s, i, o, a, c, u, d;
    const h = (r = n.displayName) !== null && r !== void 0 ? r : void 0, T = (s = n.email) !== null && s !== void 0 ? s : void 0, S = (i = n.phoneNumber) !== null && i !== void 0 ? i : void 0, f = (o = n.photoURL) !== null && o !== void 0 ? o : void 0, w = (a = n.tenantId) !== null && a !== void 0 ? a : void 0, g = (c = n._redirectEventId) !== null && c !== void 0 ? c : void 0, _ = (u = n.createdAt) !== null && u !== void 0 ? u : void 0, b = (d = n.lastLoginAt) !== null && d !== void 0 ? d : void 0, { uid: p, emailVerified: v, isAnonymous: A, providerData: D, stsTokenManager: M } = n;
    L(
      p && M,
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const k = _e.fromJSON(this.name, M);
    L(
      typeof p == "string",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), te(h, e.name), te(T, e.name), L(
      typeof v == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), L(
      typeof A == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), te(S, e.name), te(f, e.name), te(w, e.name), te(g, e.name), te(_, e.name), te(b, e.name);
    const B = new re({
      uid: p,
      auth: e,
      email: T,
      emailVerified: v,
      displayName: h,
      isAnonymous: A,
      photoURL: f,
      phoneNumber: S,
      tenantId: w,
      stsTokenManager: k,
      createdAt: _,
      lastLoginAt: b
    });
    return D && Array.isArray(D) && (B.providerData = D.map((x) => Object.assign({}, x))), g && (B._redirectEventId = g), B;
  }
  /**
   * Initialize a User from an idToken server response
   * @param auth
   * @param idTokenResponse
   */
  static async _fromIdTokenResponse(e, n, r = !1) {
    const s = new _e();
    s.updateFromServerResponse(n);
    const i = new re({
      uid: n.localId,
      auth: e,
      stsTokenManager: s,
      isAnonymous: r
    });
    return await We(i), i;
  }
  /**
   * Initialize a User from an idToken server response
   * @param auth
   * @param idTokenResponse
   */
  static async _fromGetAccountInfoResponse(e, n, r) {
    const s = n.users[0];
    L(
      s.localId !== void 0,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const i = s.providerUserInfo !== void 0 ? Qn(s.providerUserInfo) : [], o = !(s.email && s.passwordHash) && !(i != null && i.length), a = new _e();
    a.updateFromIdToken(r);
    const c = new re({
      uid: s.localId,
      auth: e,
      stsTokenManager: a,
      isAnonymous: o
    }), u = {
      uid: s.localId,
      displayName: s.displayName || null,
      photoURL: s.photoUrl || null,
      email: s.email || null,
      emailVerified: s.emailVerified || !1,
      phoneNumber: s.phoneNumber || null,
      tenantId: s.tenantId || null,
      providerData: i,
      metadata: new St(s.createdAt, s.lastLoginAt),
      isAnonymous: !(s.email && s.passwordHash) && !(i != null && i.length)
    };
    return Object.assign(c, u), c;
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Zt = /* @__PURE__ */ new Map();
function ce(t) {
  Ge(t instanceof Function, "Expected a class definition");
  let e = Zt.get(t);
  return e ? (Ge(e instanceof t, "Instance stored in cache mismatched with class"), e) : (e = new t(), Zt.set(t, e), e);
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Zn {
  constructor() {
    this.type = "NONE", this.storage = {};
  }
  async _isAvailable() {
    return !0;
  }
  async _set(e, n) {
    this.storage[e] = n;
  }
  async _get(e) {
    const n = this.storage[e];
    return n === void 0 ? null : n;
  }
  async _remove(e) {
    delete this.storage[e];
  }
  _addListener(e, n) {
  }
  _removeListener(e, n) {
  }
}
Zn.type = "NONE";
const en = Zn;
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function dt(t, e, n) {
  return `firebase:${t}:${e}:${n}`;
}
class ge {
  constructor(e, n, r) {
    this.persistence = e, this.auth = n, this.userKey = r;
    const { config: s, name: i } = this.auth;
    this.fullUserKey = dt(this.userKey, s.apiKey, i), this.fullPersistenceKey = dt("persistence", s.apiKey, i), this.boundEventHandler = n._onStorageEvent.bind(n), this.persistence._addListener(this.fullUserKey, this.boundEventHandler);
  }
  setCurrentUser(e) {
    return this.persistence._set(this.fullUserKey, e.toJSON());
  }
  async getCurrentUser() {
    const e = await this.persistence._get(this.fullUserKey);
    return e ? re._fromJSON(this.auth, e) : null;
  }
  removeCurrentUser() {
    return this.persistence._remove(this.fullUserKey);
  }
  savePersistenceForRedirect() {
    return this.persistence._set(this.fullPersistenceKey, this.persistence.type);
  }
  async setPersistence(e) {
    if (this.persistence === e)
      return;
    const n = await this.getCurrentUser();
    if (await this.removeCurrentUser(), this.persistence = e, n)
      return this.setCurrentUser(n);
  }
  delete() {
    this.persistence._removeListener(this.fullUserKey, this.boundEventHandler);
  }
  static async create(e, n, r = "authUser") {
    if (!n.length)
      return new ge(ce(en), e, r);
    const s = (await Promise.all(n.map(async (u) => {
      if (await u._isAvailable())
        return u;
    }))).filter((u) => u);
    let i = s[0] || ce(en);
    const o = dt(r, e.config.apiKey, e.name);
    let a = null;
    for (const u of n)
      try {
        const d = await u._get(o);
        if (d) {
          const h = re._fromJSON(e, d);
          u !== i && (a = h), i = u;
          break;
        }
      } catch {
      }
    const c = s.filter((u) => u._shouldAllowMigration);
    return !i._shouldAllowMigration || !c.length ? new ge(i, e, r) : (i = c[0], a && await i._set(o, a.toJSON()), await Promise.all(n.map(async (u) => {
      if (u !== i)
        try {
          await u._remove(o);
        } catch {
        }
    })), new ge(i, e, r));
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function tn(t) {
  const e = t.toLowerCase();
  if (e.includes("opera/") || e.includes("opr/") || e.includes("opios/"))
    return "Opera";
  if (ki(e))
    return "IEMobile";
  if (e.includes("msie") || e.includes("trident/"))
    return "IE";
  if (e.includes("edge/"))
    return "Edge";
  if (Pi(e))
    return "Firefox";
  if (e.includes("silk/"))
    return "Silk";
  if (xi(e))
    return "Blackberry";
  if (Fi(e))
    return "Webos";
  if (Li(e))
    return "Safari";
  if ((e.includes("chrome/") || Mi(e)) && !e.includes("edge/"))
    return "Chrome";
  if (Ui(e))
    return "Android";
  {
    const n = /([a-zA-Z\d\.]+)\/[a-zA-Z\d\.]*$/, r = t.match(n);
    if ((r == null ? void 0 : r.length) === 2)
      return r[1];
  }
  return "Other";
}
function Pi(t = Q()) {
  return /firefox\//i.test(t);
}
function Li(t = Q()) {
  const e = t.toLowerCase();
  return e.includes("safari/") && !e.includes("chrome/") && !e.includes("crios/") && !e.includes("android");
}
function Mi(t = Q()) {
  return /crios\//i.test(t);
}
function ki(t = Q()) {
  return /iemobile/i.test(t);
}
function Ui(t = Q()) {
  return /android/i.test(t);
}
function xi(t = Q()) {
  return /blackberry/i.test(t);
}
function Fi(t = Q()) {
  return /webos/i.test(t);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function er(t, e = []) {
  let n;
  switch (t) {
    case "Browser":
      n = tn(Q());
      break;
    case "Worker":
      n = `${tn(Q())}-${t}`;
      break;
    default:
      n = t;
  }
  const r = e.length ? e.join(",") : "FirebaseCore-web";
  return `${n}/JsCore/${Lt}/${r}`;
}
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class $i {
  constructor(e) {
    this.auth = e, this.queue = [];
  }
  pushCallback(e, n) {
    const r = (i) => new Promise((o, a) => {
      try {
        const c = e(i);
        o(c);
      } catch (c) {
        a(c);
      }
    });
    r.onAbort = n, this.queue.push(r);
    const s = this.queue.length - 1;
    return () => {
      this.queue[s] = () => Promise.resolve();
    };
  }
  async runMiddleware(e) {
    if (this.auth.currentUser === e)
      return;
    const n = [];
    try {
      for (const r of this.queue)
        await r(e), r.onAbort && n.push(r.onAbort);
    } catch (r) {
      n.reverse();
      for (const s of n)
        try {
          s();
        } catch {
        }
      throw this.auth._errorFactory.create("login-blocked", {
        originalMessage: r == null ? void 0 : r.message
      });
    }
  }
}
/**
 * @license
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function Bi(t, e = {}) {
  return Xe(t, "GET", "/v2/passwordPolicy", Kn(t, e));
}
/**
 * @license
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Hi = 6;
class Vi {
  constructor(e) {
    var n, r, s, i;
    const o = e.customStrengthOptions;
    this.customStrengthOptions = {}, this.customStrengthOptions.minPasswordLength = (n = o.minPasswordLength) !== null && n !== void 0 ? n : Hi, o.maxPasswordLength && (this.customStrengthOptions.maxPasswordLength = o.maxPasswordLength), o.containsLowercaseCharacter !== void 0 && (this.customStrengthOptions.containsLowercaseLetter = o.containsLowercaseCharacter), o.containsUppercaseCharacter !== void 0 && (this.customStrengthOptions.containsUppercaseLetter = o.containsUppercaseCharacter), o.containsNumericCharacter !== void 0 && (this.customStrengthOptions.containsNumericCharacter = o.containsNumericCharacter), o.containsNonAlphanumericCharacter !== void 0 && (this.customStrengthOptions.containsNonAlphanumericCharacter = o.containsNonAlphanumericCharacter), this.enforcementState = e.enforcementState, this.enforcementState === "ENFORCEMENT_STATE_UNSPECIFIED" && (this.enforcementState = "OFF"), this.allowedNonAlphanumericCharacters = (s = (r = e.allowedNonAlphanumericCharacters) === null || r === void 0 ? void 0 : r.join("")) !== null && s !== void 0 ? s : "", this.forceUpgradeOnSignin = (i = e.forceUpgradeOnSignin) !== null && i !== void 0 ? i : !1, this.schemaVersion = e.schemaVersion;
  }
  validatePassword(e) {
    var n, r, s, i, o, a;
    const c = {
      isValid: !0,
      passwordPolicy: this
    };
    return this.validatePasswordLengthOptions(e, c), this.validatePasswordCharacterOptions(e, c), c.isValid && (c.isValid = (n = c.meetsMinPasswordLength) !== null && n !== void 0 ? n : !0), c.isValid && (c.isValid = (r = c.meetsMaxPasswordLength) !== null && r !== void 0 ? r : !0), c.isValid && (c.isValid = (s = c.containsLowercaseLetter) !== null && s !== void 0 ? s : !0), c.isValid && (c.isValid = (i = c.containsUppercaseLetter) !== null && i !== void 0 ? i : !0), c.isValid && (c.isValid = (o = c.containsNumericCharacter) !== null && o !== void 0 ? o : !0), c.isValid && (c.isValid = (a = c.containsNonAlphanumericCharacter) !== null && a !== void 0 ? a : !0), c;
  }
  /**
   * Validates that the password meets the length options for the policy.
   *
   * @param password Password to validate.
   * @param status Validation status.
   */
  validatePasswordLengthOptions(e, n) {
    const r = this.customStrengthOptions.minPasswordLength, s = this.customStrengthOptions.maxPasswordLength;
    r && (n.meetsMinPasswordLength = e.length >= r), s && (n.meetsMaxPasswordLength = e.length <= s);
  }
  /**
   * Validates that the password meets the character options for the policy.
   *
   * @param password Password to validate.
   * @param status Validation status.
   */
  validatePasswordCharacterOptions(e, n) {
    this.updatePasswordCharacterOptionsStatuses(
      n,
      /* containsLowercaseCharacter= */
      !1,
      /* containsUppercaseCharacter= */
      !1,
      /* containsNumericCharacter= */
      !1,
      /* containsNonAlphanumericCharacter= */
      !1
    );
    let r;
    for (let s = 0; s < e.length; s++)
      r = e.charAt(s), this.updatePasswordCharacterOptionsStatuses(
        n,
        /* containsLowercaseCharacter= */
        r >= "a" && r <= "z",
        /* containsUppercaseCharacter= */
        r >= "A" && r <= "Z",
        /* containsNumericCharacter= */
        r >= "0" && r <= "9",
        /* containsNonAlphanumericCharacter= */
        this.allowedNonAlphanumericCharacters.includes(r)
      );
  }
  /**
   * Updates the running validation status with the statuses for the character options.
   * Expected to be called each time a character is processed to update each option status
   * based on the current character.
   *
   * @param status Validation status.
   * @param containsLowercaseCharacter Whether the character is a lowercase letter.
   * @param containsUppercaseCharacter Whether the character is an uppercase letter.
   * @param containsNumericCharacter Whether the character is a numeric character.
   * @param containsNonAlphanumericCharacter Whether the character is a non-alphanumeric character.
   */
  updatePasswordCharacterOptionsStatuses(e, n, r, s, i) {
    this.customStrengthOptions.containsLowercaseLetter && (e.containsLowercaseLetter || (e.containsLowercaseLetter = n)), this.customStrengthOptions.containsUppercaseLetter && (e.containsUppercaseLetter || (e.containsUppercaseLetter = r)), this.customStrengthOptions.containsNumericCharacter && (e.containsNumericCharacter || (e.containsNumericCharacter = s)), this.customStrengthOptions.containsNonAlphanumericCharacter && (e.containsNonAlphanumericCharacter || (e.containsNonAlphanumericCharacter = i));
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class ji {
  constructor(e, n, r, s) {
    this.app = e, this.heartbeatServiceProvider = n, this.appCheckServiceProvider = r, this.config = s, this.currentUser = null, this.emulatorConfig = null, this.operations = Promise.resolve(), this.authStateSubscription = new nn(this), this.idTokenSubscription = new nn(this), this.beforeStateQueue = new $i(this), this.redirectUser = null, this.isProactiveRefreshEnabled = !1, this.EXPECTED_PASSWORD_POLICY_SCHEMA_VERSION = 1, this._canInitEmulator = !0, this._isInitialized = !1, this._deleted = !1, this._initializationPromise = null, this._popupRedirectResolver = null, this._errorFactory = jn, this._agentRecaptchaConfig = null, this._tenantRecaptchaConfigs = {}, this._projectPasswordPolicy = null, this._tenantPasswordPolicies = {}, this.lastNotifiedUid = void 0, this.languageCode = null, this.tenantId = null, this.settings = { appVerificationDisabledForTesting: !1 }, this.frameworks = [], this.name = e.name, this.clientVersion = s.sdkClientVersion;
  }
  _initializeWithPersistence(e, n) {
    return n && (this._popupRedirectResolver = ce(n)), this._initializationPromise = this.queue(async () => {
      var r, s;
      if (!this._deleted && (this.persistenceManager = await ge.create(this, e), !this._deleted)) {
        if (!((r = this._popupRedirectResolver) === null || r === void 0) && r._shouldInitProactively)
          try {
            await this._popupRedirectResolver._initialize(this);
          } catch {
          }
        await this.initializeCurrentUser(n), this.lastNotifiedUid = ((s = this.currentUser) === null || s === void 0 ? void 0 : s.uid) || null, !this._deleted && (this._isInitialized = !0);
      }
    }), this._initializationPromise;
  }
  /**
   * If the persistence is changed in another window, the user manager will let us know
   */
  async _onStorageEvent() {
    if (this._deleted)
      return;
    const e = await this.assertedPersistence.getCurrentUser();
    if (!(!this.currentUser && !e)) {
      if (this.currentUser && e && this.currentUser.uid === e.uid) {
        this._currentUser._assign(e), await this.currentUser.getIdToken();
        return;
      }
      await this._updateCurrentUser(
        e,
        /* skipBeforeStateCallbacks */
        !0
      );
    }
  }
  async initializeCurrentUserFromIdToken(e) {
    try {
      const n = await Yn(this, { idToken: e }), r = await re._fromGetAccountInfoResponse(this, n, e);
      await this.directlySetCurrentUser(r);
    } catch (n) {
      console.warn("FirebaseServerApp could not login user with provided authIdToken: ", n), await this.directlySetCurrentUser(null);
    }
  }
  async initializeCurrentUser(e) {
    var n;
    if (Te(this.app)) {
      const o = this.app.settings.authIdToken;
      return o ? new Promise((a) => {
        setTimeout(() => this.initializeCurrentUserFromIdToken(o).then(a, a));
      }) : this.directlySetCurrentUser(null);
    }
    const r = await this.assertedPersistence.getCurrentUser();
    let s = r, i = !1;
    if (e && this.config.authDomain) {
      await this.getOrInitRedirectPersistenceManager();
      const o = (n = this.redirectUser) === null || n === void 0 ? void 0 : n._redirectEventId, a = s == null ? void 0 : s._redirectEventId, c = await this.tryRedirectSignIn(e);
      (!o || o === a) && (c != null && c.user) && (s = c.user, i = !0);
    }
    if (!s)
      return this.directlySetCurrentUser(null);
    if (!s._redirectEventId) {
      if (i)
        try {
          await this.beforeStateQueue.runMiddleware(s);
        } catch (o) {
          s = r, this._popupRedirectResolver._overrideRedirectResult(this, () => Promise.reject(o));
        }
      return s ? this.reloadAndSetCurrentUserOrClear(s) : this.directlySetCurrentUser(null);
    }
    return L(
      this._popupRedirectResolver,
      this,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), await this.getOrInitRedirectPersistenceManager(), this.redirectUser && this.redirectUser._redirectEventId === s._redirectEventId ? this.directlySetCurrentUser(s) : this.reloadAndSetCurrentUserOrClear(s);
  }
  async tryRedirectSignIn(e) {
    let n = null;
    try {
      n = await this._popupRedirectResolver._completeRedirectFn(this, e, !0);
    } catch {
      await this._setRedirectUser(null);
    }
    return n;
  }
  async reloadAndSetCurrentUserOrClear(e) {
    try {
      await We(e);
    } catch (n) {
      if ((n == null ? void 0 : n.code) !== "auth/network-request-failed")
        return this.directlySetCurrentUser(null);
    }
    return this.directlySetCurrentUser(e);
  }
  useDeviceLanguage() {
    this.languageCode = Ei();
  }
  async _delete() {
    this._deleted = !0;
  }
  async updateCurrentUser(e) {
    if (Te(this.app))
      return Promise.reject(He(this));
    const n = e ? Ye(e) : null;
    return n && L(
      n.auth.config.apiKey === this.config.apiKey,
      this,
      "invalid-user-token"
      /* AuthErrorCode.INVALID_AUTH */
    ), this._updateCurrentUser(n && n._clone(this));
  }
  async _updateCurrentUser(e, n = !1) {
    if (!this._deleted)
      return e && L(
        this.tenantId === e.tenantId,
        this,
        "tenant-id-mismatch"
        /* AuthErrorCode.TENANT_ID_MISMATCH */
      ), n || await this.beforeStateQueue.runMiddleware(e), this.queue(async () => {
        await this.directlySetCurrentUser(e), this.notifyAuthListeners();
      });
  }
  async signOut() {
    return Te(this.app) ? Promise.reject(He(this)) : (await this.beforeStateQueue.runMiddleware(null), (this.redirectPersistenceManager || this._popupRedirectResolver) && await this._setRedirectUser(null), this._updateCurrentUser(
      null,
      /* skipBeforeStateCallbacks */
      !0
    ));
  }
  setPersistence(e) {
    return Te(this.app) ? Promise.reject(He(this)) : this.queue(async () => {
      await this.assertedPersistence.setPersistence(ce(e));
    });
  }
  _getRecaptchaConfig() {
    return this.tenantId == null ? this._agentRecaptchaConfig : this._tenantRecaptchaConfigs[this.tenantId];
  }
  async validatePassword(e) {
    this._getPasswordPolicyInternal() || await this._updatePasswordPolicy();
    const n = this._getPasswordPolicyInternal();
    return n.schemaVersion !== this.EXPECTED_PASSWORD_POLICY_SCHEMA_VERSION ? Promise.reject(this._errorFactory.create("unsupported-password-policy-schema-version", {})) : n.validatePassword(e);
  }
  _getPasswordPolicyInternal() {
    return this.tenantId === null ? this._projectPasswordPolicy : this._tenantPasswordPolicies[this.tenantId];
  }
  async _updatePasswordPolicy() {
    const e = await Bi(this), n = new Vi(e);
    this.tenantId === null ? this._projectPasswordPolicy = n : this._tenantPasswordPolicies[this.tenantId] = n;
  }
  _getPersistence() {
    return this.assertedPersistence.persistence.type;
  }
  _updateErrorMap(e) {
    this._errorFactory = new Le("auth", "Firebase", e());
  }
  onAuthStateChanged(e, n, r) {
    return this.registerStateListener(this.authStateSubscription, e, n, r);
  }
  beforeAuthStateChanged(e, n) {
    return this.beforeStateQueue.pushCallback(e, n);
  }
  onIdTokenChanged(e, n, r) {
    return this.registerStateListener(this.idTokenSubscription, e, n, r);
  }
  authStateReady() {
    return new Promise((e, n) => {
      if (this.currentUser)
        e();
      else {
        const r = this.onAuthStateChanged(() => {
          r(), e();
        }, n);
      }
    });
  }
  /**
   * Revokes the given access token. Currently only supports Apple OAuth access tokens.
   */
  async revokeAccessToken(e) {
    if (this.currentUser) {
      const n = await this.currentUser.getIdToken(), r = {
        providerId: "apple.com",
        tokenType: "ACCESS_TOKEN",
        token: e,
        idToken: n
      };
      this.tenantId != null && (r.tenantId = this.tenantId), await Di(this, r);
    }
  }
  toJSON() {
    var e;
    return {
      apiKey: this.config.apiKey,
      authDomain: this.config.authDomain,
      appName: this.name,
      currentUser: (e = this._currentUser) === null || e === void 0 ? void 0 : e.toJSON()
    };
  }
  async _setRedirectUser(e, n) {
    const r = await this.getOrInitRedirectPersistenceManager(n);
    return e === null ? r.removeCurrentUser() : r.setCurrentUser(e);
  }
  async getOrInitRedirectPersistenceManager(e) {
    if (!this.redirectPersistenceManager) {
      const n = e && ce(e) || this._popupRedirectResolver;
      L(
        n,
        this,
        "argument-error"
        /* AuthErrorCode.ARGUMENT_ERROR */
      ), this.redirectPersistenceManager = await ge.create(
        this,
        [ce(n._redirectPersistence)],
        "redirectUser"
        /* KeyName.REDIRECT_USER */
      ), this.redirectUser = await this.redirectPersistenceManager.getCurrentUser();
    }
    return this.redirectPersistenceManager;
  }
  async _redirectUserForId(e) {
    var n, r;
    return this._isInitialized && await this.queue(async () => {
    }), ((n = this._currentUser) === null || n === void 0 ? void 0 : n._redirectEventId) === e ? this._currentUser : ((r = this.redirectUser) === null || r === void 0 ? void 0 : r._redirectEventId) === e ? this.redirectUser : null;
  }
  async _persistUserIfCurrent(e) {
    if (e === this.currentUser)
      return this.queue(async () => this.directlySetCurrentUser(e));
  }
  /** Notifies listeners only if the user is current */
  _notifyListenersIfCurrent(e) {
    e === this.currentUser && this.notifyAuthListeners();
  }
  _key() {
    return `${this.config.authDomain}:${this.config.apiKey}:${this.name}`;
  }
  _startProactiveRefresh() {
    this.isProactiveRefreshEnabled = !0, this.currentUser && this._currentUser._startProactiveRefresh();
  }
  _stopProactiveRefresh() {
    this.isProactiveRefreshEnabled = !1, this.currentUser && this._currentUser._stopProactiveRefresh();
  }
  /** Returns the current user cast as the internal type */
  get _currentUser() {
    return this.currentUser;
  }
  notifyAuthListeners() {
    var e, n;
    if (!this._isInitialized)
      return;
    this.idTokenSubscription.next(this.currentUser);
    const r = (n = (e = this.currentUser) === null || e === void 0 ? void 0 : e.uid) !== null && n !== void 0 ? n : null;
    this.lastNotifiedUid !== r && (this.lastNotifiedUid = r, this.authStateSubscription.next(this.currentUser));
  }
  registerStateListener(e, n, r, s) {
    if (this._deleted)
      return () => {
      };
    const i = typeof n == "function" ? n : n.next.bind(n);
    let o = !1;
    const a = this._isInitialized ? Promise.resolve() : this._initializationPromise;
    if (L(
      a,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), a.then(() => {
      o || i(this.currentUser);
    }), typeof n == "function") {
      const c = e.addObserver(n, r, s);
      return () => {
        o = !0, c();
      };
    } else {
      const c = e.addObserver(n);
      return () => {
        o = !0, c();
      };
    }
  }
  /**
   * Unprotected (from race conditions) method to set the current user. This
   * should only be called from within a queued callback. This is necessary
   * because the queue shouldn't rely on another queued callback.
   */
  async directlySetCurrentUser(e) {
    this.currentUser && this.currentUser !== e && this._currentUser._stopProactiveRefresh(), e && this.isProactiveRefreshEnabled && e._startProactiveRefresh(), this.currentUser = e, e ? await this.assertedPersistence.setCurrentUser(e) : await this.assertedPersistence.removeCurrentUser();
  }
  queue(e) {
    return this.operations = this.operations.then(e, e), this.operations;
  }
  get assertedPersistence() {
    return L(
      this.persistenceManager,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.persistenceManager;
  }
  _logFramework(e) {
    !e || this.frameworks.includes(e) || (this.frameworks.push(e), this.frameworks.sort(), this.clientVersion = er(this.config.clientPlatform, this._getFrameworks()));
  }
  _getFrameworks() {
    return this.frameworks;
  }
  async _getAdditionalHeaders() {
    var e;
    const n = {
      "X-Client-Version": this.clientVersion
    };
    this.app.options.appId && (n[
      "X-Firebase-gmpid"
      /* HttpHeader.X_FIREBASE_GMPID */
    ] = this.app.options.appId);
    const r = await ((e = this.heartbeatServiceProvider.getImmediate({
      optional: !0
    })) === null || e === void 0 ? void 0 : e.getHeartbeatsHeader());
    r && (n[
      "X-Firebase-Client"
      /* HttpHeader.X_FIREBASE_CLIENT */
    ] = r);
    const s = await this._getAppCheckToken();
    return s && (n[
      "X-Firebase-AppCheck"
      /* HttpHeader.X_FIREBASE_APP_CHECK */
    ] = s), n;
  }
  async _getAppCheckToken() {
    var e;
    const n = await ((e = this.appCheckServiceProvider.getImmediate({ optional: !0 })) === null || e === void 0 ? void 0 : e.getToken());
    return n != null && n.error && _i(`Error while retrieving App Check token: ${n.error}`), n == null ? void 0 : n.token;
  }
}
function qi(t) {
  return Ye(t);
}
class nn {
  constructor(e) {
    this.auth = e, this.observer = null, this.addObserver = us((n) => this.observer = n);
  }
  get next() {
    return L(
      this.observer,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.observer.next.bind(this.observer);
  }
}
function Gi(t, e) {
  const n = (e == null ? void 0 : e.persistence) || [], r = (Array.isArray(n) ? n : [n]).map(ce);
  e != null && e.errorMap && t._updateErrorMap(e.errorMap), t._initializeWithPersistence(r, e == null ? void 0 : e.popupRedirectResolver);
}
new Me(3e4, 6e4);
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
new Me(2e3, 1e4);
/**
 * @license
 * Copyright 2020 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
new Me(3e4, 6e4);
/**
 * @license
 * Copyright 2020 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
new Me(5e3, 15e3);
var rn = "@firebase/auth", sn = "1.7.4";
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Wi {
  constructor(e) {
    this.auth = e, this.internalListeners = /* @__PURE__ */ new Map();
  }
  getUid() {
    var e;
    return this.assertAuthConfigured(), ((e = this.auth.currentUser) === null || e === void 0 ? void 0 : e.uid) || null;
  }
  async getToken(e) {
    return this.assertAuthConfigured(), await this.auth._initializationPromise, this.auth.currentUser ? { accessToken: await this.auth.currentUser.getIdToken(e) } : null;
  }
  addAuthTokenListener(e) {
    if (this.assertAuthConfigured(), this.internalListeners.has(e))
      return;
    const n = this.auth.onIdTokenChanged((r) => {
      e((r == null ? void 0 : r.stsTokenManager.accessToken) || null);
    });
    this.internalListeners.set(e, n), this.updateProactiveRefresh();
  }
  removeAuthTokenListener(e) {
    this.assertAuthConfigured();
    const n = this.internalListeners.get(e);
    n && (this.internalListeners.delete(e), n(), this.updateProactiveRefresh());
  }
  assertAuthConfigured() {
    L(
      this.auth._initializationPromise,
      "dependent-sdk-initialized-before-auth"
      /* AuthErrorCode.DEPENDENT_SDK_INIT_BEFORE_AUTH */
    );
  }
  updateProactiveRefresh() {
    this.internalListeners.size > 0 ? this.auth._startProactiveRefresh() : this.auth._stopProactiveRefresh();
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Ki(t) {
  switch (t) {
    case "Node":
      return "node";
    case "ReactNative":
      return "rn";
    case "Worker":
      return "webworker";
    case "Cordova":
      return "cordova";
    case "WebExtension":
      return "web-extension";
    default:
      return;
  }
}
function zi(t) {
  Ce(new Ne(
    "auth",
    (e, { options: n }) => {
      const r = e.getProvider("app").getImmediate(), s = e.getProvider("heartbeat"), i = e.getProvider("app-check-internal"), { apiKey: o, authDomain: a } = r.options;
      L(o && !o.includes(":"), "invalid-api-key", { appName: r.name });
      const c = {
        apiKey: o,
        authDomain: a,
        clientPlatform: t,
        apiHost: "identitytoolkit.googleapis.com",
        tokenApiHost: "securetoken.googleapis.com",
        apiScheme: "https",
        sdkClientVersion: er(t)
      }, u = new ji(r, s, i, c);
      return Gi(u, n), u;
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  ).setInstanceCreatedCallback((e, n, r) => {
    e.getProvider(
      "auth-internal"
      /* _ComponentName.AUTH_INTERNAL */
    ).initialize();
  })), Ce(new Ne(
    "auth-internal",
    (e) => {
      const n = qi(e.getProvider(
        "auth"
        /* _ComponentName.AUTH */
      ).getImmediate());
      return ((r) => new Wi(r))(n);
    },
    "PRIVATE"
    /* ComponentType.PRIVATE */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  )), ve(rn, sn, Ki(t)), ve(rn, sn, "esm2017");
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Ji = 5 * 60;
es("authIdTokenMaxAge");
zi(
  "Browser"
  /* ClientPlatform.BROWSER */
);
const Yi = "SignupMaxUsersReachedError";
class Dc extends Error {
  constructor(e) {
    super(e), this.name = Yi;
  }
}
class $ extends Error {
  /**
   * Creates a new instance of TnError.
   * @param {string} message - The error message.
   * @param {TnErrorOptions} options - The error options.
   */
  constructor(n, r) {
    super(n);
    Ae(this, "code");
    Ae(this, "statusCode");
    Ae(this, "data");
    Ae(this, "message");
    this.message = n, this.code = r == null ? void 0 : r.code, this.statusCode = r == null ? void 0 : r.statusCode, this.data = r == null ? void 0 : r.data;
  }
}
const Pc = {
  MISSING_EMAIL_PASSWORD: "missing-email-password",
  MISSING_EMAIL: "missing-email",
  EMAIL_EXISTS_2: "auth/email-already-exists",
  // not included in AuthErrorCodes for some reason
  UID_EXISTS: "auth/uid-already-exists",
  INVITE_TOKEN_MISSING: "invite-token-missing",
  INVITE_TOKEN_EXPIRED: "invite-token-expired",
  INVITE_TOKEN_USED: "invite-token-used",
  INVITE_TOKEN_ERROR: "invite-token-error",
  CM_NOT_PREMIUM: "cm-not-premium",
  MAGIC_LINK_SEND_ERROR: "magic-link-send-error",
  ...mi
};
function Xi(t, e) {
  const n = {};
  for (const r in t) {
    const s = t[r];
    typeof s == "object" && s !== null && !Array.isArray(s) ? n[r] = Xi(s, e) : n[r] = e(s);
  }
  return n;
}
function Lc(t, e) {
  const n = {};
  for (const r in t) {
    const s = t[r];
    n[r] = e(s);
  }
  return n;
}
function Mc(t) {
  return Object.fromEntries(
    Object.entries(t).filter(([e, n]) => n !== void 0)
  );
}
var Qi = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Zi(t) {
  return t && t.__esModule && Object.prototype.hasOwnProperty.call(t, "default") ? t.default : t;
}
var tr = { exports: {} };
(function(t, e) {
  (function(n, r) {
    t.exports = r();
  })(Qi, function() {
    var n = 1e3, r = 6e4, s = 36e5, i = "millisecond", o = "second", a = "minute", c = "hour", u = "day", d = "week", h = "month", T = "quarter", S = "year", f = "date", w = "Invalid Date", g = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, _ = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, b = { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(R) {
      var E = ["th", "st", "nd", "rd"], m = R % 100;
      return "[" + R + (E[(m - 20) % 10] || E[m] || E[0]) + "]";
    } }, p = function(R, E, m) {
      var I = String(R);
      return !I || I.length >= E ? R : "" + Array(E + 1 - I.length).join(m) + R;
    }, v = { s: p, z: function(R) {
      var E = -R.utcOffset(), m = Math.abs(E), I = Math.floor(m / 60), y = m % 60;
      return (E <= 0 ? "+" : "-") + p(I, 2, "0") + ":" + p(y, 2, "0");
    }, m: function R(E, m) {
      if (E.date() < m.date())
        return -R(m, E);
      var I = 12 * (m.year() - E.year()) + (m.month() - E.month()), y = E.clone().add(I, h), N = m - y < 0, C = E.clone().add(I + (N ? -1 : 1), h);
      return +(-(I + (m - y) / (N ? y - C : C - y)) || 0);
    }, a: function(R) {
      return R < 0 ? Math.ceil(R) || 0 : Math.floor(R);
    }, p: function(R) {
      return { M: h, y: S, w: d, d: u, D: f, h: c, m: a, s: o, ms: i, Q: T }[R] || String(R || "").toLowerCase().replace(/s$/, "");
    }, u: function(R) {
      return R === void 0;
    } }, A = "en", D = {};
    D[A] = b;
    var M = "$isDayjsObject", k = function(R) {
      return R instanceof xe || !(!R || !R[M]);
    }, B = function R(E, m, I) {
      var y;
      if (!E)
        return A;
      if (typeof E == "string") {
        var N = E.toLowerCase();
        D[N] && (y = N), m && (D[N] = m, y = N);
        var C = E.split("-");
        if (!y && C.length > 1)
          return R(C[0]);
      } else {
        var F = E.name;
        D[F] = E, y = F;
      }
      return !I && y && (A = y), y || !I && A;
    }, x = function(R, E) {
      if (k(R))
        return R.clone();
      var m = typeof E == "object" ? E : {};
      return m.date = R, m.args = arguments, new xe(m);
    }, P = v;
    P.l = B, P.i = k, P.w = function(R, E) {
      return x(R, { locale: E.$L, utc: E.$u, x: E.$x, $offset: E.$offset });
    };
    var xe = function() {
      function R(m) {
        this.$L = B(m.locale, null, !0), this.parse(m), this.$x = this.$x || m.x || {}, this[M] = !0;
      }
      var E = R.prototype;
      return E.parse = function(m) {
        this.$d = function(I) {
          var y = I.date, N = I.utc;
          if (y === null)
            return /* @__PURE__ */ new Date(NaN);
          if (P.u(y))
            return /* @__PURE__ */ new Date();
          if (y instanceof Date)
            return new Date(y);
          if (typeof y == "string" && !/Z$/i.test(y)) {
            var C = y.match(g);
            if (C) {
              var F = C[2] - 1 || 0, H = (C[7] || "0").substring(0, 3);
              return N ? new Date(Date.UTC(C[1], F, C[3] || 1, C[4] || 0, C[5] || 0, C[6] || 0, H)) : new Date(C[1], F, C[3] || 1, C[4] || 0, C[5] || 0, C[6] || 0, H);
            }
          }
          return new Date(y);
        }(m), this.init();
      }, E.init = function() {
        var m = this.$d;
        this.$y = m.getFullYear(), this.$M = m.getMonth(), this.$D = m.getDate(), this.$W = m.getDay(), this.$H = m.getHours(), this.$m = m.getMinutes(), this.$s = m.getSeconds(), this.$ms = m.getMilliseconds();
      }, E.$utils = function() {
        return P;
      }, E.isValid = function() {
        return this.$d.toString() !== w;
      }, E.isSame = function(m, I) {
        var y = x(m);
        return this.startOf(I) <= y && y <= this.endOf(I);
      }, E.isAfter = function(m, I) {
        return x(m) < this.startOf(I);
      }, E.isBefore = function(m, I) {
        return this.endOf(I) < x(m);
      }, E.$g = function(m, I, y) {
        return P.u(m) ? this[I] : this.set(y, m);
      }, E.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, E.valueOf = function() {
        return this.$d.getTime();
      }, E.startOf = function(m, I) {
        var y = this, N = !!P.u(I) || I, C = P.p(m), F = function(ae, G) {
          var ee = P.w(y.$u ? Date.UTC(y.$y, G, ae) : new Date(y.$y, G, ae), y);
          return N ? ee : ee.endOf(u);
        }, H = function(ae, G) {
          return P.w(y.toDate()[ae].apply(y.toDate("s"), (N ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(G)), y);
        }, j = this.$W, q = this.$M, Y = this.$D, pe = "set" + (this.$u ? "UTC" : "");
        switch (C) {
          case S:
            return N ? F(1, 0) : F(31, 11);
          case h:
            return N ? F(1, q) : F(0, q + 1);
          case d:
            var oe = this.$locale().weekStart || 0, we = (j < oe ? j + 7 : j) - oe;
            return F(N ? Y - we : Y + (6 - we), q);
          case u:
          case f:
            return H(pe + "Hours", 0);
          case c:
            return H(pe + "Minutes", 1);
          case a:
            return H(pe + "Seconds", 2);
          case o:
            return H(pe + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, E.endOf = function(m) {
        return this.startOf(m, !1);
      }, E.$set = function(m, I) {
        var y, N = P.p(m), C = "set" + (this.$u ? "UTC" : ""), F = (y = {}, y[u] = C + "Date", y[f] = C + "Date", y[h] = C + "Month", y[S] = C + "FullYear", y[c] = C + "Hours", y[a] = C + "Minutes", y[o] = C + "Seconds", y[i] = C + "Milliseconds", y)[N], H = N === u ? this.$D + (I - this.$W) : I;
        if (N === h || N === S) {
          var j = this.clone().set(f, 1);
          j.$d[F](H), j.init(), this.$d = j.set(f, Math.min(this.$D, j.daysInMonth())).$d;
        } else
          F && this.$d[F](H);
        return this.init(), this;
      }, E.set = function(m, I) {
        return this.clone().$set(m, I);
      }, E.get = function(m) {
        return this[P.p(m)]();
      }, E.add = function(m, I) {
        var y, N = this;
        m = Number(m);
        var C = P.p(I), F = function(q) {
          var Y = x(N);
          return P.w(Y.date(Y.date() + Math.round(q * m)), N);
        };
        if (C === h)
          return this.set(h, this.$M + m);
        if (C === S)
          return this.set(S, this.$y + m);
        if (C === u)
          return F(1);
        if (C === d)
          return F(7);
        var H = (y = {}, y[a] = r, y[c] = s, y[o] = n, y)[C] || 1, j = this.$d.getTime() + m * H;
        return P.w(j, this);
      }, E.subtract = function(m, I) {
        return this.add(-1 * m, I);
      }, E.format = function(m) {
        var I = this, y = this.$locale();
        if (!this.isValid())
          return y.invalidDate || w;
        var N = m || "YYYY-MM-DDTHH:mm:ssZ", C = P.z(this), F = this.$H, H = this.$m, j = this.$M, q = y.weekdays, Y = y.months, pe = y.meridiem, oe = function(G, ee, Se, Fe) {
          return G && (G[ee] || G(I, N)) || Se[ee].slice(0, Fe);
        }, we = function(G) {
          return P.s(F % 12 || 12, G, "0");
        }, ae = pe || function(G, ee, Se) {
          var Fe = G < 12 ? "AM" : "PM";
          return Se ? Fe.toLowerCase() : Fe;
        };
        return N.replace(_, function(G, ee) {
          return ee || function(Se) {
            switch (Se) {
              case "YY":
                return String(I.$y).slice(-2);
              case "YYYY":
                return P.s(I.$y, 4, "0");
              case "M":
                return j + 1;
              case "MM":
                return P.s(j + 1, 2, "0");
              case "MMM":
                return oe(y.monthsShort, j, Y, 3);
              case "MMMM":
                return oe(Y, j);
              case "D":
                return I.$D;
              case "DD":
                return P.s(I.$D, 2, "0");
              case "d":
                return String(I.$W);
              case "dd":
                return oe(y.weekdaysMin, I.$W, q, 2);
              case "ddd":
                return oe(y.weekdaysShort, I.$W, q, 3);
              case "dddd":
                return q[I.$W];
              case "H":
                return String(F);
              case "HH":
                return P.s(F, 2, "0");
              case "h":
                return we(1);
              case "hh":
                return we(2);
              case "a":
                return ae(F, H, !0);
              case "A":
                return ae(F, H, !1);
              case "m":
                return String(H);
              case "mm":
                return P.s(H, 2, "0");
              case "s":
                return String(I.$s);
              case "ss":
                return P.s(I.$s, 2, "0");
              case "SSS":
                return P.s(I.$ms, 3, "0");
              case "Z":
                return C;
            }
            return null;
          }(G) || C.replace(":", "");
        });
      }, E.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, E.diff = function(m, I, y) {
        var N, C = this, F = P.p(I), H = x(m), j = (H.utcOffset() - this.utcOffset()) * r, q = this - H, Y = function() {
          return P.m(C, H);
        };
        switch (F) {
          case S:
            N = Y() / 12;
            break;
          case h:
            N = Y();
            break;
          case T:
            N = Y() / 3;
            break;
          case d:
            N = (q - j) / 6048e5;
            break;
          case u:
            N = (q - j) / 864e5;
            break;
          case c:
            N = q / s;
            break;
          case a:
            N = q / r;
            break;
          case o:
            N = q / n;
            break;
          default:
            N = q;
        }
        return y ? N : P.a(N);
      }, E.daysInMonth = function() {
        return this.endOf(h).$D;
      }, E.$locale = function() {
        return D[this.$L];
      }, E.locale = function(m, I) {
        if (!m)
          return this.$L;
        var y = this.clone(), N = B(m, I, !0);
        return N && (y.$L = N), y;
      }, E.clone = function() {
        return P.w(this.$d, this);
      }, E.toDate = function() {
        return new Date(this.valueOf());
      }, E.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, E.toISOString = function() {
        return this.$d.toISOString();
      }, E.toString = function() {
        return this.$d.toUTCString();
      }, R;
    }(), Bt = xe.prototype;
    return x.prototype = Bt, [["$ms", i], ["$s", o], ["$m", a], ["$H", c], ["$W", u], ["$M", h], ["$y", S], ["$D", f]].forEach(function(R) {
      Bt[R[1]] = function(E) {
        return this.$g(E, R[0], R[1]);
      };
    }), x.extend = function(R, E) {
      return R.$i || (R(E, xe, x), R.$i = !0), x;
    }, x.locale = B, x.isDayjs = k, x.unix = function(R) {
      return x(1e3 * R);
    }, x.en = D[A], x.Ls = D, x.p = {}, x;
  });
})(tr);
var eo = tr.exports;
const to = /* @__PURE__ */ Zi(eo);
function kc(t) {
  return to(t).format("YYYY-MM-DDTHH:mm:ss");
}
var no = /* @__PURE__ */ ((t) => (t.Invited = "invited", t.Premium = "premium", t.Basic = "basic", t.Expired = "expired", t))(no || {}), ro = /* @__PURE__ */ ((t) => (t.Brand = "brand", t.Member = "member", t))(ro || {});
const Uc = (t, { length: e = 25 } = {}) => t ? t.length <= e ? t : t.slice(0, e) + "…" : "", xc = (t) => t && t.charAt(0).toUpperCase() + t.slice(1);
function so(t) {
  return t.reduce((e, n) => ({ ...e, [n]: !1 }), {});
}
function Qe(t, e, n) {
  if (!t || !n || !e)
    return null;
  const r = so(Object.values(n)), s = t[e];
  if (!s)
    return r;
  const i = { ...r };
  for (const o of s) {
    const a = n[o];
    a && (o === "other" ? i[a] = t[`${e}Other`] ?? "" : i[a] = !0);
  }
  return i;
}
const io = {
  none: "allergies_none",
  peanuts: "allergies_peanuts",
  treeNuts: "allergies_treenuts",
  dairy: "allergies_dairy",
  eggs: "allergies_eggs",
  wheat: "allergies_wheat",
  gluten: "allergies_gluten",
  soy: "allergies_soy",
  fish: "allergies_fish",
  shellfish: "allergies_shellfish",
  sesame: "allergies_sesame",
  other: "allergies_other"
}, oo = {
  none: "diet_none",
  keto: "diet_keto",
  lowCarb: "diet_lowcarb",
  carnivore: "diet_carnivore",
  paleo: "diet_paleo",
  vegetarian: "diet_vegetarian",
  flexitarian: "diet_flexitarian",
  pescatarian: "diet_pescatarian",
  vegan: "diet_vegan",
  other: "diet_other"
}, ao = {
  supermarketChain: "shopping_supermarket_chain",
  supermarketHealthFood: "shopping_healthfood_supermarket",
  localGroceryStore: "shopping_independent_grocerystore",
  farmerMarket: "shopping_farmers_market",
  warehouseClub: "shopping_warehouse_club",
  onlineGrocery: "shopping_online_grocery",
  convenienceStore: "shopping_convenience_store",
  specialityFoodStore: "shopping_speciality_foodstores",
  others: "shopping_others"
}, co = {
  ketoOrLowCarbDesserts: "purchase_lowcarb_sweets",
  ketoOrLowCarbBread: "purchase_lowcarb_bread",
  ketoOrLowCarbCereals: "purchase_lowcarb_cereals",
  ketoOrLowCarbSnacks: "purchase_lowcarb_snacks",
  ketoOrLowCarbCondimentsAndSauces: "purchase_lowcarb_condiments",
  meatSnacks: "purchase_meat",
  dairyAlternates: "purchase_dairy_alternates",
  lowOrNoSugarBeverages: "purchase_lowsugar_beverages",
  supplementsPowdersProteinShakes: "purchase_supplements",
  kidFriendlyHealthySnacks: "purchase_kid_snacks"
};
function uo(t) {
  return Qe(
    t,
    "allergiesAndIntolerances",
    io
  );
}
function lo(t) {
  return Qe(
    t,
    "dietaryRestrictions",
    oo
  );
}
function ho(t) {
  return Qe(
    t,
    "groceriesShoppingPlace",
    ao
  );
}
function fo(t) {
  return Qe(
    t,
    "frequentlyPurchaseProducts",
    co
  );
}
function Fc(t) {
  const e = {};
  return t.age !== void 0 && (e.age_group = t.age), t.gender !== void 0 && (e.gender = t.gender), t.selfDescription !== void 0 && (e.onboarding_self_description = t.selfDescription), t.eatingHelpHealthCondition !== void 0 && (e.way_eating_manage_health = t.eatingHelpHealthCondition), t.householdPeopleNumber !== void 0 && (e.people_household = t.householdPeopleNumber), t.householdChildrenNumber !== void 0 && (e.children_household = t.householdChildrenNumber), t.zipCode !== void 0 && (e.zip_code = t.zipCode), Object.assign(e, uo(t) || {}), Object.assign(
    e,
    lo(t) || {}
  ), Object.assign(e, ho(t) || {}), Object.assign(
    e,
    fo(t) || {}
  ), e;
}
const $c = {
  PRODUCT_REVIEWS: "is_product_reviews_enabled",
  MAX_SIGNUPS: "max_signups",
  PROMOTIONS_PERIOD: "is_promotions_period_enabled",
  INTERNAL_TEAM_MEMBER: "is_internal_team_member",
  AI_SUMMARY_GENERATION_DATA: "ai_summary_generation_data",
  AI_SUMMARIES: "is_ai_summaries_enabled",
  POINT_SYSTEM_ENABLED: "is_point_system_enabled",
  QUICK_SURVEYS_ENABLED: "quick_survey_enabled",
  QUICK_SURVEYS_DATA: "quick_surveys_data",
  ANALYTICS_TRACKING_DATA: "analytics_tracking_data"
}, Bc = async (t, e) => {
  var s, i;
  const n = await t.getUser(e);
  if (!n)
    return null;
  const r = (i = (s = n == null ? void 0 : n.customClaims) == null ? void 0 : s.brand) == null ? void 0 : i.id;
  return r || null;
}, Ze = "GraphQL Client", on = 0, an = 3, nr = "An error occurred while fetching from the API. Review 'graphQLErrors' for details.", rr = "Response returned unexpected Content-Type:", sr = "An unknown error has occurred. The API did not return a data object or any errors in its response.", At = {
  json: "application/json",
  multipart: "multipart/mixed"
}, cn = "X-SDK-Variant", un = "X-SDK-Version", po = "shopify-graphql-client", mo = "1.0.0", ir = 1e3, _o = [429, 503], or = /@(defer)\b/i, ln = `\r
`, go = /boundary="?([^=";]+)"?/i, dn = ln + ln;
function ie(t, e = Ze) {
  return t.startsWith(`${e}`) ? t : `${e}: ${t}`;
}
function ye(t) {
  return t instanceof Error ? t.message : JSON.stringify(t);
}
function ar(t) {
  return t instanceof Error && t.cause ? t.cause : void 0;
}
function cr(t) {
  return t.flatMap(({ errors: e }) => e ?? []);
}
function ur({ client: t, retries: e }) {
  if (e !== void 0 && (typeof e != "number" || e < on || e > an))
    throw new Error(`${t}: The provided "retries" value (${e}) is invalid - it cannot be less than ${on} or greater than ${an}`);
}
function W(t, e) {
  return e && (typeof e != "object" || Array.isArray(e) || typeof e == "object" && Object.keys(e).length > 0) ? { [t]: e } : {};
}
function lr(t, e) {
  if (t.length === 0)
    return e;
  const r = {
    [t.pop()]: e
  };
  return t.length === 0 ? r : lr(t, r);
}
function dr(t, e) {
  return Object.keys(e || {}).reduce((n, r) => (typeof e[r] == "object" || Array.isArray(e[r])) && t[r] ? (n[r] = dr(t[r], e[r]), n) : (n[r] = e[r], n), Array.isArray(t) ? [...t] : { ...t });
}
function hr([t, ...e]) {
  return e.reduce(dr, { ...t });
}
function yo({ clientLogger: t, customFetchApi: e = fetch, client: n = Ze, defaultRetryWaitTime: r = ir, retriableCodes: s = _o }) {
  const i = async (o, a, c) => {
    const u = a + 1, d = c + 1;
    let h;
    try {
      if (h = await e(...o), t({
        type: "HTTP-Response",
        content: {
          requestParams: o,
          response: h
        }
      }), !h.ok && s.includes(h.status) && u <= d)
        throw new Error();
      return h;
    } catch (T) {
      if (u <= d) {
        const S = h == null ? void 0 : h.headers.get("Retry-After");
        return await Eo(S ? parseInt(S, 10) : r), t({
          type: "HTTP-Retry",
          content: {
            requestParams: o,
            lastResponse: h,
            retryAttempt: a,
            maxRetries: c
          }
        }), i(o, u, c);
      }
      throw new Error(ie(`${c > 0 ? `Attempted maximum number of ${c} network retries. Last message - ` : ""}${ye(T)}`, n));
    }
  };
  return i;
}
async function Eo(t) {
  return new Promise((e) => setTimeout(e, t));
}
function bo({ headers: t, url: e, customFetchApi: n = fetch, retries: r = 0, logger: s }) {
  ur({ client: Ze, retries: r });
  const i = {
    headers: t,
    url: e,
    retries: r
  }, o = wo(s), a = yo({
    customFetchApi: n,
    clientLogger: o,
    defaultRetryWaitTime: ir
  }), c = So(a, i), u = Ao(c), d = Co(c);
  return {
    config: i,
    fetch: c,
    request: u,
    requestStream: d
  };
}
function wo(t) {
  return (e) => {
    t && t(e);
  };
}
async function fr(t) {
  const { errors: e, data: n, extensions: r } = await t.json();
  return {
    ...W("data", n),
    ...W("extensions", r),
    ...e || !n ? {
      errors: {
        networkStatusCode: t.status,
        message: ie(e ? nr : sr),
        ...W("graphQLErrors", e),
        response: t
      }
    } : {}
  };
}
function So(t, { url: e, headers: n, retries: r }) {
  return async (s, i = {}) => {
    const { variables: o, headers: a, url: c, retries: u } = i, d = JSON.stringify({
      query: s,
      variables: o
    });
    ur({ client: Ze, retries: u });
    const h = Object.entries({
      ...n,
      ...a
    }).reduce((S, [f, w]) => (S[f] = Array.isArray(w) ? w.join(", ") : w.toString(), S), {});
    return !h[cn] && !h[un] && (h[cn] = po, h[un] = mo), t([
      c ?? e,
      {
        method: "POST",
        headers: h,
        body: d
      }
    ], 1, u ?? r);
  };
}
function Ao(t) {
  return async (...e) => {
    if (or.test(e[0]))
      throw new Error(ie("This operation will result in a streamable response - use requestStream() instead."));
    try {
      const n = await t(...e), { status: r, statusText: s } = n, i = n.headers.get("content-type") || "";
      return n.ok ? i.includes(At.json) ? fr(n) : {
        errors: {
          networkStatusCode: r,
          message: ie(`${rr} ${i}`),
          response: n
        }
      } : {
        errors: {
          networkStatusCode: r,
          message: ie(s),
          response: n
        }
      };
    } catch (n) {
      return {
        errors: {
          message: ye(n)
        }
      };
    }
  };
}
async function* Io(t) {
  const e = new TextDecoder();
  if (t.body[Symbol.asyncIterator])
    for await (const n of t.body)
      yield e.decode(n);
  else {
    const n = t.body.getReader();
    let r;
    try {
      for (; !(r = await n.read()).done; )
        yield e.decode(r.value);
    } finally {
      n.cancel();
    }
  }
}
function To(t, e) {
  return {
    async *[Symbol.asyncIterator]() {
      try {
        let n = "";
        for await (const r of t)
          if (n += r, n.indexOf(e) > -1) {
            const s = n.lastIndexOf(e), o = n.slice(0, s).split(e).filter((a) => a.trim().length > 0).map((a) => a.slice(a.indexOf(dn) + dn.length).trim());
            o.length > 0 && (yield o), n = n.slice(s + e.length), n.trim() === "--" && (n = "");
          }
      } catch (n) {
        throw new Error(`Error occured while processing stream payload - ${ye(n)}`);
      }
    }
  };
}
function vo(t) {
  return {
    async *[Symbol.asyncIterator]() {
      yield {
        ...await fr(t),
        hasNext: !1
      };
    }
  };
}
function Ro(t) {
  return t.map((e) => {
    try {
      return JSON.parse(e);
    } catch (n) {
      throw new Error(`Error in parsing multipart response - ${ye(n)}`);
    }
  }).map((e) => {
    const { data: n, incremental: r, hasNext: s, extensions: i, errors: o } = e;
    if (!r)
      return {
        data: n || {},
        ...W("errors", o),
        ...W("extensions", i),
        hasNext: s
      };
    const a = r.map(({ data: c, path: u, errors: d }) => ({
      data: c && u ? lr(u, c) : {},
      ...W("errors", d)
    }));
    return {
      data: a.length === 1 ? a[0].data : hr([
        ...a.map(({ data: c }) => c)
      ]),
      ...W("errors", cr(a)),
      hasNext: s
    };
  });
}
function Oo(t, e) {
  if (t.length > 0)
    throw new Error(nr, {
      cause: {
        graphQLErrors: t
      }
    });
  if (Object.keys(e).length === 0)
    throw new Error(sr);
}
function No(t, e) {
  var a;
  const n = (e ?? "").match(go), r = `--${n ? n[1] : "-"}`;
  if (!((a = t.body) != null && a.getReader) && !t.body[Symbol.asyncIterator])
    throw new Error("API multipart response did not return an iterable body", {
      cause: t
    });
  const s = Io(t);
  let i = {}, o;
  return {
    async *[Symbol.asyncIterator]() {
      var c;
      try {
        let u = !0;
        for await (const d of To(s, r)) {
          const h = Ro(d);
          o = ((c = h.find((S) => S.extensions)) == null ? void 0 : c.extensions) ?? o;
          const T = cr(h);
          i = hr([
            i,
            ...h.map(({ data: S }) => S)
          ]), u = h.slice(-1)[0].hasNext, Oo(T, i), yield {
            ...W("data", i),
            ...W("extensions", o),
            hasNext: u
          };
        }
        if (u)
          throw new Error("Response stream terminated unexpectedly");
      } catch (u) {
        const d = ar(u);
        yield {
          ...W("data", i),
          ...W("extensions", o),
          errors: {
            message: ie(ye(u)),
            networkStatusCode: t.status,
            ...W("graphQLErrors", d == null ? void 0 : d.graphQLErrors),
            response: t
          },
          hasNext: !1
        };
      }
    }
  };
}
function Co(t) {
  return async (...e) => {
    if (!or.test(e[0]))
      throw new Error(ie("This operation does not result in a streamable response - use request() instead."));
    try {
      const n = await t(...e), { statusText: r } = n;
      if (!n.ok)
        throw new Error(r, { cause: n });
      const s = n.headers.get("content-type") || "";
      switch (!0) {
        case s.includes(At.json):
          return vo(n);
        case s.includes(At.multipart):
          return No(n, s);
        default:
          throw new Error(`${rr} ${s}`, { cause: n });
      }
    } catch (n) {
      return {
        async *[Symbol.asyncIterator]() {
          const r = ar(n);
          yield {
            errors: {
              message: ie(ye(n)),
              ...W("networkStatusCode", r == null ? void 0 : r.status),
              ...W("response", r)
            },
            hasNext: !1
          };
        }
      };
    }
  };
}
function Do({ client: t, storeDomain: e }) {
  try {
    if (!e || typeof e != "string")
      throw new Error();
    const n = e.trim(), r = n.match(/^https?:/) ? n : `https://${n}`, s = new URL(r);
    return s.protocol = "https", s.origin;
  } catch {
    throw new Error(`${t}: a valid store domain ("${e}") must be provided`);
  }
}
function pr({ client: t, currentSupportedApiVersions: e, apiVersion: n, logger: r }) {
  const s = `${t}: the provided apiVersion ("${n}")`, i = `Currently supported API versions: ${e.join(", ")}`;
  if (!n || typeof n != "string")
    throw new Error(`${s} is invalid. ${i}`);
  const o = n.trim();
  e.includes(o) || (r ? r({
    type: "Unsupported_Api_Version",
    content: {
      apiVersion: n,
      supportedApiVersions: e
    }
  }) : console.warn(`${s} is likely deprecated or not supported. ${i}`));
}
function Ke(t) {
  const e = t * 3 - 2;
  return e === 10 ? e : `0${e}`;
}
function ht(t, e, n) {
  const r = e - n;
  return r <= 0 ? `${t - 1}-${Ke(r + 4)}` : `${t}-${Ke(r)}`;
}
function Po() {
  const t = /* @__PURE__ */ new Date(), e = t.getUTCMonth(), n = t.getUTCFullYear(), r = Math.floor(e / 3 + 1);
  return {
    year: n,
    quarter: r,
    version: `${n}-${Ke(r)}`
  };
}
function Lo() {
  const { year: t, quarter: e, version: n } = Po(), r = e === 4 ? `${t + 1}-01` : `${t}-${Ke(e + 1)}`;
  return [
    ht(t, e, 3),
    ht(t, e, 2),
    ht(t, e, 1),
    n,
    r,
    "unstable"
  ];
}
function Mo(t) {
  return (e) => ({ ...e ?? {}, ...t.headers });
}
function ko({ getHeaders: t, getApiUrl: e }) {
  return (n, r) => {
    const s = [n];
    if (r && Object.keys(r).length > 0) {
      const { variables: i, apiVersion: o, headers: a, retries: c } = r;
      s.push({
        ...i ? { variables: i } : {},
        ...a ? { headers: t(a) } : {},
        ...o ? { url: e(o) } : {},
        ...c ? { retries: c } : {}
      });
    }
    return s;
  };
}
const hn = "application/json", Uo = "1.0.0", xo = "X-Shopify-Access-Token", me = "Admin API Client";
function Fo(t) {
  if (!t)
    throw new Error(`${me}: an access token must be provided`);
}
function $o() {
  if (typeof window < "u")
    throw new Error(`${me}: this client should not be used in the browser`);
}
function Bo({ storeDomain: t, apiVersion: e, accessToken: n, userAgentPrefix: r, retries: s = 0, customFetchApi: i, logger: o }) {
  const a = Lo(), c = Do({
    client: me,
    storeDomain: t
  }), u = {
    client: me,
    currentSupportedApiVersions: a,
    logger: o
  };
  $o(), pr({
    client: me,
    currentSupportedApiVersions: a,
    apiVersion: e,
    logger: o
  }), Fo(n);
  const d = Ho(c, e, u), h = {
    storeDomain: c,
    apiVersion: e,
    accessToken: n,
    headers: {
      "Content-Type": hn,
      Accept: hn,
      [xo]: n,
      "User-Agent": `${r ? `${r} | ` : ""}${me} v${Uo}`
    },
    apiUrl: d(),
    userAgentPrefix: r
  }, T = bo({
    headers: h.headers,
    url: h.apiUrl,
    retries: s,
    customFetchApi: i,
    logger: o
  }), S = Mo(h), f = Vo(h, d), w = ko({
    getHeaders: S,
    getApiUrl: f
  });
  return Object.freeze({
    config: h,
    getHeaders: S,
    getApiUrl: f,
    fetch: (..._) => T.fetch(...w(..._)),
    request: (..._) => T.request(...w(..._))
  });
}
function Ho(t, e, n) {
  return (r) => {
    r && pr({
      ...n,
      apiVersion: r
    });
    const s = (r ?? e).trim();
    return `${t}/admin/api/${s}/graphql.json`;
  };
}
function Vo(t, e) {
  return (n) => n ? e(n) : t.apiUrl;
}
var fn;
(function(t) {
  t.Get = "GET", t.Post = "POST", t.Put = "PUT", t.Delete = "DELETE";
})(fn || (fn = {}));
const jo = "2024-10", qo = ["read_products", "write_orders"], mr = ({
  storeId: t,
  accessToken: e
}) => Bo({
  storeDomain: t,
  accessToken: e,
  apiVersion: jo,
  retries: 3
}), _r = async (t, e) => {
  var s;
  const n = (s = e == null ? void 0 : e.shopify) == null ? void 0 : s.storeId;
  if (!n)
    return null;
  const r = await t.collection("shopify_sessions").doc(`offline_${n}`).get();
  return r.exists ? Z(r) : null;
}, gr = async (t, e) => {
  var a;
  const n = await t.collection("brandStatus").doc(e).get();
  if (!n.exists)
    return null;
  const r = Z(n);
  if (!(r != null && r.shopify))
    return null;
  const s = (a = r == null ? void 0 : r.shopify) == null ? void 0 : a.storeId;
  if (!s)
    return null;
  const i = await _r(t, r), o = i == null ? void 0 : i.accessToken;
  return o ? { accessToken: o, storeId: s, shopifyBrandStatus: r.shopify } : null;
}, Hc = async (t, e) => {
  const n = await t.collection("brandStatus").doc(e).get();
  if (!n.exists)
    return !1;
  const r = Z(n), s = await _r(t, r);
  if (!s)
    return !0;
  const i = s == null ? void 0 : s.scope;
  if (!i)
    return !0;
  const o = i.split(",");
  return qo.some((a) => !o.includes(a));
}, Vc = async (t, e) => {
  const { variant: n, product: r, brandId: s } = e, i = await t.collection("brands").doc(s).get(), o = Z(i), a = {
    shopifyVariantId: n.id,
    title: `${r.title} ${!r.hasOnlyDefaultVariant && n.title ? ` - ${n.title}` : ""}`,
    brand: {
      id: o.id,
      name: o.name,
      logoImage: o.logoImage
    },
    createdAtTimeMs: Date.now(),
    updatedAtTimeMs: Date.now(),
    sku: n.sku || "",
    descriptionHTML: r.descriptionHtml,
    retailPrice: n.price
  }, c = r.images;
  return c != null && c.length && (a.productImage = c[0], a.images = c), await t.collection("products").add(a), a;
}, jc = async (t, e) => {
  const { brandId: n } = e;
  await t.collection("brandStatus").doc(n).set(
    {
      shopify: null,
      updatedAtTimeMs: Date.now()
    },
    { merge: !0 }
  );
}, qc = async (t, e) => {
  var o;
  const { brandId: n } = e, r = await t.collection("brandStatus").doc(n).get();
  if (!r.exists)
    return !1;
  const s = Z(r), i = (o = s == null ? void 0 : s.shopify) == null ? void 0 : o.storeId;
  return i ? (await t.collection("shopify_sessions").doc(`offline_${i}`).delete(), !0) : !1;
}, pn = "tn_order_id", mn = "is_tn_order", Gc = async ({
  db: t,
  orderId: e
}) => {
  var v, A, D, M;
  if (!e)
    throw new $("Order ID is required", { statusCode: 400 });
  const n = await t.collection("orders").doc(e).get();
  if (!n.exists)
    throw new $("Order not found", {
      statusCode: 404,
      data: { orderId: e }
    });
  const r = Z(n), s = (v = r == null ? void 0 : r.product) == null ? void 0 : v.brandId;
  if (!s)
    throw new $("Order does not have a brand ID", {
      statusCode: 404,
      data: { orderId: e }
    });
  const i = await gr(t, s);
  if (!(i != null && i.storeId) || !(i != null && i.accessToken))
    throw new $("Shopify access not found", {
      statusCode: 401,
      data: { orderId: e, brandId: s }
    });
  if (!((A = i.shopifyBrandStatus) != null && A.ordersSyncEnabled))
    throw new $("Orders sync is disabled", {
      statusCode: 400,
      data: { orderId: e, brandId: s }
    });
  const o = mr({
    storeId: i.storeId,
    accessToken: i.accessToken
  }), a = r == null ? void 0 : r.deliveryAddress;
  if (!a)
    throw new $("Order does not have a delivery address", {
      statusCode: 400,
      data: { orderId: e, brandId: s }
    });
  const c = (D = r == null ? void 0 : r.product) == null ? void 0 : D.id;
  if (!c)
    throw new $("Order does not have a product", {
      statusCode: 400,
      data: { orderId: e, brandId: s }
    });
  const u = await t.collection("products").doc(c).get();
  if (!u.exists)
    throw new $("Product not found", {
      statusCode: 404,
      data: { orderId: e, brandId: s, tnProductId: c }
    });
  const d = Z(u), h = {
    quantity: 1,
    requiresShipping: !0
  }, T = d == null ? void 0 : d.shopifyVariantId;
  let S = !T;
  if (T) {
    const { data: k, errors: B } = await o.request(
      `#graphql
      query CheckProductVariant($id: ID!) {
        productVariant(id: $id) {
          id
        }
      }`,
      {
        variables: {
          id: T
        }
      }
    );
    !B && (k != null && k.productVariant) ? h.variantId = T : S = !0;
  }
  S && (h.title = d == null ? void 0 : d.title, h.sku = d == null ? void 0 : d.sku, h.priceSet = {
    shopMoney: {
      amount: "0",
      currencyCode: "USD"
    }
  });
  const f = `#graphql
  mutation OrderCreate($order: OrderCreateOrderInput!, $options: OrderCreateOptionsInput) {
    orderCreate(order: $order, options: $options) {
      userErrors {
        field
        message
      }
      order {
        id
        name
        totalTaxSet {
          shopMoney {
            amount
            currencyCode
          }
        }
        lineItems(first: 5) {
          nodes {
            variant {
              id
            }
            id
            title
            quantity
            taxLines {
              title
              rate
              priceSet {
                shopMoney {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
    }
  }`, { data: w, errors: g } = await o.request(f, {
    variables: {
      order: {
        lineItems: [h],
        tags: ["taste-network"],
        note: "Order generated by Taste Network",
        customAttributes: [
          {
            key: pn,
            value: e
          },
          {
            key: mn,
            value: "TRUE"
          }
        ],
        metafields: [
          {
            key: pn,
            value: e,
            type: "single_line_text_field",
            namespace: "taste-network"
          },
          {
            key: mn,
            value: "TRUE",
            type: "single_line_text_field",
            namespace: "taste-network"
          }
        ],
        shippingAddress: {
          address1: a.addressLine1,
          address2: a.addressLine2,
          city: a.city,
          countryCode: a.country,
          provinceCode: a.state,
          zip: a.postalCode,
          firstName: a.firstName,
          lastName: a.lastName
        }
      }
    }
  });
  if (g)
    throw new $(g.message || "Failed to create order", {
      statusCode: 500,
      data: { orderId: e, brandId: s, tnProductId: c, errors: g, response: w }
    });
  const _ = (M = w == null ? void 0 : w.orderCreate) == null ? void 0 : M.order, b = _ == null ? void 0 : _.id;
  if (!b)
    throw new $("Shopify order not created", {
      statusCode: 500,
      data: { orderId: e, brandId: s, tnProductId: c, errors: g, response: w }
    });
  const p = {
    shopifyOrder: {
      id: b,
      name: _ == null ? void 0 : _.name
    }
  };
  return await t.collection("orders").doc(e).update(p), { shopifyOrderId: b };
}, Wc = async ({
  shopifyOrderId: t,
  tnOrderId: e,
  db: n
}) => {
  var S, f;
  const r = await n.collection("orders").doc(e).get();
  if (!r.exists)
    throw new $("Order not found", {
      statusCode: 404,
      data: { tnOrderId: e, shopifyOrderId: t }
    });
  const s = Z(r), i = (S = s == null ? void 0 : s.product) == null ? void 0 : S.brandId;
  if (!i)
    throw new $("Order does not have a brand ID", {
      statusCode: 404,
      data: { tnOrderId: e, shopifyOrderId: t }
    });
  const o = await gr(n, i);
  if (!(o != null && o.storeId) || !(o != null && o.accessToken))
    throw new $("Shopify access not found", {
      statusCode: 401,
      data: { tnOrderId: e, brandId: i, shopifyOrderId: t }
    });
  if (!((f = o.shopifyBrandStatus) != null && f.ordersSyncEnabled))
    throw new $("Orders sync is disabled", {
      statusCode: 400,
      data: { tnOrderId: e, brandId: i, shopifyOrderId: t }
    });
  const a = mr({
    storeId: o.storeId,
    accessToken: o.accessToken
  }), { data: c, errors: u } = await a.request(
    `#graphql
      query SyncOrderGet($id: ID!) {
        order(id: $id) {
          name,
          displayFulfillmentStatus
        }
      }`,
    {
      variables: {
        id: t
      }
    }
  );
  if (u)
    throw new $(u.message || "Failed to sync order", {
      statusCode: 500,
      data: { tnOrderId: e, brandId: i, errors: u, response: c }
    });
  if (!(c != null && c.order))
    throw new $("Failed to sync order", {
      statusCode: 500,
      data: { tnOrderId: e, brandId: i, errors: u, response: c }
    });
  const T = {
    status: c.order.displayFulfillmentStatus === "FULFILLED" ? gt.Fulfilled : (
      // any status other than fulfilled is unfulfilled: https://shopify.dev/docs/api/admin-graphql/2024-10/enums/OrderDisplayFulfillmentStatus
      gt.Unfulfilled
    ),
    shopifyOrder: {
      id: t,
      name: c.order.name
    }
  };
  await n.collection("orders").doc(e).update(T);
};
function yr(t, e) {
  return function() {
    return t.apply(e, arguments);
  };
}
const { toString: Go } = Object.prototype, { getPrototypeOf: kt } = Object, et = /* @__PURE__ */ ((t) => (e) => {
  const n = Go.call(e);
  return t[n] || (t[n] = n.slice(8, -1).toLowerCase());
})(/* @__PURE__ */ Object.create(null)), X = (t) => (t = t.toLowerCase(), (e) => et(e) === t), tt = (t) => (e) => typeof e === t, { isArray: Ee } = Array, Pe = tt("undefined");
function Wo(t) {
  return t !== null && !Pe(t) && t.constructor !== null && !Pe(t.constructor) && J(t.constructor.isBuffer) && t.constructor.isBuffer(t);
}
const Er = X("ArrayBuffer");
function Ko(t) {
  let e;
  return typeof ArrayBuffer < "u" && ArrayBuffer.isView ? e = ArrayBuffer.isView(t) : e = t && t.buffer && Er(t.buffer), e;
}
const zo = tt("string"), J = tt("function"), br = tt("number"), nt = (t) => t !== null && typeof t == "object", Jo = (t) => t === !0 || t === !1, Ve = (t) => {
  if (et(t) !== "object")
    return !1;
  const e = kt(t);
  return (e === null || e === Object.prototype || Object.getPrototypeOf(e) === null) && !(Symbol.toStringTag in t) && !(Symbol.iterator in t);
}, Yo = X("Date"), Xo = X("File"), Qo = X("Blob"), Zo = X("FileList"), ea = (t) => nt(t) && J(t.pipe), ta = (t) => {
  let e;
  return t && (typeof FormData == "function" && t instanceof FormData || J(t.append) && ((e = et(t)) === "formdata" || // detect form-data instance
  e === "object" && J(t.toString) && t.toString() === "[object FormData]"));
}, na = X("URLSearchParams"), [ra, sa, ia, oa] = ["ReadableStream", "Request", "Response", "Headers"].map(X), aa = (t) => t.trim ? t.trim() : t.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
function ke(t, e, { allOwnKeys: n = !1 } = {}) {
  if (t === null || typeof t > "u")
    return;
  let r, s;
  if (typeof t != "object" && (t = [t]), Ee(t))
    for (r = 0, s = t.length; r < s; r++)
      e.call(null, t[r], r, t);
  else {
    const i = n ? Object.getOwnPropertyNames(t) : Object.keys(t), o = i.length;
    let a;
    for (r = 0; r < o; r++)
      a = i[r], e.call(null, t[a], a, t);
  }
}
function wr(t, e) {
  e = e.toLowerCase();
  const n = Object.keys(t);
  let r = n.length, s;
  for (; r-- > 0; )
    if (s = n[r], e === s.toLowerCase())
      return s;
  return null;
}
const ue = typeof globalThis < "u" ? globalThis : typeof self < "u" ? self : typeof window < "u" ? window : global, Sr = (t) => !Pe(t) && t !== ue;
function It() {
  const { caseless: t } = Sr(this) && this || {}, e = {}, n = (r, s) => {
    const i = t && wr(e, s) || s;
    Ve(e[i]) && Ve(r) ? e[i] = It(e[i], r) : Ve(r) ? e[i] = It({}, r) : Ee(r) ? e[i] = r.slice() : e[i] = r;
  };
  for (let r = 0, s = arguments.length; r < s; r++)
    arguments[r] && ke(arguments[r], n);
  return e;
}
const ca = (t, e, n, { allOwnKeys: r } = {}) => (ke(e, (s, i) => {
  n && J(s) ? t[i] = yr(s, n) : t[i] = s;
}, { allOwnKeys: r }), t), ua = (t) => (t.charCodeAt(0) === 65279 && (t = t.slice(1)), t), la = (t, e, n, r) => {
  t.prototype = Object.create(e.prototype, r), t.prototype.constructor = t, Object.defineProperty(t, "super", {
    value: e.prototype
  }), n && Object.assign(t.prototype, n);
}, da = (t, e, n, r) => {
  let s, i, o;
  const a = {};
  if (e = e || {}, t == null)
    return e;
  do {
    for (s = Object.getOwnPropertyNames(t), i = s.length; i-- > 0; )
      o = s[i], (!r || r(o, t, e)) && !a[o] && (e[o] = t[o], a[o] = !0);
    t = n !== !1 && kt(t);
  } while (t && (!n || n(t, e)) && t !== Object.prototype);
  return e;
}, ha = (t, e, n) => {
  t = String(t), (n === void 0 || n > t.length) && (n = t.length), n -= e.length;
  const r = t.indexOf(e, n);
  return r !== -1 && r === n;
}, fa = (t) => {
  if (!t)
    return null;
  if (Ee(t))
    return t;
  let e = t.length;
  if (!br(e))
    return null;
  const n = new Array(e);
  for (; e-- > 0; )
    n[e] = t[e];
  return n;
}, pa = /* @__PURE__ */ ((t) => (e) => t && e instanceof t)(typeof Uint8Array < "u" && kt(Uint8Array)), ma = (t, e) => {
  const r = (t && t[Symbol.iterator]).call(t);
  let s;
  for (; (s = r.next()) && !s.done; ) {
    const i = s.value;
    e.call(t, i[0], i[1]);
  }
}, _a = (t, e) => {
  let n;
  const r = [];
  for (; (n = t.exec(e)) !== null; )
    r.push(n);
  return r;
}, ga = X("HTMLFormElement"), ya = (t) => t.toLowerCase().replace(
  /[-_\s]([a-z\d])(\w*)/g,
  function(n, r, s) {
    return r.toUpperCase() + s;
  }
), _n = (({ hasOwnProperty: t }) => (e, n) => t.call(e, n))(Object.prototype), Ea = X("RegExp"), Ar = (t, e) => {
  const n = Object.getOwnPropertyDescriptors(t), r = {};
  ke(n, (s, i) => {
    let o;
    (o = e(s, i, t)) !== !1 && (r[i] = o || s);
  }), Object.defineProperties(t, r);
}, ba = (t) => {
  Ar(t, (e, n) => {
    if (J(t) && ["arguments", "caller", "callee"].indexOf(n) !== -1)
      return !1;
    const r = t[n];
    if (J(r)) {
      if (e.enumerable = !1, "writable" in e) {
        e.writable = !1;
        return;
      }
      e.set || (e.set = () => {
        throw Error("Can not rewrite read-only method '" + n + "'");
      });
    }
  });
}, wa = (t, e) => {
  const n = {}, r = (s) => {
    s.forEach((i) => {
      n[i] = !0;
    });
  };
  return Ee(t) ? r(t) : r(String(t).split(e)), n;
}, Sa = () => {
}, Aa = (t, e) => t != null && Number.isFinite(t = +t) ? t : e, ft = "abcdefghijklmnopqrstuvwxyz", gn = "0123456789", Ir = {
  DIGIT: gn,
  ALPHA: ft,
  ALPHA_DIGIT: ft + ft.toUpperCase() + gn
}, Ia = (t = 16, e = Ir.ALPHA_DIGIT) => {
  let n = "";
  const { length: r } = e;
  for (; t--; )
    n += e[Math.random() * r | 0];
  return n;
};
function Ta(t) {
  return !!(t && J(t.append) && t[Symbol.toStringTag] === "FormData" && t[Symbol.iterator]);
}
const va = (t) => {
  const e = new Array(10), n = (r, s) => {
    if (nt(r)) {
      if (e.indexOf(r) >= 0)
        return;
      if (!("toJSON" in r)) {
        e[s] = r;
        const i = Ee(r) ? [] : {};
        return ke(r, (o, a) => {
          const c = n(o, s + 1);
          !Pe(c) && (i[a] = c);
        }), e[s] = void 0, i;
      }
    }
    return r;
  };
  return n(t, 0);
}, Ra = X("AsyncFunction"), Oa = (t) => t && (nt(t) || J(t)) && J(t.then) && J(t.catch), Tr = ((t, e) => t ? setImmediate : e ? ((n, r) => (ue.addEventListener("message", ({ source: s, data: i }) => {
  s === ue && i === n && r.length && r.shift()();
}, !1), (s) => {
  r.push(s), ue.postMessage(n, "*");
}))(`axios@${Math.random()}`, []) : (n) => setTimeout(n))(
  typeof setImmediate == "function",
  J(ue.postMessage)
), Na = typeof queueMicrotask < "u" ? queueMicrotask.bind(ue) : typeof process < "u" && process.nextTick || Tr, l = {
  isArray: Ee,
  isArrayBuffer: Er,
  isBuffer: Wo,
  isFormData: ta,
  isArrayBufferView: Ko,
  isString: zo,
  isNumber: br,
  isBoolean: Jo,
  isObject: nt,
  isPlainObject: Ve,
  isReadableStream: ra,
  isRequest: sa,
  isResponse: ia,
  isHeaders: oa,
  isUndefined: Pe,
  isDate: Yo,
  isFile: Xo,
  isBlob: Qo,
  isRegExp: Ea,
  isFunction: J,
  isStream: ea,
  isURLSearchParams: na,
  isTypedArray: pa,
  isFileList: Zo,
  forEach: ke,
  merge: It,
  extend: ca,
  trim: aa,
  stripBOM: ua,
  inherits: la,
  toFlatObject: da,
  kindOf: et,
  kindOfTest: X,
  endsWith: ha,
  toArray: fa,
  forEachEntry: ma,
  matchAll: _a,
  isHTMLForm: ga,
  hasOwnProperty: _n,
  hasOwnProp: _n,
  // an alias to avoid ESLint no-prototype-builtins detection
  reduceDescriptors: Ar,
  freezeMethods: ba,
  toObjectSet: wa,
  toCamelCase: ya,
  noop: Sa,
  toFiniteNumber: Aa,
  findKey: wr,
  global: ue,
  isContextDefined: Sr,
  ALPHABET: Ir,
  generateString: Ia,
  isSpecCompliantForm: Ta,
  toJSONObject: va,
  isAsyncFn: Ra,
  isThenable: Oa,
  setImmediate: Tr,
  asap: Na
};
function O(t, e, n, r, s) {
  Error.call(this), Error.captureStackTrace ? Error.captureStackTrace(this, this.constructor) : this.stack = new Error().stack, this.message = t, this.name = "AxiosError", e && (this.code = e), n && (this.config = n), r && (this.request = r), s && (this.response = s, this.status = s.status ? s.status : null);
}
l.inherits(O, Error, {
  toJSON: function() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: l.toJSONObject(this.config),
      code: this.code,
      status: this.status
    };
  }
});
const vr = O.prototype, Rr = {};
[
  "ERR_BAD_OPTION_VALUE",
  "ERR_BAD_OPTION",
  "ECONNABORTED",
  "ETIMEDOUT",
  "ERR_NETWORK",
  "ERR_FR_TOO_MANY_REDIRECTS",
  "ERR_DEPRECATED",
  "ERR_BAD_RESPONSE",
  "ERR_BAD_REQUEST",
  "ERR_CANCELED",
  "ERR_NOT_SUPPORT",
  "ERR_INVALID_URL"
  // eslint-disable-next-line func-names
].forEach((t) => {
  Rr[t] = { value: t };
});
Object.defineProperties(O, Rr);
Object.defineProperty(vr, "isAxiosError", { value: !0 });
O.from = (t, e, n, r, s, i) => {
  const o = Object.create(vr);
  return l.toFlatObject(t, o, function(c) {
    return c !== Error.prototype;
  }, (a) => a !== "isAxiosError"), O.call(o, t.message, e, n, r, s), o.cause = t, o.name = t.name, i && Object.assign(o, i), o;
};
const Ca = null;
function Tt(t) {
  return l.isPlainObject(t) || l.isArray(t);
}
function Or(t) {
  return l.endsWith(t, "[]") ? t.slice(0, -2) : t;
}
function yn(t, e, n) {
  return t ? t.concat(e).map(function(s, i) {
    return s = Or(s), !n && i ? "[" + s + "]" : s;
  }).join(n ? "." : "") : e;
}
function Da(t) {
  return l.isArray(t) && !t.some(Tt);
}
const Pa = l.toFlatObject(l, {}, null, function(e) {
  return /^is[A-Z]/.test(e);
});
function rt(t, e, n) {
  if (!l.isObject(t))
    throw new TypeError("target must be an object");
  e = e || new FormData(), n = l.toFlatObject(n, {
    metaTokens: !0,
    dots: !1,
    indexes: !1
  }, !1, function(w, g) {
    return !l.isUndefined(g[w]);
  });
  const r = n.metaTokens, s = n.visitor || d, i = n.dots, o = n.indexes, c = (n.Blob || typeof Blob < "u" && Blob) && l.isSpecCompliantForm(e);
  if (!l.isFunction(s))
    throw new TypeError("visitor must be a function");
  function u(f) {
    if (f === null)
      return "";
    if (l.isDate(f))
      return f.toISOString();
    if (!c && l.isBlob(f))
      throw new O("Blob is not supported. Use a Buffer instead.");
    return l.isArrayBuffer(f) || l.isTypedArray(f) ? c && typeof Blob == "function" ? new Blob([f]) : Buffer.from(f) : f;
  }
  function d(f, w, g) {
    let _ = f;
    if (f && !g && typeof f == "object") {
      if (l.endsWith(w, "{}"))
        w = r ? w : w.slice(0, -2), f = JSON.stringify(f);
      else if (l.isArray(f) && Da(f) || (l.isFileList(f) || l.endsWith(w, "[]")) && (_ = l.toArray(f)))
        return w = Or(w), _.forEach(function(p, v) {
          !(l.isUndefined(p) || p === null) && e.append(
            // eslint-disable-next-line no-nested-ternary
            o === !0 ? yn([w], v, i) : o === null ? w : w + "[]",
            u(p)
          );
        }), !1;
    }
    return Tt(f) ? !0 : (e.append(yn(g, w, i), u(f)), !1);
  }
  const h = [], T = Object.assign(Pa, {
    defaultVisitor: d,
    convertValue: u,
    isVisitable: Tt
  });
  function S(f, w) {
    if (!l.isUndefined(f)) {
      if (h.indexOf(f) !== -1)
        throw Error("Circular reference detected in " + w.join("."));
      h.push(f), l.forEach(f, function(_, b) {
        (!(l.isUndefined(_) || _ === null) && s.call(
          e,
          _,
          l.isString(b) ? b.trim() : b,
          w,
          T
        )) === !0 && S(_, w ? w.concat(b) : [b]);
      }), h.pop();
    }
  }
  if (!l.isObject(t))
    throw new TypeError("data must be an object");
  return S(t), e;
}
function En(t) {
  const e = {
    "!": "%21",
    "'": "%27",
    "(": "%28",
    ")": "%29",
    "~": "%7E",
    "%20": "+",
    "%00": "\0"
  };
  return encodeURIComponent(t).replace(/[!'()~]|%20|%00/g, function(r) {
    return e[r];
  });
}
function Ut(t, e) {
  this._pairs = [], t && rt(t, this, e);
}
const Nr = Ut.prototype;
Nr.append = function(e, n) {
  this._pairs.push([e, n]);
};
Nr.toString = function(e) {
  const n = e ? function(r) {
    return e.call(this, r, En);
  } : En;
  return this._pairs.map(function(s) {
    return n(s[0]) + "=" + n(s[1]);
  }, "").join("&");
};
function La(t) {
  return encodeURIComponent(t).replace(/%3A/gi, ":").replace(/%24/g, "$").replace(/%2C/gi, ",").replace(/%20/g, "+").replace(/%5B/gi, "[").replace(/%5D/gi, "]");
}
function Cr(t, e, n) {
  if (!e)
    return t;
  const r = n && n.encode || La, s = n && n.serialize;
  let i;
  if (s ? i = s(e, n) : i = l.isURLSearchParams(e) ? e.toString() : new Ut(e, n).toString(r), i) {
    const o = t.indexOf("#");
    o !== -1 && (t = t.slice(0, o)), t += (t.indexOf("?") === -1 ? "?" : "&") + i;
  }
  return t;
}
class bn {
  constructor() {
    this.handlers = [];
  }
  /**
   * Add a new interceptor to the stack
   *
   * @param {Function} fulfilled The function to handle `then` for a `Promise`
   * @param {Function} rejected The function to handle `reject` for a `Promise`
   *
   * @return {Number} An ID used to remove interceptor later
   */
  use(e, n, r) {
    return this.handlers.push({
      fulfilled: e,
      rejected: n,
      synchronous: r ? r.synchronous : !1,
      runWhen: r ? r.runWhen : null
    }), this.handlers.length - 1;
  }
  /**
   * Remove an interceptor from the stack
   *
   * @param {Number} id The ID that was returned by `use`
   *
   * @returns {Boolean} `true` if the interceptor was removed, `false` otherwise
   */
  eject(e) {
    this.handlers[e] && (this.handlers[e] = null);
  }
  /**
   * Clear all interceptors from the stack
   *
   * @returns {void}
   */
  clear() {
    this.handlers && (this.handlers = []);
  }
  /**
   * Iterate over all the registered interceptors
   *
   * This method is particularly useful for skipping over any
   * interceptors that may have become `null` calling `eject`.
   *
   * @param {Function} fn The function to call for each interceptor
   *
   * @returns {void}
   */
  forEach(e) {
    l.forEach(this.handlers, function(r) {
      r !== null && e(r);
    });
  }
}
const Dr = {
  silentJSONParsing: !0,
  forcedJSONParsing: !0,
  clarifyTimeoutError: !1
}, Ma = typeof URLSearchParams < "u" ? URLSearchParams : Ut, ka = typeof FormData < "u" ? FormData : null, Ua = typeof Blob < "u" ? Blob : null, xa = {
  isBrowser: !0,
  classes: {
    URLSearchParams: Ma,
    FormData: ka,
    Blob: Ua
  },
  protocols: ["http", "https", "file", "blob", "url", "data"]
}, xt = typeof window < "u" && typeof document < "u", vt = typeof navigator == "object" && navigator || void 0, Fa = xt && (!vt || ["ReactNative", "NativeScript", "NS"].indexOf(vt.product) < 0), $a = typeof WorkerGlobalScope < "u" && // eslint-disable-next-line no-undef
self instanceof WorkerGlobalScope && typeof self.importScripts == "function", Ba = xt && window.location.href || "http://localhost", Ha = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  hasBrowserEnv: xt,
  hasStandardBrowserEnv: Fa,
  hasStandardBrowserWebWorkerEnv: $a,
  navigator: vt,
  origin: Ba
}, Symbol.toStringTag, { value: "Module" })), K = {
  ...Ha,
  ...xa
};
function Va(t, e) {
  return rt(t, new K.classes.URLSearchParams(), Object.assign({
    visitor: function(n, r, s, i) {
      return K.isNode && l.isBuffer(n) ? (this.append(r, n.toString("base64")), !1) : i.defaultVisitor.apply(this, arguments);
    }
  }, e));
}
function ja(t) {
  return l.matchAll(/\w+|\[(\w*)]/g, t).map((e) => e[0] === "[]" ? "" : e[1] || e[0]);
}
function qa(t) {
  const e = {}, n = Object.keys(t);
  let r;
  const s = n.length;
  let i;
  for (r = 0; r < s; r++)
    i = n[r], e[i] = t[i];
  return e;
}
function Pr(t) {
  function e(n, r, s, i) {
    let o = n[i++];
    if (o === "__proto__")
      return !0;
    const a = Number.isFinite(+o), c = i >= n.length;
    return o = !o && l.isArray(s) ? s.length : o, c ? (l.hasOwnProp(s, o) ? s[o] = [s[o], r] : s[o] = r, !a) : ((!s[o] || !l.isObject(s[o])) && (s[o] = []), e(n, r, s[o], i) && l.isArray(s[o]) && (s[o] = qa(s[o])), !a);
  }
  if (l.isFormData(t) && l.isFunction(t.entries)) {
    const n = {};
    return l.forEachEntry(t, (r, s) => {
      e(ja(r), s, n, 0);
    }), n;
  }
  return null;
}
function Ga(t, e, n) {
  if (l.isString(t))
    try {
      return (e || JSON.parse)(t), l.trim(t);
    } catch (r) {
      if (r.name !== "SyntaxError")
        throw r;
    }
  return (n || JSON.stringify)(t);
}
const Ue = {
  transitional: Dr,
  adapter: ["xhr", "http", "fetch"],
  transformRequest: [function(e, n) {
    const r = n.getContentType() || "", s = r.indexOf("application/json") > -1, i = l.isObject(e);
    if (i && l.isHTMLForm(e) && (e = new FormData(e)), l.isFormData(e))
      return s ? JSON.stringify(Pr(e)) : e;
    if (l.isArrayBuffer(e) || l.isBuffer(e) || l.isStream(e) || l.isFile(e) || l.isBlob(e) || l.isReadableStream(e))
      return e;
    if (l.isArrayBufferView(e))
      return e.buffer;
    if (l.isURLSearchParams(e))
      return n.setContentType("application/x-www-form-urlencoded;charset=utf-8", !1), e.toString();
    let a;
    if (i) {
      if (r.indexOf("application/x-www-form-urlencoded") > -1)
        return Va(e, this.formSerializer).toString();
      if ((a = l.isFileList(e)) || r.indexOf("multipart/form-data") > -1) {
        const c = this.env && this.env.FormData;
        return rt(
          a ? { "files[]": e } : e,
          c && new c(),
          this.formSerializer
        );
      }
    }
    return i || s ? (n.setContentType("application/json", !1), Ga(e)) : e;
  }],
  transformResponse: [function(e) {
    const n = this.transitional || Ue.transitional, r = n && n.forcedJSONParsing, s = this.responseType === "json";
    if (l.isResponse(e) || l.isReadableStream(e))
      return e;
    if (e && l.isString(e) && (r && !this.responseType || s)) {
      const o = !(n && n.silentJSONParsing) && s;
      try {
        return JSON.parse(e);
      } catch (a) {
        if (o)
          throw a.name === "SyntaxError" ? O.from(a, O.ERR_BAD_RESPONSE, this, null, this.response) : a;
      }
    }
    return e;
  }],
  /**
   * A timeout in milliseconds to abort a request. If set to 0 (default) a
   * timeout is not created.
   */
  timeout: 0,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  maxContentLength: -1,
  maxBodyLength: -1,
  env: {
    FormData: K.classes.FormData,
    Blob: K.classes.Blob
  },
  validateStatus: function(e) {
    return e >= 200 && e < 300;
  },
  headers: {
    common: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": void 0
    }
  }
};
l.forEach(["delete", "get", "head", "post", "put", "patch"], (t) => {
  Ue.headers[t] = {};
});
const Wa = l.toObjectSet([
  "age",
  "authorization",
  "content-length",
  "content-type",
  "etag",
  "expires",
  "from",
  "host",
  "if-modified-since",
  "if-unmodified-since",
  "last-modified",
  "location",
  "max-forwards",
  "proxy-authorization",
  "referer",
  "retry-after",
  "user-agent"
]), Ka = (t) => {
  const e = {};
  let n, r, s;
  return t && t.split(`
`).forEach(function(o) {
    s = o.indexOf(":"), n = o.substring(0, s).trim().toLowerCase(), r = o.substring(s + 1).trim(), !(!n || e[n] && Wa[n]) && (n === "set-cookie" ? e[n] ? e[n].push(r) : e[n] = [r] : e[n] = e[n] ? e[n] + ", " + r : r);
  }), e;
}, wn = Symbol("internals");
function Ie(t) {
  return t && String(t).trim().toLowerCase();
}
function je(t) {
  return t === !1 || t == null ? t : l.isArray(t) ? t.map(je) : String(t);
}
function za(t) {
  const e = /* @__PURE__ */ Object.create(null), n = /([^\s,;=]+)\s*(?:=\s*([^,;]+))?/g;
  let r;
  for (; r = n.exec(t); )
    e[r[1]] = r[2];
  return e;
}
const Ja = (t) => /^[-_a-zA-Z0-9^`|~,!#$%&'*+.]+$/.test(t.trim());
function pt(t, e, n, r, s) {
  if (l.isFunction(r))
    return r.call(this, e, n);
  if (s && (e = n), !!l.isString(e)) {
    if (l.isString(r))
      return e.indexOf(r) !== -1;
    if (l.isRegExp(r))
      return r.test(e);
  }
}
function Ya(t) {
  return t.trim().toLowerCase().replace(/([a-z\d])(\w*)/g, (e, n, r) => n.toUpperCase() + r);
}
function Xa(t, e) {
  const n = l.toCamelCase(" " + e);
  ["get", "set", "has"].forEach((r) => {
    Object.defineProperty(t, r + n, {
      value: function(s, i, o) {
        return this[r].call(this, e, s, i, o);
      },
      configurable: !0
    });
  });
}
class z {
  constructor(e) {
    e && this.set(e);
  }
  set(e, n, r) {
    const s = this;
    function i(a, c, u) {
      const d = Ie(c);
      if (!d)
        throw new Error("header name must be a non-empty string");
      const h = l.findKey(s, d);
      (!h || s[h] === void 0 || u === !0 || u === void 0 && s[h] !== !1) && (s[h || c] = je(a));
    }
    const o = (a, c) => l.forEach(a, (u, d) => i(u, d, c));
    if (l.isPlainObject(e) || e instanceof this.constructor)
      o(e, n);
    else if (l.isString(e) && (e = e.trim()) && !Ja(e))
      o(Ka(e), n);
    else if (l.isHeaders(e))
      for (const [a, c] of e.entries())
        i(c, a, r);
    else
      e != null && i(n, e, r);
    return this;
  }
  get(e, n) {
    if (e = Ie(e), e) {
      const r = l.findKey(this, e);
      if (r) {
        const s = this[r];
        if (!n)
          return s;
        if (n === !0)
          return za(s);
        if (l.isFunction(n))
          return n.call(this, s, r);
        if (l.isRegExp(n))
          return n.exec(s);
        throw new TypeError("parser must be boolean|regexp|function");
      }
    }
  }
  has(e, n) {
    if (e = Ie(e), e) {
      const r = l.findKey(this, e);
      return !!(r && this[r] !== void 0 && (!n || pt(this, this[r], r, n)));
    }
    return !1;
  }
  delete(e, n) {
    const r = this;
    let s = !1;
    function i(o) {
      if (o = Ie(o), o) {
        const a = l.findKey(r, o);
        a && (!n || pt(r, r[a], a, n)) && (delete r[a], s = !0);
      }
    }
    return l.isArray(e) ? e.forEach(i) : i(e), s;
  }
  clear(e) {
    const n = Object.keys(this);
    let r = n.length, s = !1;
    for (; r--; ) {
      const i = n[r];
      (!e || pt(this, this[i], i, e, !0)) && (delete this[i], s = !0);
    }
    return s;
  }
  normalize(e) {
    const n = this, r = {};
    return l.forEach(this, (s, i) => {
      const o = l.findKey(r, i);
      if (o) {
        n[o] = je(s), delete n[i];
        return;
      }
      const a = e ? Ya(i) : String(i).trim();
      a !== i && delete n[i], n[a] = je(s), r[a] = !0;
    }), this;
  }
  concat(...e) {
    return this.constructor.concat(this, ...e);
  }
  toJSON(e) {
    const n = /* @__PURE__ */ Object.create(null);
    return l.forEach(this, (r, s) => {
      r != null && r !== !1 && (n[s] = e && l.isArray(r) ? r.join(", ") : r);
    }), n;
  }
  [Symbol.iterator]() {
    return Object.entries(this.toJSON())[Symbol.iterator]();
  }
  toString() {
    return Object.entries(this.toJSON()).map(([e, n]) => e + ": " + n).join(`
`);
  }
  get [Symbol.toStringTag]() {
    return "AxiosHeaders";
  }
  static from(e) {
    return e instanceof this ? e : new this(e);
  }
  static concat(e, ...n) {
    const r = new this(e);
    return n.forEach((s) => r.set(s)), r;
  }
  static accessor(e) {
    const r = (this[wn] = this[wn] = {
      accessors: {}
    }).accessors, s = this.prototype;
    function i(o) {
      const a = Ie(o);
      r[a] || (Xa(s, o), r[a] = !0);
    }
    return l.isArray(e) ? e.forEach(i) : i(e), this;
  }
}
z.accessor(["Content-Type", "Content-Length", "Accept", "Accept-Encoding", "User-Agent", "Authorization"]);
l.reduceDescriptors(z.prototype, ({ value: t }, e) => {
  let n = e[0].toUpperCase() + e.slice(1);
  return {
    get: () => t,
    set(r) {
      this[n] = r;
    }
  };
});
l.freezeMethods(z);
function mt(t, e) {
  const n = this || Ue, r = e || n, s = z.from(r.headers);
  let i = r.data;
  return l.forEach(t, function(a) {
    i = a.call(n, i, s.normalize(), e ? e.status : void 0);
  }), s.normalize(), i;
}
function Lr(t) {
  return !!(t && t.__CANCEL__);
}
function be(t, e, n) {
  O.call(this, t ?? "canceled", O.ERR_CANCELED, e, n), this.name = "CanceledError";
}
l.inherits(be, O, {
  __CANCEL__: !0
});
function Mr(t, e, n) {
  const r = n.config.validateStatus;
  !n.status || !r || r(n.status) ? t(n) : e(new O(
    "Request failed with status code " + n.status,
    [O.ERR_BAD_REQUEST, O.ERR_BAD_RESPONSE][Math.floor(n.status / 100) - 4],
    n.config,
    n.request,
    n
  ));
}
function Qa(t) {
  const e = /^([-+\w]{1,25})(:?\/\/|:)/.exec(t);
  return e && e[1] || "";
}
function Za(t, e) {
  t = t || 10;
  const n = new Array(t), r = new Array(t);
  let s = 0, i = 0, o;
  return e = e !== void 0 ? e : 1e3, function(c) {
    const u = Date.now(), d = r[i];
    o || (o = u), n[s] = c, r[s] = u;
    let h = i, T = 0;
    for (; h !== s; )
      T += n[h++], h = h % t;
    if (s = (s + 1) % t, s === i && (i = (i + 1) % t), u - o < e)
      return;
    const S = d && u - d;
    return S ? Math.round(T * 1e3 / S) : void 0;
  };
}
function ec(t, e) {
  let n = 0, r = 1e3 / e, s, i;
  const o = (u, d = Date.now()) => {
    n = d, s = null, i && (clearTimeout(i), i = null), t.apply(null, u);
  };
  return [(...u) => {
    const d = Date.now(), h = d - n;
    h >= r ? o(u, d) : (s = u, i || (i = setTimeout(() => {
      i = null, o(s);
    }, r - h)));
  }, () => s && o(s)];
}
const ze = (t, e, n = 3) => {
  let r = 0;
  const s = Za(50, 250);
  return ec((i) => {
    const o = i.loaded, a = i.lengthComputable ? i.total : void 0, c = o - r, u = s(c), d = o <= a;
    r = o;
    const h = {
      loaded: o,
      total: a,
      progress: a ? o / a : void 0,
      bytes: c,
      rate: u || void 0,
      estimated: u && a && d ? (a - o) / u : void 0,
      event: i,
      lengthComputable: a != null,
      [e ? "download" : "upload"]: !0
    };
    t(h);
  }, n);
}, Sn = (t, e) => {
  const n = t != null;
  return [(r) => e[0]({
    lengthComputable: n,
    total: t,
    loaded: r
  }), e[1]];
}, An = (t) => (...e) => l.asap(() => t(...e)), tc = K.hasStandardBrowserEnv ? (
  // Standard browser envs have full support of the APIs needed to test
  // whether the request URL is of the same origin as current location.
  function() {
    const e = K.navigator && /(msie|trident)/i.test(K.navigator.userAgent), n = document.createElement("a");
    let r;
    function s(i) {
      let o = i;
      return e && (n.setAttribute("href", o), o = n.href), n.setAttribute("href", o), {
        href: n.href,
        protocol: n.protocol ? n.protocol.replace(/:$/, "") : "",
        host: n.host,
        search: n.search ? n.search.replace(/^\?/, "") : "",
        hash: n.hash ? n.hash.replace(/^#/, "") : "",
        hostname: n.hostname,
        port: n.port,
        pathname: n.pathname.charAt(0) === "/" ? n.pathname : "/" + n.pathname
      };
    }
    return r = s(window.location.href), function(o) {
      const a = l.isString(o) ? s(o) : o;
      return a.protocol === r.protocol && a.host === r.host;
    };
  }()
) : (
  // Non standard browser envs (web workers, react-native) lack needed support.
  /* @__PURE__ */ function() {
    return function() {
      return !0;
    };
  }()
), nc = K.hasStandardBrowserEnv ? (
  // Standard browser envs support document.cookie
  {
    write(t, e, n, r, s, i) {
      const o = [t + "=" + encodeURIComponent(e)];
      l.isNumber(n) && o.push("expires=" + new Date(n).toGMTString()), l.isString(r) && o.push("path=" + r), l.isString(s) && o.push("domain=" + s), i === !0 && o.push("secure"), document.cookie = o.join("; ");
    },
    read(t) {
      const e = document.cookie.match(new RegExp("(^|;\\s*)(" + t + ")=([^;]*)"));
      return e ? decodeURIComponent(e[3]) : null;
    },
    remove(t) {
      this.write(t, "", Date.now() - 864e5);
    }
  }
) : (
  // Non-standard browser env (web workers, react-native) lack needed support.
  {
    write() {
    },
    read() {
      return null;
    },
    remove() {
    }
  }
);
function rc(t) {
  return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(t);
}
function sc(t, e) {
  return e ? t.replace(/\/?\/$/, "") + "/" + e.replace(/^\/+/, "") : t;
}
function kr(t, e) {
  return t && !rc(e) ? sc(t, e) : e;
}
const In = (t) => t instanceof z ? { ...t } : t;
function he(t, e) {
  e = e || {};
  const n = {};
  function r(u, d, h) {
    return l.isPlainObject(u) && l.isPlainObject(d) ? l.merge.call({ caseless: h }, u, d) : l.isPlainObject(d) ? l.merge({}, d) : l.isArray(d) ? d.slice() : d;
  }
  function s(u, d, h) {
    if (l.isUndefined(d)) {
      if (!l.isUndefined(u))
        return r(void 0, u, h);
    } else
      return r(u, d, h);
  }
  function i(u, d) {
    if (!l.isUndefined(d))
      return r(void 0, d);
  }
  function o(u, d) {
    if (l.isUndefined(d)) {
      if (!l.isUndefined(u))
        return r(void 0, u);
    } else
      return r(void 0, d);
  }
  function a(u, d, h) {
    if (h in e)
      return r(u, d);
    if (h in t)
      return r(void 0, u);
  }
  const c = {
    url: i,
    method: i,
    data: i,
    baseURL: o,
    transformRequest: o,
    transformResponse: o,
    paramsSerializer: o,
    timeout: o,
    timeoutMessage: o,
    withCredentials: o,
    withXSRFToken: o,
    adapter: o,
    responseType: o,
    xsrfCookieName: o,
    xsrfHeaderName: o,
    onUploadProgress: o,
    onDownloadProgress: o,
    decompress: o,
    maxContentLength: o,
    maxBodyLength: o,
    beforeRedirect: o,
    transport: o,
    httpAgent: o,
    httpsAgent: o,
    cancelToken: o,
    socketPath: o,
    responseEncoding: o,
    validateStatus: a,
    headers: (u, d) => s(In(u), In(d), !0)
  };
  return l.forEach(Object.keys(Object.assign({}, t, e)), function(d) {
    const h = c[d] || s, T = h(t[d], e[d], d);
    l.isUndefined(T) && h !== a || (n[d] = T);
  }), n;
}
const Ur = (t) => {
  const e = he({}, t);
  let { data: n, withXSRFToken: r, xsrfHeaderName: s, xsrfCookieName: i, headers: o, auth: a } = e;
  e.headers = o = z.from(o), e.url = Cr(kr(e.baseURL, e.url), t.params, t.paramsSerializer), a && o.set(
    "Authorization",
    "Basic " + btoa((a.username || "") + ":" + (a.password ? unescape(encodeURIComponent(a.password)) : ""))
  );
  let c;
  if (l.isFormData(n)) {
    if (K.hasStandardBrowserEnv || K.hasStandardBrowserWebWorkerEnv)
      o.setContentType(void 0);
    else if ((c = o.getContentType()) !== !1) {
      const [u, ...d] = c ? c.split(";").map((h) => h.trim()).filter(Boolean) : [];
      o.setContentType([u || "multipart/form-data", ...d].join("; "));
    }
  }
  if (K.hasStandardBrowserEnv && (r && l.isFunction(r) && (r = r(e)), r || r !== !1 && tc(e.url))) {
    const u = s && i && nc.read(i);
    u && o.set(s, u);
  }
  return e;
}, ic = typeof XMLHttpRequest < "u", oc = ic && function(t) {
  return new Promise(function(n, r) {
    const s = Ur(t);
    let i = s.data;
    const o = z.from(s.headers).normalize();
    let { responseType: a, onUploadProgress: c, onDownloadProgress: u } = s, d, h, T, S, f;
    function w() {
      S && S(), f && f(), s.cancelToken && s.cancelToken.unsubscribe(d), s.signal && s.signal.removeEventListener("abort", d);
    }
    let g = new XMLHttpRequest();
    g.open(s.method.toUpperCase(), s.url, !0), g.timeout = s.timeout;
    function _() {
      if (!g)
        return;
      const p = z.from(
        "getAllResponseHeaders" in g && g.getAllResponseHeaders()
      ), A = {
        data: !a || a === "text" || a === "json" ? g.responseText : g.response,
        status: g.status,
        statusText: g.statusText,
        headers: p,
        config: t,
        request: g
      };
      Mr(function(M) {
        n(M), w();
      }, function(M) {
        r(M), w();
      }, A), g = null;
    }
    "onloadend" in g ? g.onloadend = _ : g.onreadystatechange = function() {
      !g || g.readyState !== 4 || g.status === 0 && !(g.responseURL && g.responseURL.indexOf("file:") === 0) || setTimeout(_);
    }, g.onabort = function() {
      g && (r(new O("Request aborted", O.ECONNABORTED, t, g)), g = null);
    }, g.onerror = function() {
      r(new O("Network Error", O.ERR_NETWORK, t, g)), g = null;
    }, g.ontimeout = function() {
      let v = s.timeout ? "timeout of " + s.timeout + "ms exceeded" : "timeout exceeded";
      const A = s.transitional || Dr;
      s.timeoutErrorMessage && (v = s.timeoutErrorMessage), r(new O(
        v,
        A.clarifyTimeoutError ? O.ETIMEDOUT : O.ECONNABORTED,
        t,
        g
      )), g = null;
    }, i === void 0 && o.setContentType(null), "setRequestHeader" in g && l.forEach(o.toJSON(), function(v, A) {
      g.setRequestHeader(A, v);
    }), l.isUndefined(s.withCredentials) || (g.withCredentials = !!s.withCredentials), a && a !== "json" && (g.responseType = s.responseType), u && ([T, f] = ze(u, !0), g.addEventListener("progress", T)), c && g.upload && ([h, S] = ze(c), g.upload.addEventListener("progress", h), g.upload.addEventListener("loadend", S)), (s.cancelToken || s.signal) && (d = (p) => {
      g && (r(!p || p.type ? new be(null, t, g) : p), g.abort(), g = null);
    }, s.cancelToken && s.cancelToken.subscribe(d), s.signal && (s.signal.aborted ? d() : s.signal.addEventListener("abort", d)));
    const b = Qa(s.url);
    if (b && K.protocols.indexOf(b) === -1) {
      r(new O("Unsupported protocol " + b + ":", O.ERR_BAD_REQUEST, t));
      return;
    }
    g.send(i || null);
  });
}, ac = (t, e) => {
  const { length: n } = t = t ? t.filter(Boolean) : [];
  if (e || n) {
    let r = new AbortController(), s;
    const i = function(u) {
      if (!s) {
        s = !0, a();
        const d = u instanceof Error ? u : this.reason;
        r.abort(d instanceof O ? d : new be(d instanceof Error ? d.message : d));
      }
    };
    let o = e && setTimeout(() => {
      o = null, i(new O(`timeout ${e} of ms exceeded`, O.ETIMEDOUT));
    }, e);
    const a = () => {
      t && (o && clearTimeout(o), o = null, t.forEach((u) => {
        u.unsubscribe ? u.unsubscribe(i) : u.removeEventListener("abort", i);
      }), t = null);
    };
    t.forEach((u) => u.addEventListener("abort", i));
    const { signal: c } = r;
    return c.unsubscribe = () => l.asap(a), c;
  }
}, cc = function* (t, e) {
  let n = t.byteLength;
  if (!e || n < e) {
    yield t;
    return;
  }
  let r = 0, s;
  for (; r < n; )
    s = r + e, yield t.slice(r, s), r = s;
}, uc = async function* (t, e) {
  for await (const n of lc(t))
    yield* cc(n, e);
}, lc = async function* (t) {
  if (t[Symbol.asyncIterator]) {
    yield* t;
    return;
  }
  const e = t.getReader();
  try {
    for (; ; ) {
      const { done: n, value: r } = await e.read();
      if (n)
        break;
      yield r;
    }
  } finally {
    await e.cancel();
  }
}, Tn = (t, e, n, r) => {
  const s = uc(t, e);
  let i = 0, o, a = (c) => {
    o || (o = !0, r && r(c));
  };
  return new ReadableStream({
    async pull(c) {
      try {
        const { done: u, value: d } = await s.next();
        if (u) {
          a(), c.close();
          return;
        }
        let h = d.byteLength;
        if (n) {
          let T = i += h;
          n(T);
        }
        c.enqueue(new Uint8Array(d));
      } catch (u) {
        throw a(u), u;
      }
    },
    cancel(c) {
      return a(c), s.return();
    }
  }, {
    highWaterMark: 2
  });
}, st = typeof fetch == "function" && typeof Request == "function" && typeof Response == "function", xr = st && typeof ReadableStream == "function", dc = st && (typeof TextEncoder == "function" ? /* @__PURE__ */ ((t) => (e) => t.encode(e))(new TextEncoder()) : async (t) => new Uint8Array(await new Response(t).arrayBuffer())), Fr = (t, ...e) => {
  try {
    return !!t(...e);
  } catch {
    return !1;
  }
}, hc = xr && Fr(() => {
  let t = !1;
  const e = new Request(K.origin, {
    body: new ReadableStream(),
    method: "POST",
    get duplex() {
      return t = !0, "half";
    }
  }).headers.has("Content-Type");
  return t && !e;
}), vn = 64 * 1024, Rt = xr && Fr(() => l.isReadableStream(new Response("").body)), Je = {
  stream: Rt && ((t) => t.body)
};
st && ((t) => {
  ["text", "arrayBuffer", "blob", "formData", "stream"].forEach((e) => {
    !Je[e] && (Je[e] = l.isFunction(t[e]) ? (n) => n[e]() : (n, r) => {
      throw new O(`Response type '${e}' is not supported`, O.ERR_NOT_SUPPORT, r);
    });
  });
})(new Response());
const fc = async (t) => {
  if (t == null)
    return 0;
  if (l.isBlob(t))
    return t.size;
  if (l.isSpecCompliantForm(t))
    return (await new Request(K.origin, {
      method: "POST",
      body: t
    }).arrayBuffer()).byteLength;
  if (l.isArrayBufferView(t) || l.isArrayBuffer(t))
    return t.byteLength;
  if (l.isURLSearchParams(t) && (t = t + ""), l.isString(t))
    return (await dc(t)).byteLength;
}, pc = async (t, e) => {
  const n = l.toFiniteNumber(t.getContentLength());
  return n ?? fc(e);
}, mc = st && (async (t) => {
  let {
    url: e,
    method: n,
    data: r,
    signal: s,
    cancelToken: i,
    timeout: o,
    onDownloadProgress: a,
    onUploadProgress: c,
    responseType: u,
    headers: d,
    withCredentials: h = "same-origin",
    fetchOptions: T
  } = Ur(t);
  u = u ? (u + "").toLowerCase() : "text";
  let S = ac([s, i && i.toAbortSignal()], o), f;
  const w = S && S.unsubscribe && (() => {
    S.unsubscribe();
  });
  let g;
  try {
    if (c && hc && n !== "get" && n !== "head" && (g = await pc(d, r)) !== 0) {
      let A = new Request(e, {
        method: "POST",
        body: r,
        duplex: "half"
      }), D;
      if (l.isFormData(r) && (D = A.headers.get("content-type")) && d.setContentType(D), A.body) {
        const [M, k] = Sn(
          g,
          ze(An(c))
        );
        r = Tn(A.body, vn, M, k);
      }
    }
    l.isString(h) || (h = h ? "include" : "omit");
    const _ = "credentials" in Request.prototype;
    f = new Request(e, {
      ...T,
      signal: S,
      method: n.toUpperCase(),
      headers: d.normalize().toJSON(),
      body: r,
      duplex: "half",
      credentials: _ ? h : void 0
    });
    let b = await fetch(f);
    const p = Rt && (u === "stream" || u === "response");
    if (Rt && (a || p && w)) {
      const A = {};
      ["status", "statusText", "headers"].forEach((B) => {
        A[B] = b[B];
      });
      const D = l.toFiniteNumber(b.headers.get("content-length")), [M, k] = a && Sn(
        D,
        ze(An(a), !0)
      ) || [];
      b = new Response(
        Tn(b.body, vn, M, () => {
          k && k(), w && w();
        }),
        A
      );
    }
    u = u || "text";
    let v = await Je[l.findKey(Je, u) || "text"](b, t);
    return !p && w && w(), await new Promise((A, D) => {
      Mr(A, D, {
        data: v,
        headers: z.from(b.headers),
        status: b.status,
        statusText: b.statusText,
        config: t,
        request: f
      });
    });
  } catch (_) {
    throw w && w(), _ && _.name === "TypeError" && /fetch/i.test(_.message) ? Object.assign(
      new O("Network Error", O.ERR_NETWORK, t, f),
      {
        cause: _.cause || _
      }
    ) : O.from(_, _ && _.code, t, f);
  }
}), Ot = {
  http: Ca,
  xhr: oc,
  fetch: mc
};
l.forEach(Ot, (t, e) => {
  if (t) {
    try {
      Object.defineProperty(t, "name", { value: e });
    } catch {
    }
    Object.defineProperty(t, "adapterName", { value: e });
  }
});
const Rn = (t) => `- ${t}`, _c = (t) => l.isFunction(t) || t === null || t === !1, $r = {
  getAdapter: (t) => {
    t = l.isArray(t) ? t : [t];
    const { length: e } = t;
    let n, r;
    const s = {};
    for (let i = 0; i < e; i++) {
      n = t[i];
      let o;
      if (r = n, !_c(n) && (r = Ot[(o = String(n)).toLowerCase()], r === void 0))
        throw new O(`Unknown adapter '${o}'`);
      if (r)
        break;
      s[o || "#" + i] = r;
    }
    if (!r) {
      const i = Object.entries(s).map(
        ([a, c]) => `adapter ${a} ` + (c === !1 ? "is not supported by the environment" : "is not available in the build")
      );
      let o = e ? i.length > 1 ? `since :
` + i.map(Rn).join(`
`) : " " + Rn(i[0]) : "as no adapter specified";
      throw new O(
        "There is no suitable adapter to dispatch the request " + o,
        "ERR_NOT_SUPPORT"
      );
    }
    return r;
  },
  adapters: Ot
};
function _t(t) {
  if (t.cancelToken && t.cancelToken.throwIfRequested(), t.signal && t.signal.aborted)
    throw new be(null, t);
}
function On(t) {
  return _t(t), t.headers = z.from(t.headers), t.data = mt.call(
    t,
    t.transformRequest
  ), ["post", "put", "patch"].indexOf(t.method) !== -1 && t.headers.setContentType("application/x-www-form-urlencoded", !1), $r.getAdapter(t.adapter || Ue.adapter)(t).then(function(r) {
    return _t(t), r.data = mt.call(
      t,
      t.transformResponse,
      r
    ), r.headers = z.from(r.headers), r;
  }, function(r) {
    return Lr(r) || (_t(t), r && r.response && (r.response.data = mt.call(
      t,
      t.transformResponse,
      r.response
    ), r.response.headers = z.from(r.response.headers))), Promise.reject(r);
  });
}
const Br = "1.7.7", Ft = {};
["object", "boolean", "number", "function", "string", "symbol"].forEach((t, e) => {
  Ft[t] = function(r) {
    return typeof r === t || "a" + (e < 1 ? "n " : " ") + t;
  };
});
const Nn = {};
Ft.transitional = function(e, n, r) {
  function s(i, o) {
    return "[Axios v" + Br + "] Transitional option '" + i + "'" + o + (r ? ". " + r : "");
  }
  return (i, o, a) => {
    if (e === !1)
      throw new O(
        s(o, " has been removed" + (n ? " in " + n : "")),
        O.ERR_DEPRECATED
      );
    return n && !Nn[o] && (Nn[o] = !0, console.warn(
      s(
        o,
        " has been deprecated since v" + n + " and will be removed in the near future"
      )
    )), e ? e(i, o, a) : !0;
  };
};
function gc(t, e, n) {
  if (typeof t != "object")
    throw new O("options must be an object", O.ERR_BAD_OPTION_VALUE);
  const r = Object.keys(t);
  let s = r.length;
  for (; s-- > 0; ) {
    const i = r[s], o = e[i];
    if (o) {
      const a = t[i], c = a === void 0 || o(a, i, t);
      if (c !== !0)
        throw new O("option " + i + " must be " + c, O.ERR_BAD_OPTION_VALUE);
      continue;
    }
    if (n !== !0)
      throw new O("Unknown option " + i, O.ERR_BAD_OPTION);
  }
}
const Nt = {
  assertOptions: gc,
  validators: Ft
}, ne = Nt.validators;
class le {
  constructor(e) {
    this.defaults = e, this.interceptors = {
      request: new bn(),
      response: new bn()
    };
  }
  /**
   * Dispatch a request
   *
   * @param {String|Object} configOrUrl The config specific for this request (merged with this.defaults)
   * @param {?Object} config
   *
   * @returns {Promise} The Promise to be fulfilled
   */
  async request(e, n) {
    try {
      return await this._request(e, n);
    } catch (r) {
      if (r instanceof Error) {
        let s;
        Error.captureStackTrace ? Error.captureStackTrace(s = {}) : s = new Error();
        const i = s.stack ? s.stack.replace(/^.+\n/, "") : "";
        try {
          r.stack ? i && !String(r.stack).endsWith(i.replace(/^.+\n.+\n/, "")) && (r.stack += `
` + i) : r.stack = i;
        } catch {
        }
      }
      throw r;
    }
  }
  _request(e, n) {
    typeof e == "string" ? (n = n || {}, n.url = e) : n = e || {}, n = he(this.defaults, n);
    const { transitional: r, paramsSerializer: s, headers: i } = n;
    r !== void 0 && Nt.assertOptions(r, {
      silentJSONParsing: ne.transitional(ne.boolean),
      forcedJSONParsing: ne.transitional(ne.boolean),
      clarifyTimeoutError: ne.transitional(ne.boolean)
    }, !1), s != null && (l.isFunction(s) ? n.paramsSerializer = {
      serialize: s
    } : Nt.assertOptions(s, {
      encode: ne.function,
      serialize: ne.function
    }, !0)), n.method = (n.method || this.defaults.method || "get").toLowerCase();
    let o = i && l.merge(
      i.common,
      i[n.method]
    );
    i && l.forEach(
      ["delete", "get", "head", "post", "put", "patch", "common"],
      (f) => {
        delete i[f];
      }
    ), n.headers = z.concat(o, i);
    const a = [];
    let c = !0;
    this.interceptors.request.forEach(function(w) {
      typeof w.runWhen == "function" && w.runWhen(n) === !1 || (c = c && w.synchronous, a.unshift(w.fulfilled, w.rejected));
    });
    const u = [];
    this.interceptors.response.forEach(function(w) {
      u.push(w.fulfilled, w.rejected);
    });
    let d, h = 0, T;
    if (!c) {
      const f = [On.bind(this), void 0];
      for (f.unshift.apply(f, a), f.push.apply(f, u), T = f.length, d = Promise.resolve(n); h < T; )
        d = d.then(f[h++], f[h++]);
      return d;
    }
    T = a.length;
    let S = n;
    for (h = 0; h < T; ) {
      const f = a[h++], w = a[h++];
      try {
        S = f(S);
      } catch (g) {
        w.call(this, g);
        break;
      }
    }
    try {
      d = On.call(this, S);
    } catch (f) {
      return Promise.reject(f);
    }
    for (h = 0, T = u.length; h < T; )
      d = d.then(u[h++], u[h++]);
    return d;
  }
  getUri(e) {
    e = he(this.defaults, e);
    const n = kr(e.baseURL, e.url);
    return Cr(n, e.params, e.paramsSerializer);
  }
}
l.forEach(["delete", "get", "head", "options"], function(e) {
  le.prototype[e] = function(n, r) {
    return this.request(he(r || {}, {
      method: e,
      url: n,
      data: (r || {}).data
    }));
  };
});
l.forEach(["post", "put", "patch"], function(e) {
  function n(r) {
    return function(i, o, a) {
      return this.request(he(a || {}, {
        method: e,
        headers: r ? {
          "Content-Type": "multipart/form-data"
        } : {},
        url: i,
        data: o
      }));
    };
  }
  le.prototype[e] = n(), le.prototype[e + "Form"] = n(!0);
});
class $t {
  constructor(e) {
    if (typeof e != "function")
      throw new TypeError("executor must be a function.");
    let n;
    this.promise = new Promise(function(i) {
      n = i;
    });
    const r = this;
    this.promise.then((s) => {
      if (!r._listeners)
        return;
      let i = r._listeners.length;
      for (; i-- > 0; )
        r._listeners[i](s);
      r._listeners = null;
    }), this.promise.then = (s) => {
      let i;
      const o = new Promise((a) => {
        r.subscribe(a), i = a;
      }).then(s);
      return o.cancel = function() {
        r.unsubscribe(i);
      }, o;
    }, e(function(i, o, a) {
      r.reason || (r.reason = new be(i, o, a), n(r.reason));
    });
  }
  /**
   * Throws a `CanceledError` if cancellation has been requested.
   */
  throwIfRequested() {
    if (this.reason)
      throw this.reason;
  }
  /**
   * Subscribe to the cancel signal
   */
  subscribe(e) {
    if (this.reason) {
      e(this.reason);
      return;
    }
    this._listeners ? this._listeners.push(e) : this._listeners = [e];
  }
  /**
   * Unsubscribe from the cancel signal
   */
  unsubscribe(e) {
    if (!this._listeners)
      return;
    const n = this._listeners.indexOf(e);
    n !== -1 && this._listeners.splice(n, 1);
  }
  toAbortSignal() {
    const e = new AbortController(), n = (r) => {
      e.abort(r);
    };
    return this.subscribe(n), e.signal.unsubscribe = () => this.unsubscribe(n), e.signal;
  }
  /**
   * Returns an object that contains a new `CancelToken` and a function that, when called,
   * cancels the `CancelToken`.
   */
  static source() {
    let e;
    return {
      token: new $t(function(s) {
        e = s;
      }),
      cancel: e
    };
  }
}
function yc(t) {
  return function(n) {
    return t.apply(null, n);
  };
}
function Ec(t) {
  return l.isObject(t) && t.isAxiosError === !0;
}
const Ct = {
  Continue: 100,
  SwitchingProtocols: 101,
  Processing: 102,
  EarlyHints: 103,
  Ok: 200,
  Created: 201,
  Accepted: 202,
  NonAuthoritativeInformation: 203,
  NoContent: 204,
  ResetContent: 205,
  PartialContent: 206,
  MultiStatus: 207,
  AlreadyReported: 208,
  ImUsed: 226,
  MultipleChoices: 300,
  MovedPermanently: 301,
  Found: 302,
  SeeOther: 303,
  NotModified: 304,
  UseProxy: 305,
  Unused: 306,
  TemporaryRedirect: 307,
  PermanentRedirect: 308,
  BadRequest: 400,
  Unauthorized: 401,
  PaymentRequired: 402,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  ProxyAuthenticationRequired: 407,
  RequestTimeout: 408,
  Conflict: 409,
  Gone: 410,
  LengthRequired: 411,
  PreconditionFailed: 412,
  PayloadTooLarge: 413,
  UriTooLong: 414,
  UnsupportedMediaType: 415,
  RangeNotSatisfiable: 416,
  ExpectationFailed: 417,
  ImATeapot: 418,
  MisdirectedRequest: 421,
  UnprocessableEntity: 422,
  Locked: 423,
  FailedDependency: 424,
  TooEarly: 425,
  UpgradeRequired: 426,
  PreconditionRequired: 428,
  TooManyRequests: 429,
  RequestHeaderFieldsTooLarge: 431,
  UnavailableForLegalReasons: 451,
  InternalServerError: 500,
  NotImplemented: 501,
  BadGateway: 502,
  ServiceUnavailable: 503,
  GatewayTimeout: 504,
  HttpVersionNotSupported: 505,
  VariantAlsoNegotiates: 506,
  InsufficientStorage: 507,
  LoopDetected: 508,
  NotExtended: 510,
  NetworkAuthenticationRequired: 511
};
Object.entries(Ct).forEach(([t, e]) => {
  Ct[e] = t;
});
function Hr(t) {
  const e = new le(t), n = yr(le.prototype.request, e);
  return l.extend(n, le.prototype, e, { allOwnKeys: !0 }), l.extend(n, e, null, { allOwnKeys: !0 }), n.create = function(s) {
    return Hr(he(t, s));
  }, n;
}
const V = Hr(Ue);
V.Axios = le;
V.CanceledError = be;
V.CancelToken = $t;
V.isCancel = Lr;
V.VERSION = Br;
V.toFormData = rt;
V.AxiosError = O;
V.Cancel = V.CanceledError;
V.all = function(e) {
  return Promise.all(e);
};
V.spread = yc;
V.isAxiosError = Ec;
V.mergeConfig = he;
V.AxiosHeaders = z;
V.formToJSON = (t) => Pr(l.isHTMLForm(t) ? new FormData(t) : t);
V.getAdapter = $r.getAdapter;
V.HttpStatusCode = Ct;
V.default = V;
const bc = "https://api.fastspring.com", wc = async (t, e) => {
  if (!t || !e)
    throw new Error("Could not get FastSpring credentials");
  return V.create({
    baseURL: bc,
    headers: { "User-Agent": "Cloud Function" },
    // User Agent is a must for FastSpring
    auth: {
      username: t,
      password: e
    }
  });
}, Sc = (t) => {
  const e = t.sort((n, r) => r.begin - n.begin);
  return (e == null ? void 0 : e[0]) || null;
}, Ac = ({
  subscription: t,
  currentCycle: e
}) => {
  if (t.active === !0)
    return !0;
  const n = t.deactivationDate;
  return !n || !e ? !1 : n > e.startDate && n < e.endDate;
}, Kc = async ({
  subscription: t,
  plans: e,
  db: n,
  now: r
}) => {
  var s;
  try {
    if (!t)
      return !1;
    const i = (s = t.customMetadata) == null ? void 0 : s.currentActiveProductId;
    if (!e.includes(i))
      return !1;
    const o = await n.collection("cycles").where("startDate", "<", r).where("endDate", ">", r).orderBy("startDate", "desc").limit(1).get();
    o.docs.length;
    const a = o.docs.map((d) => {
      const h = Z(d);
      return {
        ...h,
        id: h.id,
        startDate: h.startDate.toMillis(),
        orderPlacementDeadlineDate: h.orderPlacementDeadlineDate.toMillis(),
        reviewStartDate: h.reviewStartDate.toMillis(),
        endDate: h.endDate.toMillis()
      };
    }), c = a == null ? void 0 : a[0];
    if (!c)
      throw new Error("No current cycle found");
    return !!Ac({
      subscription: t,
      currentCycle: c
    });
  } catch {
    return !1;
  }
}, zc = async (t, e) => {
  let n = null;
  const r = async () => (n || (n = await wc(
    t,
    e
  )), n), s = async (_) => {
    var A, D;
    const p = await (await r()).get(`/accounts/${_}`);
    return ((D = (A = p == null ? void 0 : p.data) == null ? void 0 : A.accounts) == null ? void 0 : D[0]) || null;
  }, i = async ({
    email: _,
    memberUID: b,
    brandId: p
  }) => {
    var B, x, P;
    if ([_, b, p].filter(Boolean).length !== 1)
      throw new $("Only one param is allowed", {
        statusCode: 400
      });
    const A = await r(), D = {};
    _ && (D.email = _), b && (D.custom = `member_${b}`), p && (D.custom = `brand_${p}`);
    const M = await A.get("/accounts", {
      params: D
    });
    return ((P = (x = (B = M == null ? void 0 : M.data) == null ? void 0 : B.accounts) == null ? void 0 : x[0]) == null ? void 0 : P.id) || null;
  }, o = async (_) => {
    var D;
    const p = await (await r()).get(
      "/subscriptions",
      {
        params: {
          accountId: _
        }
      }
    ), v = (D = p == null ? void 0 : p.data) == null ? void 0 : D.subscriptions, A = Object.values(
      Cn
    );
    return v ? v.filter(
      (M) => M.product && A.includes(M.product)
    ) : [];
  }, a = async (_) => {
    var M, k;
    const p = await (await r()).get(
      `/subscriptions/${_}/entries`
    ), v = (M = p == null ? void 0 : p.data) == null ? void 0 : M.sort(
      (B, x) => new Date(x.endPeriodDate).getTime() - new Date(B.endPeriod).getTime()
    ), A = (v == null ? void 0 : v[0]) ?? null;
    return A && ((k = A.order.items) == null ? void 0 : k[0]) || null;
  }, c = async (_) => {
    const b = await o(_), p = Sc(b);
    if (!p)
      return null;
    const v = await a(
      p.id
    );
    return v && (p.customMetadata || (p.customMetadata = {}), p.customMetadata.currentActiveProductId = v.product), p;
  };
  return {
    getFastSpringAccount: s,
    getFastSpringAccountId: i,
    getBrandCurrentSubscription: async ({
      brandId: _
    }) => {
      const b = await i({ brandId: _ });
      return b ? await c(b) : null;
    },
    getAccountFastSpringSubscriptions: o,
    getAccountLatestFastspringSubscription: c,
    getSubscriptionLatestEntry: a,
    getAccountAuthenticatedURL: async (_) => {
      var v, A, D;
      const p = await (await r()).get(`/accounts/${_}/authenticate`);
      return (D = (A = (v = p == null ? void 0 : p.data) == null ? void 0 : v.accounts) == null ? void 0 : A[0]) == null ? void 0 : D.url;
    },
    createFastSpringAccount: async ({
      email: _,
      firstName: b,
      lastName: p,
      memberUID: v,
      brandId: A
    }) => {
      var P;
      if (!_ || !b || !p)
        throw new $("Missing required params", {
          statusCode: 400
        });
      if ([v, A].filter(Boolean).length !== 1)
        throw new $("Only memberUID or brandId param is allowed", {
          statusCode: 400
        });
      const M = await r(), k = {
        contact: {
          first: b,
          last: p,
          email: _
        },
        language: "en",
        country: "US"
      };
      return v && (k.lookup = { custom: `member_${v}` }), A && (k.lookup = { custom: `brand_${A}` }), ((P = (await M.post(
        "/accounts",
        k
      )).data) == null ? void 0 : P.account) || null;
    },
    updateFastSpringAccount: async (_, {
      firstName: b,
      lastName: p,
      memberUID: v,
      brandId: A
    }) => {
      if (!_)
        throw new $("Missing accountId param", {
          statusCode: 400
        });
      if ([b, p, v, A].every((B) => !B))
        throw new $("Missing update params", {
          statusCode: 400
        });
      if ([v, A].filter(Boolean).length > 1)
        throw new $("Only memberUID or brandId param is allowed", {
          statusCode: 400
        });
      const M = await r(), k = {};
      return (b || p) && (k.contact = {}, b && (k.contact.first = b), p && (k.contact.last = p)), v && (k.lookup = { custom: `member_${v}` }), A && (k.lookup = { custom: `brand_${A}` }), await M.post(`/accounts/${_}`, k), _ || null;
    },
    getFastSpringSubscription: async (_) => {
      const p = await (await r()).get(
        `/subscriptions/${_}`
      ), v = await a(_), A = p.data;
      return A ? (A.customMetadata || (A.customMetadata = {}), A && v && (A.customMetadata.currentActiveProductId = v.product), A || null) : null;
    },
    updateFastSpringSubscription: async (_) => {
      try {
        const b = await r(), p = {
          subscriptions: [_]
        };
        await b.post("/subscriptions", p);
      } catch (b) {
        throw console.error(b), new $("Error, could not update the subscription", {
          statusCode: 500
        });
      }
    },
    restoreFastspringSubscription: async (_) => {
      try {
        const b = await r(), p = {
          subscriptions: [
            {
              subscription: _,
              deactivation: null
            }
          ]
        };
        await b.post("/subscriptions", p);
      } catch (b) {
        throw console.error(b), new $("Error, could not restore the subscription", {
          statusCode: 500
        });
      }
    },
    cancelFastspringSubscription: async (_) => {
      try {
        await (await r()).delete(`/subscriptions/${_}`);
      } catch (b) {
        throw console.error(b), new $("Error, could not cancel the subscription", {
          statusCode: 500
        });
      }
    }
  };
};
export {
  vc as BrandBasicPlans,
  Tc as BrandFullPlans,
  Cn as BrandSubscriptionPlan,
  Nc as CYCLE_MAX_TOKENS,
  bc as FAST_SPRING_API_URL,
  mn as IS_TN_ORDER_ATTRIBUTE,
  Yi as MAX_USERS_REACHED_ERROR,
  no as MemberStatus,
  gt as OrderStatus,
  qr as ProductStatusType,
  Gr as PromotionStatus,
  jo as SHOPIFY_API_VERSION,
  qo as SHOPIFY_APP_SCOPES,
  Dc as SignupMaxUsersReachedError,
  Pc as TN_ERROR_CODES,
  pn as TN_ORDER_ID_ATTRIBUTE,
  $ as TnError,
  Cc as US_STATES,
  ro as UserType,
  xc as capitalizeFirstLetter,
  kc as convertTimestampToDate,
  wc as createFastspringAxiosClient,
  jc as disconnectBrandFromShopifyStore,
  Hc as doesBrandNeedToUpdateShopifyApp,
  Gc as exportOrderToShopify,
  zc as fastspring,
  $c as flagsConfig,
  qc as forceShopifyAppUpdate,
  Bc as getBrandIdForUser,
  Sc as getLatestSubscription,
  gr as getShopifyAccessForBrand,
  mr as getShopifyClient,
  _r as getShopifySessionForBrand,
  Ac as isSubscriptionActiveOrInGracePeriod,
  Kc as isSubscriptionActiveOrInGracePeriodForPlans,
  Rc as loginProviders,
  uo as mapAllergiesToAnalytics,
  lo as mapDietaryRestrictionsToAnalytics,
  fo as mapFrequentlyPurchasedProductsToAnalytics,
  ho as mapGroceryShoppingToAnalytics,
  Fc as mapMemberOnboardingDataToAnalytics,
  Mc as removeUndefinedValues,
  Vc as saveShopifyProduct,
  Z as snapshotToTyped,
  Wc as syncShopifyOrderToTn,
  Lc as transformValues,
  Xi as transformValuesDeep,
  Uc as truncate,
  Oc as wait
};
