<script setup lang="ts">
import { setRemoteConfig } from '~/utils/fetchRemoteConfig';
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
import QuickSurveyContainer from '~/components/quick-survey/QuickSurveyContainer.vue';
import type { RemoteConfig } from '@tn/shared';

const remoteConfig = useState<RemoteConfig>('remote-config');

const config = useRuntimeConfig();
const { refresh, uid, memberUser, userType } = useUserState();
const auth = useFirebaseAuth();

useTriggerOperations(uid.value);
let unsubscribe: (() => void) | undefined;

onMounted(() => {
  unsubscribe = auth?.onAuthStateChanged((user) => {
    if (user && uid.value !== user.uid) {
      refresh();
      setRemoteConfig();
    }
  });
});

onUnmounted(() => {
  unsubscribe?.();
});

watch(uid, (newValue, oldValue) => {
  if (newValue && newValue !== oldValue) {
    const userTraits = {
      ...(userType.value && { user_type: userType.value }),
      ...(memberUser.value?.membership && {
        member_status: memberUser.value?.membership,
      }),
    };
    useIdentifyEvent(newValue, userTraits);
  }
});

const shouldShowDevTools = computed(
  () => config.public.appEnv !== 'production'
);
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
    <UNotifications />
    <UModals />
  </NuxtLayout>
  <QuickSurveyContainer v-if="remoteConfig.quickSurveys.isEnabled && uid" />
  <TnDevTools v-if="shouldShowDevTools" />
  <VueQueryDevtools button-position="bottom-left" />
</template>
