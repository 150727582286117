import { useCurrentUserQuery } from '~/features/auth';

export const useUserState = () => {
  const { data, refetch } = useCurrentUserQuery();

  const refresh = async () => {
    await refetch();

    if (data.value?.isBrand && !data?.value?.testClockTimeMs) {
      const brandSubscriptionStore = useBrandSubscriptionStore();
      await brandSubscriptionStore.fetchCurrentSubscription();
    }

    return data.value;
  };

  return {
    uid: computed(() => data.value?.uid),
    email: computed(() => data.value?.email),
    isEmailVerified: computed(() => data.value?.isEmailVerified),
    hasCompletedOnboarding: computed(() => data.value?.hasCompletedOnboarding),
    testClockTimeMs: computed(() => data.value?.testClockTimeMs),
    lastSeenRenewBannerMs: computed(() => data.value?.lastSeenRenewBannerMs),
    isCmUser: computed(() => data.value?.isCmUser),
    isPremium: computed(() => data.value?.isPremium),
    userType: computed(() => data.value?.userType),
    isBrand: computed(() => data.value?.isBrand),
    memberUser: computed(() => data.value?.memberUser),
    brandUser: computed(() => data.value?.brandUser),
    refresh,
  };
};
